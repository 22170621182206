//import { OperationDataTypeCode } from "@rfb/common";

import { OperationDataTypeCode } from 'dto/operation-data';

export enum OperationDataTypeValue {
  PAY = 'Погашение',
  REQ = 'Финансирование_новые',
  REQU = 'Финансирование_с пробегом',
  REQR = 'Финансирование РЕПО',
}

export const operationDataTypeValue: { [key: string]: string } = {
  [OperationDataTypeCode.PAY]: 'Погашение',
  [OperationDataTypeCode.REQ]: 'Финансирование_новые',
  [OperationDataTypeCode.REQU]: 'Финансирование_с пробегом',
  [OperationDataTypeCode.REQR]: 'Финансирование РЕПО',
};

export const operationDataTypeCode: { [key: string]: OperationDataTypeCode } = {
  [OperationDataTypeValue.PAY]: OperationDataTypeCode.PAY,
  [OperationDataTypeValue.REQ]: OperationDataTypeCode.REQ,
  [OperationDataTypeValue.REQU]: OperationDataTypeCode.REQU,
  [OperationDataTypeValue.REQR]: OperationDataTypeCode.REQR,
};
