import Change from '@rfb/ui-kit/lib/icons/Change';
import Remove from '@rfb/ui-kit/lib/icons/Remove';
import { IFinancialState } from 'modules/financial-block/store';
import React from 'react';
import styles from './assets/styles/index.module.css';

import cn from 'classnames';

import { convertPathUsingParams, DealerSystems } from '@rfb/common';
import routingConfig from 'configs/routing';
import { FinancingRequestUsedDTO } from 'dto/rf-info';
import notification from 'utils/notification';

export interface FTButtonCellProps {
  filter: IFinancialState['filter'];
  record: FinancingRequestUsedDTO;
}

export interface FTButtonCellExternal {
  history: any;
  requestDataId: number;
  brand_type: string;
  state?: string;
  isActive: boolean;
}

export interface FTButtonCellActions {
  actions: {
    setForm: Function;
    getRequestDataUsed: Function;
    deleteRequestDataUsed: Function;
  };
}

const FTButtonCellUsed = ({
  actions,
  ...props
}: FTButtonCellProps & FTButtonCellActions & FTButtonCellExternal) => {
  return (
    <div>
      <span
        onClick={() => {
          if (props.isActive) {
            actions.setForm({
              requestDataId: props.record.request_data_id,
              requestAmount: props.record.request_amount,
              requestVin: props.record.request_vin,
              notVin: props.record.not_vin,
              requestBrand: props.record.brand,
              requestBrandId: props.record.brand_id,
              requestModel: props.record.model,
              requestModelId: props.record.model_id,
              requestCarPassportDate: props.record.car_passport_date,
              requestEngineDisplacement: props.record.engine_volume,
              requestEnginePower: props.record.engine_power,
              requestElectricalEnginePower: props.record.electrical_engine_power,
              requestMileage: props.record.car_run,
              requestProofOfPaymentFileName: props.record.request_attachments[0].file_name,
              requestProofOfPaymentFileId: props.record.request_attachments[0].attachment_id,
              requestActOfAcceptanceFileName: props.record.request_attachments[1].file_name,
              requestActOfAcceptanceFileId: props.record.request_attachments[1].attachment_id,
              requestContractFileName: props.record.request_attachments[2].file_name,
              requestContractFileId: props.record.request_attachments[2].attachment_id,
            });
            props.history.push(
              convertPathUsingParams(routingConfig.financialBlockFTEdit.path, {
                requestDataId: props.requestDataId,
                system: DealerSystems.ROSBANK_AVTO,
              }) + '?code_type=used'
            );
          }
        }}
      >
        <Change className={cn(styles.icon, { [styles.iconDisabled]: !props.isActive })} />
      </span>
      <span
        style={{ marginLeft: '12px' }}
        onClick={() => {
          if (props.isActive) {
            notification.confirm('Вы уверены, что хотите удалить запись', () => {
              actions.deleteRequestDataUsed({
                requestDataId: props.requestDataId,
                onSuccess: () => actions.getRequestDataUsed(props.filter),
              });
            });
          }
        }}
      >
        <Remove className={cn(styles.icon, { [styles.iconDisabled]: !props.isActive })} />
      </span>
    </div>
  );
};

export default FTButtonCellUsed;
