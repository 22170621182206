// Дублирование с админкой
export enum OperationDataTypeCode {
  PAY = 'PAY',
  REQ = 'REQ',
  REQU = 'REQU',
  REQR = 'REQR',
}

export type TDTOOperationData = {
  id: number;
};

export type TBrand = {
  brand_id: number;
  brand_name: string;
};

export type TModel = {
  model_id: string;
  model_name: string;
};

export type TAttachment = {
  operation_attachment_id: number;
  file_id: string;
  file_name: string;
  attachment_type_code: string;
};

export interface IDTOOperationDataGetResponseHeaders {
  'x-pagination-page-count': string;
  'x-pagination-per-page': string;
}

export enum OperationStatusId {
  SENT = 1,
  UPLOAD_ERROR_TO_FOLDER_IN_DEALER = 2,
  UPLOAD_ERROR_TO_DOCUMENTUM = 3,
  EXECUTED = 4,
  PERFORMED_PARTIALLY = 5,
  NOT_EXECUTED = 6,
  STATUS_REQUEST_ERROR = 7,
  STATUS_UNKNOWN = 8,
  REVOKED = 9,
  IN_ACTION = 10,
  NEW_OR_SENT = 11,
  SENT_TO_RS = 12,
  NOT_AGREED = 13,
  ERROR = 14,
  AGREEMENT_OR_SIGNING_IS_NEEDED = 15,
  SEND_ERROR = 16,
  REFUSAL_OR_NOT_AGREED = 17,
  DRAFT = 18,
  SENT_TO_1C = 19,
  READY_FOR_FINANCING_OR_SIGNED = 20,
}

export interface IDTOOperationDataResponse {
  records: {
    operation_data_id: number; // РЕПО
    operation_create_date: number;
    operation_type_code: OperationDataTypeCode;
    operation_type_name: string; // РЕПО
    operation_status_name: string; // РЕПО
    operation_number: number;
    total_sum: number; // РЕПО
    approved_sum: number;
    advance_sum: number;
    user_name: string;
    dealer_name: string; // РЕПО
    dealer_inn: string; // РЕПО
    signed_enable: boolean; // РЕПО
    enq_doc_file_id: number; // РЕПО
    enq_doc_file_name: string; // РЕПО
    req_doc_file_id: number; // РЕПО
    req_doc_file_name: string; // РЕПО
    request_number: string;
    operation_status_id: OperationStatusId;
    operation_amount: number;
    doc_request_file_id: string;
    doc_invoice_file_id: string;
    sent_file_name: string;
    vin: string;
    brand_id: number;
    brand: string;
    model_id: string;
    model: string;
    attachments: TAttachment[];
  }[];
  filters: {
    operation_type_code: OperationDataTypeCode[];
    operation_status_id: OperationStatusId[]; // В РЕПО не используется
    operation_status_name: string[];
    brand: TBrand[];
    model: TModel[];
  };
}

export interface RepoOperationDataViewRecordDto {
  operation_data_id: number;
  repo_request_data_id: number;
  date_ins: string;
  repo_request_type_code: string;
  brand_name: string;
  model_name: string;
  request_vin: string;
  origin_country: string;
  price_with_vat: number;
  vat_amount: number;
  account_number: string;
  account_date: string;
  supplier_doc_date: string;
  delivery_location: string;
  receiver: string;
  payment_purpose: string;
  advance_percent: number;
  multi_brand: number;
  repo_request_status_id: number;
  repo_request_status_name: string;
  repo_request_type_name: string;
  comment: string;
  is_check: boolean;
  is_pledge: boolean;
  refuse_reason_code: string;
  refuse_reason_name: string;
  advance_sum: number;
}

export type RepoOperationDataViewDTO = {
  records: RepoOperationDataViewRecordDto[];
  summary: {
    request_sum: number;
    request_sum_agree: number;
    advance_sum: number;
    operation_data_id: number;
    operation_type_name: string;
    operation_status_name: string;
    total_sum: number;
    dealer_name: string;
    dealer_inn: string;
    signed_enable: number;
    enq_doc_file_id: number;
    enq_doc_file_name: string;
    req_doc_file_id: number;
    req_doc_file_name: string;
    agree_enable: boolean;
  };
};

export type TRepoOperationDataViewResponse = {
  status: string;
  headers: string[];
  data: RepoOperationDataViewDTO;
};

export type TRepoOperationData = {
  operation_data_id: number,
  operation_create_date: string,
  date_ins: string,
  operation_type_code: string,
  operation_status_id: number,
  operation_number: string,
  operation_amount: number,
  doc_request_file_id: string,
  doc_invoice_file_id: string,
  sent_file_name: string
};

export type TRepoAttachment = {
  operation_attachment_id: number;
  attachment_file_name: string;
  attachment_type_code: string;
  type: string;
};

export interface TOperationData {
  operation_create_date: number;
  operation_type_code: OperationDataTypeCode;
  operation_status_id: OperationStatusId;
  operation_amount: number;
  doc_request_file_id: string;
  doc_invoice_file_id: string;
  sent_file_name: string;
  user_name: string;
  vin: string;
  brand_id: number;
  brand: string;
  model_id: string;
  model: string;
  attachments: TAttachment[];
}

export type TGetCountAgreeRequestDTO = {
  request_count: number;
};

export type TGetCountAgreeRequestResponse = {
  status: string;
  headers: string[];
  data: TGetCountAgreeRequestDTO;
};

export interface IDTOOperationDataGetResponseError {
  field: string;
  message: string;
  code: number;
}
