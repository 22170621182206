import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { name, actions, IInformationState, selectors } from '../../../store';
import Component, { ISystemChoiceActions } from './component';

const mapStateToProps = (state: { [name]: IInformationState }): { selectedSystem: any } => ({
  selectedSystem: selectors.selectSelectedSystem(state),
});

const mapDispatchToProps = (dispatch: any): ISystemChoiceActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
