import React from 'react';

import { AppFullWidthTemplate as UIKiAppFullWidthtTemplate } from '@rfb/ui-kit';

import AppMenu from 'modules/app/components/Menu';
import UserWidget from 'modules/user/components/Widget';

import styles from './assets/styles/index.module.css';

interface IAppFullWidthTemplate {
  content: JSX.Element | JSX.Element[];
}

const AppFullWidthTemplate = (props: IAppFullWidthTemplate) => {
  const renderHeader = () => (
    <>
      <AppMenu />
      <UserWidget />
    </>
  );

  const renderContent = () => (
    <div className={styles.main}>
      <div className={styles.content}>{props.content}</div>
    </div>
  );

  return <UIKiAppFullWidthtTemplate header={renderHeader()} content={renderContent()} />;
};

export default AppFullWidthTemplate;
