"use strict";
exports.__esModule = true;
exports.FeedbackSubjects = void 0;
var FeedbackSubjects;
(function (FeedbackSubjects) {
    FeedbackSubjects["DebtService"] = "debt_service";
    FeedbackSubjects["AccountMaintenance"] = "account_maintenance";
    FeedbackSubjects["TechnicalIssue"] = "technical_issue";
    FeedbackSubjects["Other"] = "other";
})(FeedbackSubjects = exports.FeedbackSubjects || (exports.FeedbackSubjects = {}));
