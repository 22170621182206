import React from 'react';

import styles from './assets/styles/index.module.css';

export interface IClientLineDetailInfo {
  type: string;
  brandName: string;
  productType: string;
}

const Info = (props: IClientLineDetailInfo) => {
  const renderItem = (title: string, value: string) => (
    <div className={styles.item}>
      <div className={styles.title}>{title}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );

  return (
    <div className={styles.main}>
      <div className={styles.type}>{renderItem('Тип договора', props.type)}</div>
      <div className={styles.brandName}>{renderItem('Бренд', props.brandName)}</div>
      <div className={styles.productType}>{renderItem('Продукт', props.productType)}</div>
    </div>
  );
};

export default Info;
