import React, { useEffect } from 'react';

import fp from 'lodash/fp';

import { Button, Condition, Form, Input, MessageList } from '@rfb/ui-kit';

import { configureValidator, runRulesChain } from 'helpers/validation';

import { innRules, kppRules } from '../../configs/validation';

import { IDTOProfileValidateRequest } from 'dto/profile';

import styles from './assets/styles/index.module.css';

export interface IAuthLoginFirstProps {
  email: string;
  dealerName: string;
  inn: string;
  innErrorList: string[];
  kpp: string;
  kppErrorList: string[];
  apiErrorList: string[];
}

export interface IAuthLoginFirstPropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void;
  };
}

export interface IAuthLoginFirstActions {
  actions: {
    set: Function;
    setError: Function;
    reset: Function;
    sendDealerData: Function;
  };
}

const LoginFirst = (
  props: IAuthLoginFirstProps & IAuthLoginFirstPropsExternal & IAuthLoginFirstActions
) => {

  useEffect(() => {
    return () => {
      props.actions.reset();
    };
    // ESLINT Необходимо выполнение только в момент размонтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validators = {
    inn: configureValidator({
      name: 'inn',
      rule: innRules,
      setError: props.actions.setError,
    }),
    kpp: configureValidator({
      name: 'kpp',
      rule: kppRules,
      setError: props.actions.setError,
    }),
  };

  const onInnChange = (inn: string): void => {
    validators.inn(inn).finally((): void => props.actions.set({ inn }));
  };
  const onKppChange = (kpp: string): void => {
    validators.kpp(kpp).finally((): void => props.actions.set({ kpp }));
  };
  const onFormSubmit = (): void => {
    const data: IDTOProfileValidateRequest = { inn: props.inn, kpp: props.kpp };
    const rules = [validators.inn(data.inn), validators.kpp(data.kpp)];
    runRulesChain(rules).then(() =>
      props.actions.sendDealerData({ data, history: props.history })
    );
  };

  return (
    <div className={styles.main}>
      <h2 className={styles.title}>
        <div>
          Вы вошли как <span className={styles.email}>{props.email}</span>
        </div>
        <div>введите ИНН и КПП организации</div>
      </h2>

      <Form onSubmit={onFormSubmit}>
        <Input wrapperClassName={styles.inn} label="ИНН" value={props.inn} onChange={onInnChange} />
        <MessageList type="error" messages={props.innErrorList} />

        <Input wrapperClassName={styles.kpp} label="КПП" value={props.kpp} onChange={onKppChange} />
        <MessageList type="error" messages={props.kppErrorList} />

        <div className={styles.name}>Название: {props.dealerName}</div>

        <Condition value={!fp.isEmpty(props.apiErrorList)}>
          <MessageList type="error" messages={props.apiErrorList} />
        </Condition>

        <Button wrapperClassName={styles.button} type="submit" text="Продолжить" />
      </Form>
    </div>
  );
};

export default LoginFirst;
