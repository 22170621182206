import { createSlice } from '@reduxjs/toolkit';

import { getStateFromParams } from 'helpers/store';

import { dates } from '../configs/defaultAccount';

import {
  IDTORFInfoAccountsResponse,
  IDTORFInfoAccountResponse,
  IDTORFInfoAccountArrestsResponse,
  IDTORFInfoAccountExtendedStatementResponse,
} from 'dto/rf-info';

export interface IAccountsState {
  accounts: IDTORFInfoAccountsResponse[];
  accountData: IDTORFInfoAccountResponse;
  currentAccountData: IDTORFInfoAccountsResponse;
  currentArrestsData: IDTORFInfoAccountArrestsResponse;
  extendedStatementsData: IDTORFInfoAccountExtendedStatementResponse;
  pageTransactionsCount: number;
  pageArrestsCount: number;
  pageStatementsCount: number;
  filter: {
    account: string | null,
    start: string,
    end: string,
    pageTransactions: number,
    pageArrests: number,
    pageStatements: number,
  }
  errorList: { [key: string]: string[] };
  isLoading: boolean;
}

const initialState: IAccountsState = {
  accounts: [],
  accountData: {
    transactions: [],
  },
  currentAccountData: {
    diasoft_id: '',
    account: '',
    rest: '',
    rko_debt: '',
    arrest: '',
    open_date: '',
    close_date: '',
  },
  currentArrestsData: {
    arrests: [],
    krt_count: '',
    krt_qty: '',
  },
  extendedStatementsData: {
    statements: [],
    rest_in: '',
    turn_cred: '',
    turn_debt: '',
    doc_quantity: '',
    rest_out: '',
  },
  pageTransactionsCount: 0,
  pageArrestsCount: 0,
  pageStatementsCount: 0,
  filter: {
    account: null,
    start: dates.start,
    end: dates.end,
    pageTransactions: 1,
    pageArrests: 1,
    pageStatements: 1,
  },
  errorList: {},
  isLoading: false,
};

const accountsSlice = createSlice({
  name: 'accounts',

  initialState,

  reducers: {
    set: (state, action) => ({ ...state, ...action.payload }),
    setAccounts: (state, action) => ({
      ...state,
      accounts: action.payload,
    }),
    setCurrentId: (state, action) => ({
      ...state,
      currentId: action.payload,
    }),
    setCurrentAccount: (state, action) => ({
      ...state,
      currentAccountData:
        state.accounts.find((account) => account.account === action.payload) ||
        initialState.currentAccountData,
    }),
    setCurrentArrests: (state, action) => ({
      ...state,
      currentArrestsData: action.payload,
    }),
    setExtendedStatementsData: (state, action) => ({
      ...state,
      extendedStatementsData: action.payload,
    }),
    setAccountData: (state, action) => ({
      ...state,
      accountData: action.payload,
    }),
    setError: (state, action) => ({
      ...state,
      errorList: { ...state.errorList, ...action.payload },
    }),
    setFilter: (state, action) => ({
      ...state,
      filter: { ...state.filter, ...action.payload },
    }),
    reset: () => ({ ...initialState }),
    resetAccountData: (state) => ({
      ...state,
      accountData: { ...initialState.accountData },
    }),
    resetCurrentAccount: (state) => ({
      ...state,
      currentAccountData: { ...initialState.currentAccountData },
    }),
    resetCurrentArrestsData: (state) => ({
      ...state,
      currentArrestsData: { ...initialState.currentArrestsData },
    }),

    getAccountTransactions: getStateFromParams,
    getAccountTransactionsSuccessful: getStateFromParams,
    getAccountTransactionsFailure: getStateFromParams,

    getAccountArrests: getStateFromParams,
    getAccountArrestsSuccessful: getStateFromParams,
    getAccountArrestsFailure: getStateFromParams,

    getAccount: getStateFromParams,
    getAccountSuccessful: getStateFromParams,
    getAccountFailure: getStateFromParams,

    getAccounts: getStateFromParams,
    getAccountsSuccessful: getStateFromParams,
    getAccountsFailure: getStateFromParams,

    getAccountExtendedStatement: getStateFromParams,
    getAccountExtendedStatementSuccessful: getStateFromParams,
    getAccountExtendedStatementFailure: getStateFromParams,

    exportAccountExtendedStatement: getStateFromParams,
    exportAccountExtendedStatementSuccessful: getStateFromParams,
    exportAccountExtendedStatementFailure: getStateFromParams,

    exportTransactionsData: getStateFromParams,
    exportTransactionsDataSuccessful: getStateFromParams,
    exportTransactionsDataFailure: getStateFromParams,
  },
});

export const { name, actions, reducer } = accountsSlice;
