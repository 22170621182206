import React from 'react';
import MailSvgIcon from '../../icons/Mail';
import styles from './assets/styles/index.module.css';
export var MailNotificationItem = function (props) {
    var _a;
    return (React.createElement(React.Fragment, null,
        React.createElement(MailSvgIcon, null),
        React.createElement(NotificationBadge, { count: (_a = props.count) !== null && _a !== void 0 ? _a : 0 })));
};
export var NotificationItem = function (props) {
    var _a, _b;
    return (React.createElement(React.Fragment, null, (_a = props.title) !== null && _a !== void 0 ? _a : '',
        React.createElement(NotificationBadge, { count: (_b = props.count) !== null && _b !== void 0 ? _b : 0 })));
};
function NotificationBadge(props) {
    return (React.createElement("div", { className: styles.notyBlock }, props.count > 0 ? React.createElement("div", { className: styles.notyBadge }, props.count) : null));
}
