import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import routingConfig from 'configs/routing';

import { name, selectors, IUserState } from '../../store';
import { selectors as authSelectors } from '../../../auth/store';
import { actions as authActions } from 'modules/auth/store';

import Component, { IUserWidgetProps, IUserWidgetActions } from './component';

const mapStateToProps = (state: { [name]: IUserState }): IUserWidgetProps => {
  return {
    profile: selectors.selectProfile(state),
    dealer: selectors.selectOrganization(state),
    dealerName: selectors.selectDealerValueByField(state)('name'),
    userDealers: authSelectors.selectUserDealers(state),
    email: selectors.selectEmail(state),
    profileLink: routingConfig.profile.path,
  };
};

const mapDispatchToProps = (dispatch: any): IUserWidgetActions => ({
  authActions: bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
