import React from 'react';
import Logo from '../../icons/Logo';
import styles from './assets/styles/index.module.css';
export var AuthTemplate = function (props) { return (React.createElement("div", { className: styles.main },
    React.createElement("div", { className: styles.logo },
        React.createElement("a", { href: "/" },
            React.createElement(Logo, null))),
    React.createElement("div", { className: styles.contentWrapper },
        React.createElement("div", { className: styles.sidebarLeft }, props.sidebarLeft),
        React.createElement("div", { className: styles.content }, props.content),
        React.createElement("div", { className: styles.sidebarRight }, props.sidebarRight)))); };
export default AuthTemplate;
