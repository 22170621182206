import React from 'react';

import { History } from '@rfb/ui-kit';

import routingConfig from 'configs/routing';

import AppTemplate from 'templates/AppTemplate';

import DetailedRestrictions from 'modules/accounts/components/DetailedRestrictions';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const AccountRestrictionsByIdPage = (props: TODO_ANY) => {
  const renderContent = () => (
    <div className={styles.main}>
      <DetailedRestrictions id={props.match.params.id} />
    </div>
  );

  const renderSidebarLeft = () => <History type="back" to={routingConfig.accounts.path} />;

  return <AppTemplate content={renderContent()} sidebarLeft={renderSidebarLeft()} />;
};

export default AccountRestrictionsByIdPage;
