import { rest } from 'msw';
import { requestData_GET, requestData_POST_ResponseBody } from './requestDataIndex';
//import {requestData_POST_ResponseBody} from "../request-data/requestData";

export const requestDataIndexHandlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/request-data/index`, (req, res, ctx) => {
    console.log('msw-handlers: GET /request-data/index');
    return res(ctx.status(200), ctx.json(requestData_GET));
  }),
  rest.post(`${process.env.REACT_APP_API_URL}/request-data`, (req, res, ctx) => {
    console.log('msw-handlers: POST /request-data');
    return res(ctx.status(200), ctx.json(requestData_POST_ResponseBody));
  }),
];
