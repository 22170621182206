import fp from 'lodash/fp';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

export interface IValidatorConfig {
  name: string;
  rule: TODO_ANY;
  setError: Function;
}
export const configureValidator = <T>({ name, rule, setError }: IValidatorConfig) => (value: T) =>
  rule
    .validate(value)
    .then((): void => setError({ [name]: [] }))
    .catch((error: TODO_ANY): Promise<never> => {
      setError({ [name]: [error.message] });
      return Promise.reject();
    });

export const runRulesChain = (rules: Promise<any>[]) =>
  fp.reduce((acc, rule) => acc.finally(() => rule), Promise.resolve(), rules);
