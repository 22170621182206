import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { IFinancialState, actions, name, selectors } from '../../store';

import Component, {
  UploadFormRepoActions,
  UploadFormRepoProps,
  UploadFormRepoPropsExternal,
} from './component';

import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IFinancialState },
  props: UploadFormRepoPropsExternal
): UploadFormRepoPropsExternal & UploadFormRepoProps => {
  const form = selectors.selectForm(state);

  return {
    operationDataId: selectors.selectOperationDataId(state),
    isLoading: selectors.selectIsLoading(state),
    attachmentsErrorList: selectors.selectErrorListByField(state)('repoAttachments'),
    apiErrorList: selectors.selectErrorListByField(state)('api'),
    attachments: selectors.selectRepoAttachments(state),
    uploadedRepoAttachments: selectors.selectUploadedRepoAttachments(state),
    ...props,
  };
};

const mapDispatchToProps = (dispatch: TODO_ANY): UploadFormRepoActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
