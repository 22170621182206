import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import storage from 'utils/storage';

import { name, actions, selectors, IAuthState } from '../../store';

import Component, {
  IAuthPhoneNumberValidationProps,
  IAuthPhoneNumberValidationPropsExternal,
  IAuthPhoneNumberValidationActions,
} from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IAuthState },
  props: { history: TODO_ANY }
): IAuthPhoneNumberValidationProps & IAuthPhoneNumberValidationPropsExternal => ({
  email: storage.get('email'),
  phoneNumber: selectors.selectPhoneNumber(state),
  phoneNumberErrorList: selectors.selectErrorListByField(state)('phoneNumber'),
  apiErrorList: selectors.selectErrorListByField(state)('api'),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): IAuthPhoneNumberValidationActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
