import { connect } from 'react-redux';

import { name, selectors, IUserState } from '../../store';

import Component, { IUserProfileProps } from './component';

const mapStateToProps = (state: { [name]: IUserState }): IUserProfileProps => ({
  dealerName: selectors.selectDealerValueByField(state)('name'),
  userName: selectors.selectFullName(state),
  inn: selectors.selectDealerValueByField(state)('inn'),
  kpp: selectors.selectDealerValueByField(state)('kpp'),
  email: selectors.selectEmail(state),
  phoneNumber: selectors.selectPhoneNumber(state),
  authorityDateRange: selectors.selectAuthorityDateRange(state),
  passwordExpiredDate: selectors.selectPasswordExpiredDate(state),
  passwordExpiredRemainDays: selectors.selectPasswordExpiredRemainDays(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
