import React, { useEffect } from 'react';
import { Html } from '@rfb/ui-kit';

import routingConfig from '../../../../configs/routing';
import { dealerSystemsKey } from 'modules/auth/store';
import storage from 'utils/storage';
import SystemChoice from '../common/SystemChoice';

export interface IInformationContactsProps {
  rb_text: string;
  rf_text: string;
}

export interface IInformationContactsPropsExternal {
  history: {
    push: (path: string) => void;
  };
}

export interface IInformationContactsActions {
  actions: {
    getContactsText: Function;
  };
}

const Contacts = (
  props: IInformationContactsProps & IInformationContactsPropsExternal & IInformationContactsActions
) => {
  useEffect(() => {
    props.actions.getContactsText();
  }, []);

  const dealerSystemConfig = storage.get(dealerSystemsKey).data;
  const oneSystemPath = routingConfig.contacts.path;
  return (
    <div>
      <SystemChoice
        dealerSystemConfig={dealerSystemConfig}
        twoSystemCaption="Контакты"
        oneSystemCaptionTemplate="Контакты"
        rbRenderContent={<Html text={props.rb_text} />}
        rfRenderContent={<Html text={props.rf_text} />}
        oneSystemPath={oneSystemPath}
        twoSystemPath={routingConfig.twoSystemContacts.path}
        rbButtonName={'Контакты РОСБАНК АВТО'}
        rfButtonName={'Контакты РУСФИНАНС'}
        isTwoButtonNeeded={() => dealerSystemConfig.rf_flag === 1}
      />
    </div>
  );
};

export default Contacts;
