import { ApiErrors } from '@rfb/common';

export const dealerSystem_BothResponseExceptionNull = {
  data: {
    rb_flag: 1,
    rf_flag: 1,
  },
  exception: null,
};

export const dealerSystem_BothResponse = {
  data: {
    rb_flag: 1,
    rf_flag: 1,
  },
};

export const dealerSystem_ServerUnavailableResponse = {
  data: {
    rb_flag: 0,
    rf_flag: 0,
  },
  exception: {
    code: ApiErrors.SERVER_TEMPORARILY_UNAVAILABLE, // 1120
    message_rb: 'message_rb-error-text',
    message_rf: 'message_rf-error-text',
  },
};

export const dealerSystem_RfOnlyResponse = {
  data: {
    rb_flag: 0,
    rf_flag: 1,
  },
  exception: {
    code: ApiErrors.ROSBANK_AVTO_TEMPORARY_UNAVAILABLE, // 1121
    message_rb: 'message_rb-error-text',
    message_rf: '',
  },
};

export const dealerSystem_RfOnlyResponse_ForButtonsNumberError = {
  data: {
    rb_flag: 0,
    rf_flag: 1,
  },
  exception: {
    code: 1123,
    message_rf: null,
    message_rb: '404–diasoftId не найден',
  },
};

export const dealerSystem_RbOnlyResponse = {
  data: {
    rb_flag: 1,
    rf_flag: 0,
  },
  exception: {
    code: ApiErrors.RUSFINANCE_TEMPORARY_UNAVAILABLE, // 1122
    message_rb: '',
    message_rf: 'message_rf-error-text',
  },
};

export const dealerSystem_RbOnlyWithExceptionNullResponse = {
  data: {
    rb_flag: 1,
    rf_flag: 0,
  },
  exception: null,
};

export const dealerSystem_200_1121_WithExceptionNullResponse = {
  data: {
    rb_flag: 0,
    rf_flag: 0,
  },
  exception: {
    code: ApiErrors.ROSBANK_AVTO_TEMPORARY_UNAVAILABLE,
    message_rf: null,
    message_rb: 'message_rb-error-text',
  },
};

export const dealerSystem_403_1032_responseBody = [
  {
    name: 'Forbidden',
    message: 'Identity identity missing diasoft',
    code: ApiErrors.NOT_CONFIRMED_LOGIN,
    status: 403,
    type: 'yii\\web\\ForbiddenHttpException',
  },
];

export const dealerSystem_403_1033_responseBody = [
  {
    name: 'Forbidden',
    message: 'Identity identity missing diasoft',
    code: ApiErrors.NOT_CHOOSED_DEALER,
    status: 403,
    type: 'yii\\web\\ForbiddenHttpException',
  },
];

export const dealerSystem_403_1030_responseBody = [
  {
    name: 'Forbidden',
    message: 'Identity identity missing diasoft',
    code: ApiErrors.RF_INFO_VALIDATION_ERROR,
    status: 403,
    type: 'yii\\web\\ForbiddenHttpException',
  },
];

export const dealerSystem_403_Other_responseBody = [
  {
    name: 'Forbidden',
    message: 'Identity identity missing diasoft',
    code: 9999,
    status: 403,
    type: 'yii\\web\\ForbiddenHttpException',
  },
];
