import { useEffect, useRef, useState } from 'react';
var calculateTime = function (currentSeconds) {
    var hours = Math.floor(currentSeconds / 3600);
    var time = currentSeconds - hours * 3600;
    var minutes = Math.floor(time / 60);
    var seconds = time - minutes * 60;
    return {
        hours: String(hours),
        minutes: String(minutes),
        seconds: seconds < 10 ? "0" + String(seconds) : String(seconds),
    };
};
var useInterval = function (callback, delay) {
    var savedCallback = useRef(callback);
    // Remember the latest callback.
    useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    useEffect(function () {
        var id = setInterval(function () {
            savedCallback.current();
        }, delay);
        return function () { return clearInterval(id); };
    }, [delay]);
};
export var useTimer = function (_a) {
    var startTime = _a.startTime, active = _a.active;
    var _b = useState(calculateTime(startTime)), timeLeft = _b[0], setTimeLeft = _b[1];
    var _c = useState(startTime), currentSeconds = _c[0], setCurrentSeconds = _c[1];
    var restart = function () {
        setCurrentSeconds(startTime);
    };
    var nextTime = function () {
        if (currentSeconds !== 0 && active) {
            setTimeLeft(calculateTime(currentSeconds - 1));
            setCurrentSeconds(function (currentSeconds) { return currentSeconds - 1; });
        }
    };
    useInterval(nextTime, 1000);
    return { secondsLeft: currentSeconds, currentTime: timeLeft, restart: restart };
};
