import React from 'react';
import styles from './assets/styles/index.module.css';
import cn from 'classnames';
import fp from 'lodash/fp';
import { DealerSystems } from '@rfb/common';
import Button from '../Button';
import ArrowRight from '../../icons/ArrowRight';
export var SystemNavigation = function (props) {
    var renderArrow = function () { return (React.createElement("div", { className: styles.arrow },
        React.createElement(ArrowRight, null))); };
    var renderButton = function (dealerSystem) {
        var _a;
        return (React.createElement("div", { className: cn((_a = {},
                _a[styles.badge] = true,
                _a[styles.badgeRed] = true,
                _a)) },
            React.createElement(Button, { wrapperClassName: styles.button, view: "square", text: dealerSystem === DealerSystems.ROSBANK_AVTO ? props.rb_name : props.rf_name, sidebarRight: renderArrow(), onClick: function () {
                    props.onSelect(dealerSystem);
                } })));
    };
    return (React.createElement("div", { className: styles.main }, fp.map(renderButton, [DealerSystems.ROSBANK_AVTO, DealerSystems.RUSFINANCE])));
};
export default SystemNavigation;
