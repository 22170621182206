import React, { useEffect } from 'react';

import { UserWidget } from '@rfb/ui-kit';
import routingConfig from 'configs/routing';
import { IDTOProfileViewResponse } from 'dto/profile';
import { DTOUserInfo } from 'dto/rf-info';
import storage from 'utils/storage';
import storageDB from 'utils/storage-db';
import { dealerDiasoftIdKey } from '../../../app/store/sagas';

export interface IUserWidgetProps {
  profile: IDTOProfileViewResponse;
  dealer: object;
  dealerName: string;
  email: string;
  profileLink: string;
  userDealers?: DTOUserInfo[];
}

export interface IUserWidgetActions {
  authActions: {
    chooseDealer: Function;
    getUserDealer: Function;
    logout: Function;
  };
}

const Widget = (props: IUserWidgetProps & IUserWidgetActions) => {
  useEffect(() => {
    props.authActions.getUserDealer({ autoChooseDealer: false });
  }, []);

  return (
    <UserWidget
      name={props.dealerName}
      email={props.email}
      link={props.profileLink}
      onLogout={() => { // Выход из организации (а не из приложения)
        if (!props.userDealers || props.userDealers?.length <= 1) {
          props.authActions.logout();
        } else {
          props.authActions.chooseDealer({
            data: { diasoft_id: '' },
            onSuccess: () => {
              storage.remove(dealerDiasoftIdKey);
              storage.remove('dealerName');
              storageDB.set('tranche-payment-list', []);
              window.location.replace(routingConfig.login.path);
            },
          });
        }
      }}
    />
  );
};

export default Widget;
