import React from 'react';
import cn from 'classnames';
import fp from 'lodash/fp';
import styles from './assets/styles/index.module.css';
export var Button = function (props) {
    var _a;
    var isCircleView = fp.isEqual(props.view, 'circle');
    var isSquareView = fp.isEqual(props.view, 'square');
    var buttonClassNames = cn((_a = {},
        _a[styles.main] = true,
        _a[styles.active] = !props.isDisabled,
        _a[styles.viewCircle] = isCircleView,
        _a[styles.viewSquare] = isSquareView,
        _a[styles.disabled] = props.isDisabled,
        _a));
    return (React.createElement("span", { className: cn(props.wrapperClassName) },
        React.createElement("button", { className: buttonClassNames, type: props.type, disabled: props.isDisabled, onClick: props.onClick ? props.onClick : fp.noop },
            props.text,
            React.createElement("div", { className: styles.sidebarRight }, props.sidebarRight))));
};
Button.defaultProps = {
    type: 'button',
    view: 'circle',
    isDisabled: false,
};
export default Button;
