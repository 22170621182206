import React from 'react';

import { Text, Link } from '@rfb/ui-kit';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

export interface IUserProfileProps {
  dealerName: string;
  userName: string;
  inn: string;
  kpp: string;
  email: string;
  phoneNumber: string;
  authorityDateRange: string;
  passwordExpiredDate: string;
  passwordExpiredRemainDays: number;
}

const formatPhoneNumber = (value: string): string => {
  if (!value) {
    return value;
  }

  // Filter only numbers from the input
  const cleaned = value.replace(/\D/g, '');

  // Check if the input is of correct (takes only 79999999999 numbers now)
  const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    const intlCode = match[1] ? '+7 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
  }
  return '';
};

const Main = (props: IUserProfileProps) => {
  const renderPasswordText = (): React.ReactNode => (
    <div className={styles.password}>
      <div className={styles.passwordText}>действителен до {props.passwordExpiredDate}</div>
      <div className={styles.passwordExplanation}>
        В целях безопасности, необходимо изменять пароль каждые три месяца (дней до смены пароля -{' '}
        {props.passwordExpiredRemainDays})
      </div>
    </div>
  );

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>Профиль</h1>

      <div className={styles.info}>
        <Text label="Юридическое лицо" content={props.dealerName} />
        <Text label="ИНН / КПП" content={`${props.inn} / ${props.kpp}`} />
        <Text label="Срок полномочий" content={props.authorityDateRange} />
        <div className={styles.info}>
          <Text
            label="Представитель"
            content={props.userName}
            sidebarRight={
              <div className={styles.link}>
                <Link title="Изменить" to={routingConfig.profileFioChange.path} />
              </div>
            }
          />
          <Text label="Логин (E-mail)" content={props.email} />
          <Text
            label="Номер мобильного телефона"
            content={formatPhoneNumber(props.phoneNumber)}
            sidebarRight={
              <div className={styles.link}>
                <Link title="Изменить" to={routingConfig.profilePhoneNumberChange.path} />
              </div>
            }
          />
          <Text
            label="Пароль"
            content={renderPasswordText()}
            sidebarRight={
              <div className={styles.link}>
                <Link title="Изменить" to={routingConfig.profilePasswordChange.path} />
              </div>
            }
          />
          <Text
            label="Кодовое слово"
            content="*****"
            sidebarRight={
              <div className={styles.link}>
                <Link title="Изменить" to={routingConfig.profileCodeWordChange.path} />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
