var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Change = function (props) { return (React.createElement("svg", __assign({}, props, { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M22.25 2.75L9.25 14.75V18.25H12.75L25.75 6.25L22.25 2.75Z", stroke: "currentColor", "stroke-width": "1.5" }),
    React.createElement("path", { d: "M19 6L22.5 9.5", stroke: "currentColor", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M16.5 5.5L14.875 7H5.5V22H20.5V13.8846L22 12.5V23.5H4V5.5H16.5Z", fill: "currentColor" }),
    React.createElement("path", { d: "M16.5 5.5L14.875 7H5.5V22H20.5V13.8846L22 12.5V23.5H4V5.5H16.5Z", stroke: "currentColor" }))); };
export default Change;
