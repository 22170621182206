import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { name, actions, selectors, IAuthState } from '../../store';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

import Component, {
  IAuthPasswordRestoreProps,
  IAuthPasswordRestorePropsExternal,
  IAuthPasswordRestoreActions,
} from './component';

const mapStateToProps = (
  state: { [name]: IAuthState },
  props: { history: TODO_ANY }
): IAuthPasswordRestoreProps & IAuthPasswordRestorePropsExternal => ({
  login: selectors.selectLogin(state),
  loginErrorList: selectors.selectErrorListByField(state)('login'),
  apiErrorList: selectors.selectErrorListByField(state)('api'),
  isSending: selectors.selectIsSending(state),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): IAuthPasswordRestoreActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
