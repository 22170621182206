import { rest } from 'msw';

export const operationDataRequestUsedRefreshStatusHandlers = [
  rest.post(
    `${process.env.REACT_APP_NEW_API_URL}/operation-data/request/used/refresh-status`,
    (req, res, ctx) => {
      console.log('msw-handlers: POST /operation-data/request/used/refresh-status');
      return res(ctx.status(204));
    }
  ),
];
