var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Calendar = function (props) { return (React.createElement("svg", __assign({}, props, { width: "16", height: "17", viewBox: "0 0 16 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M15 2H1V16H15V2Z", stroke: "#282423", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M4 0V2", stroke: "#282423", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M12 0V2", stroke: "#282423", strokeWidth: "1.5" }),
    React.createElement("rect", { x: "4.125", y: "8.625", width: "0.75", height: "0.75", fill: "white", stroke: "#282423", strokeWidth: "0.75" }),
    React.createElement("rect", { x: "4.125", y: "11.625", width: "0.75", height: "0.75", fill: "white", stroke: "#282423", strokeWidth: "0.75" }),
    React.createElement("rect", { x: "7.625", y: "8.625", width: "0.75", height: "0.75", fill: "white", stroke: "#282423", strokeWidth: "0.75" }),
    React.createElement("rect", { x: "7.625", y: "11.625", width: "0.75", height: "0.75", fill: "white", stroke: "#282423", strokeWidth: "0.75" }),
    React.createElement("rect", { x: "11.125", y: "8.625", width: "0.75", height: "0.75", fill: "white", stroke: "#282423", strokeWidth: "0.75" }),
    React.createElement("rect", { x: "11.125", y: "11.625", width: "0.75", height: "0.75", fill: "white", stroke: "#282423", strokeWidth: "0.75" }),
    React.createElement("path", { d: "M1 6H15", stroke: "#282423", strokeWidth: "1.5" }))); };
export default Calendar;
