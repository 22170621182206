import React, { useEffect } from 'react';

import cn from 'classnames';
import fp from 'lodash/fp';

import { Button, Loader, Condition, MessageList } from '@rfb/ui-kit';

import { ReactComponent as ArrowForwardIcon } from './assets/images/arrow-right.svg';
import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

export interface IDealerProps {
  diasoft_id?: string;
  isLoading: boolean;
  config: IDealerElement[];
  apiErrorList: string[];
}

export interface IDealerElement {
  diasoft_id: string;
  name: string;
}

export interface IDealerPropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void;
  };
}

export interface IDealerActions {
  actions: {
    reset: Function;
    getUserDealer: Function;
    chooseDealer: Function;
  };
}

const Dealer = (props: IDealerProps & IDealerPropsExternal & IDealerActions) => {
  useEffect(() => {
    return () => {
      props.actions.reset();
    };
    // ESLINT Необходимо выполнение только в момент размонтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.config.length === 0) {
      props.actions.getUserDealer({ history: props.history, autoChooseDealer: true });
    }
  }, []);

  const renderArrow = () => (
    <div className={styles.arrow}>
      <ArrowForwardIcon />
    </div>
  );

  const renderNavButton = (item: IDealerElement) => (
    <div
      key={item.diasoft_id}
      className={cn({
        [styles.badge]: true,
        [styles.badgeRed]: props.diasoft_id === item.diasoft_id,
      })}
    >
      <Button
        wrapperClassName={styles.button}
        view="square"
        text={item.name}
        sidebarRight={renderArrow()}
        onClick={() => {
          const data = { diasoft_id: item.diasoft_id, dealerName: item.name };
          props.actions.chooseDealer({
            data,
            onSuccess: () => {
              props.history.push(routingConfig.main.path);
            },
          });
        }}
      />
    </div>
  );

  if (props.isLoading) {
    return <Loader wrapperClassName={styles.main} />;
  }

  const isApiError = !fp.isEmpty(props.apiErrorList);
  return (
    <div>
      <Condition value={isApiError}>
        <MessageList type="error" messages={props.apiErrorList} />
      </Condition>
      <div className={styles.main}>{fp.map(renderNavButton, props.config)}</div>
    </div>
  );
};

export default Dealer;
