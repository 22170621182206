import validator from 'utils/validator';

import fp from 'lodash/fp';

import translationConfig from 'configs/validation/translations';

export const loginRules = validator
  .string()
  .required()
  .email()
  .min(1)
  .max(256)
  .test('not-cyrillic', 'Некорректный email', (value) => !/[а-яёА-ЯЁ]+/.test(value || ''));
export const passwordAuthRules = validator
  .string()
  .required()
  .min(8)
  .matches(/^(?=.*[a-z а-я])(?=.*[A-Z А-Я])(?=.*[0-9])/, translationConfig.app.passwordAuth);

export const passwordRules = validator
  .string()
  .required()
  .min(8)
  .test(
    'password-validation-rule',
    translationConfig.app.password,
    (value: string | null | undefined): boolean => {
      const minGroupCount: number = 3;

      const typesRules: { [name: string]: RegExp } = {
        lower: /^(?=.*[a-zа-я])/,
        upper: /^(?=.*[A-ZА-Я])/,
        number: /^(?=.*[\d])/,
        symbols: /^(?=.*[@$!%*#?&])/,
      };

      const successfulResultList: boolean[] = fp.pipe(
        fp.values,
        fp.map((typeRule: RegExp): boolean => typeRule.test(value!)),
        fp.filter(Boolean)
      )(typesRules);

      return fp.gte(fp.size(successfulResultList), minGroupCount);
    }
  );

export const passwordRepeatRules = (password: string) =>
  validator
    .string()
    .required()
    .test('password-repeat-rule', translationConfig.app.passwordRepeat, fp.isEqual(password));

export const phoneRules = validator
  .string()
  .required()
  .matches(/^(7|)?(\d{10})$/, translationConfig.app.phoneIncorrect)
  .length(11);

export const codeWordRules = validator.string().required().uppercase().trim().min(3).max(30);

export const innRules = validator
  .string()
  .required()
  .test('inn-is-number', translationConfig.number.typeError, fp.gt(fp.__, 0))
  .min(10)
  .max(12);

export const kppRules = validator
  .string()
  .required()
  .test('kpp-is-number', translationConfig.number.typeError, fp.gt(fp.__, 0))
  .length(9);
