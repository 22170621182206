"use strict";
exports.__esModule = true;
exports.ApiErrors = void 0;
var ApiErrors;
(function (ApiErrors) {
    ApiErrors[ApiErrors["USER_NOT_FOUND"] = 1000] = "USER_NOT_FOUND";
    ApiErrors[ApiErrors["USER_BLOCKED"] = 1001] = "USER_BLOCKED";
    ApiErrors[ApiErrors["USER_BLOCKED_BY_ADMIN"] = 1002] = "USER_BLOCKED_BY_ADMIN";
    ApiErrors[ApiErrors["USER_BLOCKED_FAILURE_AUTH"] = 1003] = "USER_BLOCKED_FAILURE_AUTH";
    ApiErrors[ApiErrors["USER_INACTIVE"] = 1010] = "USER_INACTIVE";
    ApiErrors[ApiErrors["PASSWORD_EXPIRED"] = 1020] = "PASSWORD_EXPIRED";
    ApiErrors[ApiErrors["RF_INFO_VALIDATION_ERROR"] = 1030] = "RF_INFO_VALIDATION_ERROR";
    ApiErrors[ApiErrors["NOT_CONFIRMED_LOGIN"] = 1032] = "NOT_CONFIRMED_LOGIN";
    ApiErrors[ApiErrors["NOT_CHOOSED_DEALER"] = 1033] = "NOT_CHOOSED_DEALER";
    ApiErrors[ApiErrors["SERVICE_TERMS_ERROR"] = 1040] = "SERVICE_TERMS_ERROR";
    ApiErrors[ApiErrors["INCORRECT_LOGIN_PASSWORD"] = 1050] = "INCORRECT_LOGIN_PASSWORD";
    ApiErrors[ApiErrors["TEMP_PASSWORD_FAILURE_AUTH"] = 1051] = "TEMP_PASSWORD_FAILURE_AUTH";
    ApiErrors[ApiErrors["PASSWORD_CHANGE_REQUIRED_TIME_NOT_PASSED"] = 1052] = "PASSWORD_CHANGE_REQUIRED_TIME_NOT_PASSED";
    ApiErrors[ApiErrors["PASSWORD_CHANGE_SAME_VALUE"] = 1053] = "PASSWORD_CHANGE_SAME_VALUE";
    ApiErrors[ApiErrors["OLD_PASSWORD_INCORRECT"] = 1054] = "OLD_PASSWORD_INCORRECT";
    ApiErrors[ApiErrors["INN_KPP_FAILURE_CHECK"] = 1060] = "INN_KPP_FAILURE_CHECK";
    ApiErrors[ApiErrors["RF_INFO_ERROR"] = 2010] = "RF_INFO_ERROR";
    ApiErrors[ApiErrors["PHONE_NUMBER_ERROR"] = 1031] = "PHONE_NUMBER_ERROR";
    ApiErrors[ApiErrors["WRONG_BRAND_ERROR"] = 1055] = "WRONG_BRAND_ERROR";
    ApiErrors[ApiErrors["INVALID_PHONE_NUMBER"] = 1090] = "INVALID_PHONE_NUMBER";
    ApiErrors[ApiErrors["SERVER_TEMPORARILY_UNAVAILABLE"] = 1120] = "SERVER_TEMPORARILY_UNAVAILABLE";
    ApiErrors[ApiErrors["ROSBANK_AVTO_TEMPORARY_UNAVAILABLE"] = 1121] = "ROSBANK_AVTO_TEMPORARY_UNAVAILABLE";
    ApiErrors[ApiErrors["RUSFINANCE_TEMPORARY_UNAVAILABLE"] = 1122] = "RUSFINANCE_TEMPORARY_UNAVAILABLE";
    ApiErrors[ApiErrors["PHONE_NUMBER_RELOAD"] = 2020] = "PHONE_NUMBER_RELOAD";
    ApiErrors[ApiErrors["DEALER_NOT_ATTACHED"] = 3010] = "DEALER_NOT_ATTACHED";
})(ApiErrors = exports.ApiErrors || (exports.ApiErrors = {}));
exports["default"] = ApiErrors;
