import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';
import fp from 'lodash/fp';
import { Directions, getTransformedDate } from '@rfb/common';
import CalendarPopup from '../../../../../CalendarPopup';
import DatePickerRange from '../../../../../DatePickerRange';
import { Condition } from '../../../../../../services';
// @ts-ignore
import { IMaskInput } from 'react-imask';
import CloseIcon from '../../../../../../icons/Close';
import DirectionDownIcon from '../../../../../../icons/ArrowClosed';
import DirectionUpIcon from '../../.././../../../icons/ArrowOpened';
import ClipIcon from '../../.././../../../icons/Clip';
import SortingLetterAscIcon from '../../../../../../icons/SortingLetterAsc';
import SortingLetterDescIcon from '../../../../../../icons/SortingLetterDesc';
import SortingNumberAscIcon from '../../../../../../icons/SortingNumberAsc';
import SortingNumberDescIcon from '../../../../../../icons/SortingNumberDesc';
import styles from './assets/styles/index.module.css';
export var Header = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h, _j;
    var _k = useState(false), isOpen = _k[0], setIsOpen = _k[1];
    var isDescSortingDirection = fp.isEqual((_h = props.sorting) === null || _h === void 0 ? void 0 : _h.direction, Directions.DESC);
    var isActiveSorting = fp.isEqual(props.value, (_j = props.sorting) === null || _j === void 0 ? void 0 : _j.value);
    var sortingDirectionClassNames = cn((_a = {},
        _a[styles.sortingIcon] = true,
        _a[styles.sortingIconActive] = isActiveSorting,
        _a));
    var sortingClassNames = cn((_b = {},
        _b[styles.sorting] = true,
        _b[styles.disabled] = props.isDisabled,
        _b));
    var inputClassNames = cn((_c = {},
        _c[styles.filter] = true,
        _c[styles.disabled] = props.isDisabled,
        _c));
    var selectClassNames = cn((_d = {},
        _d[styles.filter] = true,
        _d[styles.select] = true,
        _d[styles.disabled] = props.isDisabled,
        _d));
    var dateClassNames = cn((_e = {},
        _e[styles.filter] = true,
        _e));
    var calendarPopupWrapperClassNames = cn((_f = {},
        _f[styles.calendarWrapper] = true,
        _f[styles.disabled] = props.isDisabled,
        _f));
    var calendarDateRangeWrapperClassNames = cn((_g = {},
        _g[styles.filter] = true,
        _g[styles.calendarDateRangeWrapper] = true,
        _g[styles.disabled] = props.isDisabled,
        _g));
    var getDirectionIcon = function () {
        if (isActiveSorting)
            return isDescSortingDirection ? DirectionUpIcon : DirectionDownIcon;
        return DirectionDownIcon;
    };
    // TODO Move renderDropdown to a separate file
    var renderDropdown = function () {
        var sortingLetter = [
            { icon: SortingLetterAscIcon, title: 'По алфавиту от А до Я', direction: Directions.ASC },
            { icon: SortingLetterDescIcon, title: 'По алфавиту от Я до А', direction: Directions.DESC },
        ];
        var sortingNumber = [
            { icon: SortingNumberDescIcon, title: 'По убыванию', direction: Directions.DESC },
            { icon: SortingNumberAscIcon, title: 'По возрастанию', direction: Directions.ASC },
        ];
        var renderSortingItem = function (item) {
            var _a;
            var Icon = item.icon;
            return (React.createElement("div", { key: item.direction, className: cn((_a = {},
                    _a[styles.dropdownItem] = true,
                    _a[styles.dropdownItemActive] = fp.isEqual({ value: props.value, direction: item.direction }, props.sorting),
                    _a)), onClick: function () {
                    props.onSortingChange({ value: props.value, direction: item.direction });
                    setIsOpen(false);
                } },
                React.createElement(Icon, { className: styles.dropdownItemIcon }),
                item.title));
        };
        var renderFilterItem = function (value) {
            var _a;
            return (React.createElement("div", { key: value, className: cn((_a = {},
                    _a[styles.dropdownItem] = true,
                    _a[styles.dropdownItemActive] = fp.isEqual(value, props.filterValue),
                    _a)), onClick: function () { return props.onFilterChange(value); } }, value));
        };
        return (React.createElement(OutsideClickHandler, { onOutsideClick: function () { return setTimeout(function () { return setIsOpen(false); }); } },
            React.createElement("div", { className: styles.dropdown },
                React.createElement("div", { className: styles.dropdownGroup }, fp.map(renderSortingItem, fp.isEqual(props.sortingType, 'number') ? sortingNumber : sortingLetter)),
                React.createElement(Condition, { value: !fp.isEmpty(props.filterList) },
                    React.createElement("div", { className: cn([styles.dropdownGroup, styles.dropdownGroupFilterList]) }, fp.map(renderFilterItem, props.filterList))))));
    };
    var renderDirectionArrow = function () {
        var Icon = getDirectionIcon();
        return React.createElement(Icon, { className: sortingDirectionClassNames });
    };
    var renderAttachmentHeader = function () {
        var Icon = ClipIcon;
        return React.createElement(Icon, { className: styles.title });
    };
    var renderDefaultHeader = function () { return (React.createElement("div", { className: styles.title, title: props.title }, props.title)); };
    var renderSortingHeader = function () { return (React.createElement("div", { className: sortingClassNames, onClick: function () { return setIsOpen(!isOpen); } },
        React.createElement("div", { className: styles.title }, props.title),
        renderDirectionArrow(),
        React.createElement(Condition, { value: isOpen }, renderDropdown()))); };
    var renderInputHeader = function () { return (React.createElement("div", { className: inputClassNames },
        React.createElement("input", { className: styles.input, type: "input", defaultValue: props.filterValue, placeholder: props.title, title: props.inputTooltipText, onChange: function (_a) {
                var target = _a.target;
                return props.onFilterChange(target.value);
            } }))); };
    var renderPhoneInputHeader = function () { return (React.createElement("div", { className: inputClassNames },
        React.createElement(IMaskInput
        //@ts-ignore
        , { 
            //@ts-ignore
            className: styles.input, value: props.filterValue, placeholder: props.title, mask: "+{7} (000) 000-00-00", title: props.inputTooltipText, placeholderChar: "0", unmask: true, lazy: false, onAccept: function (value) {
                props.onFilterChange(value);
            } }))); };
    var renderSelectHeader = function () { return (React.createElement("div", { className: selectClassNames, onClick: function () { return setIsOpen(!isOpen); } },
        React.createElement("div", { className: styles.selectTypeTitle }, props.filterValue ? props.filterValue : props.title),
        React.createElement(Condition, { value: !fp.isEmpty(props.filterValue) },
            React.createElement(CloseIcon, { className: styles.close, onClick: function (event) {
                    event.stopPropagation();
                    props.onFilterChange('');
                } })),
        React.createElement(Condition, { value: !props.filterValue }, renderDirectionArrow()),
        React.createElement(Condition, { value: isOpen }, renderDropdown()))); };
    var renderDateHeader = function () {
        var renderDisplayedButton = function () { return (React.createElement("div", { className: dateClassNames },
            props.filterValue ? getTransformedDate(props.filterValue) : props.title,
            renderDirectionArrow())); };
        return (React.createElement(CalendarPopup, { wrapperClassName: calendarPopupWrapperClassNames, displayedButton: renderDisplayedButton(), isDisabled: props.isDisabled, onCalendarChange: function (date) {
                props.onFilterChange(date);
            } }));
    };
    var renderDateRangeHeader = function () {
        var renderDisplayedButtonPostfix = function () { return (React.createElement("div", { className: styles.calendarDateRangeDirectionArrow, onClick: function () { return setIsOpen(!isOpen); } }, renderDirectionArrow())); };
        var _a = props.filterValue, dateStart = _a[0], dateEnd = _a[1];
        return (React.createElement("div", { className: styles.calendarDateRangeHeaderWrapper, title: props.inputTooltipText },
            React.createElement(DatePickerRange, { wrapperClassName: calendarDateRangeWrapperClassNames, start: dateStart, end: dateEnd, isDisabled: props.isDisabled, displayedButtonPostfix: renderDisplayedButtonPostfix(), onChange: function (date) {
                    props.onFilterChange(date);
                } }),
            React.createElement(Condition, { value: isOpen },
                React.createElement("div", { className: styles.calendarDateRangeDropdown }, renderDropdown()))));
    };
    var renderHeaderByType = function () {
        if (fp.isEqual(props.type, 'sorting'))
            return renderSortingHeader();
        if (fp.isEqual(props.type, 'input'))
            return renderInputHeader();
        if (fp.isEqual(props.type, 'select'))
            return renderSelectHeader();
        if (fp.isEqual(props.type, 'date'))
            return renderDateHeader();
        if (fp.isEqual(props.type, 'date-range'))
            return renderDateRangeHeader();
        if (fp.isEqual(props.type, 'phone'))
            return renderPhoneInputHeader();
        if (fp.isEqual(props.type, 'attachment'))
            return renderAttachmentHeader();
        return renderDefaultHeader();
    };
    return (React.createElement("div", { key: props.value, className: styles.main, role: "columnheader" }, renderHeaderByType()));
};
export default Header;
