import React from 'react';

import fp from 'lodash/fp';

import { Button, Condition, Input, Form, MessageList } from '@rfb/ui-kit';

import { configureValidator, runRulesChain } from 'helpers/validation';
import { phoneRules } from 'modules/auth/configs/validation';

import { DTOPhoneNumberChangeRequest } from 'dto/security';
import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

export interface IUserPhoneNumberChangeProps {
  phoneNumber: string;
  phoneNumberErrorList: string[];
  apiErrorList: string[];
}

export interface IUserPhoneNumberChangePropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void;
  };
}

export interface IUserPhoneNumberChangeActions {
  actions: {
    setForm: Function;
    setError: Function;
  };
}

const PhoneNumberChange = (
  props: IUserPhoneNumberChangeProps &
    IUserPhoneNumberChangePropsExternal &
    IUserPhoneNumberChangeActions
) => {
  const validators = {
    phone: configureValidator({
      name: 'phone',
      rule: phoneRules,
      setError: props.actions.setError,
    }),
  };

  const onPhoneNumberChange = (phoneNumber: string): void => {
    validators.phone(phoneNumber).finally((): void => props.actions.setForm({ phoneNumber }));
  };

  const onFormSubmit = (): void => {
    const data: DTOPhoneNumberChangeRequest = { phone_number: props.phoneNumber };
    const rules = [validators.phone(data.phone_number)];
    runRulesChain(rules).then(() =>
      props.history.push(routingConfig.profilePhoneNumberChangeConfirmation.path)
    );
  };

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <h1>Изменить Номер мобильного телефона</h1>
      </div>

      <Form onSubmit={onFormSubmit}>
        <Input
          type="phone"
          wrapperClassName={styles.phoneNumber}
          label="Новый номер телефона"
          value={props.phoneNumber}
          onChange={onPhoneNumberChange}
        />
        <MessageList
          type="info"
          messages={['Подтверждаю, что являюсь владельцем введенного Номера мобильного телефона']}
        />
        <MessageList type="error" messages={props.phoneNumberErrorList} />

        <Condition value={!fp.isEmpty(props.apiErrorList)}>
          <MessageList type="error" messages={props.apiErrorList} />
        </Condition>
        <Button type="submit" text="Сохранить" />
      </Form>
    </div>
  );
};

export default PhoneNumberChange;
