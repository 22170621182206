import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { name, actions, selectors, IAccountsState } from '../../store';

import ExtendedStatement, {
  IExtendedStatementPropsExternal,
  IExtendedStatementProps,
  IExtendedStatementActions
} from './component';

const mapStateToProps = (
  state: { [name]: IAccountsState },
  props: IExtendedStatementPropsExternal
  ): IExtendedStatementPropsExternal & IExtendedStatementProps => ({
    id: props.id,
    dateStart: selectors.selectDateStart(state),
    dateEnd: selectors.selectDateEnd(state),
    statementData: selectors.selectExtendedStatementData(state),
    pageCount: selectors.selectStatementsPageCount(state),
    isLoading: selectors.selectIsLoading(state),
    page: selectors.selectFilterByField(state)('pageStatements')
  });

const mapDispatchToProps = (dispatch: any): IExtendedStatementActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedStatement);
