import React, { useEffect } from 'react';

import {
  ButtonPrint,
  ButtonSave,
  Dropdown,
  DatePickerRange,
  Button,
  LinkWithContent,
} from '@rfb/ui-kit';

import { dates } from '../../configs/defaultAccount';

import Balance from './components/Balance';
import Dates from './components/Dates';
import Restrictions from './components/Restrictions';
import Summary from './components/Summary';
import { IAccountsState } from '../../store';
import routingConfig from '../../../../configs/routing';
import { convertPathUsingParams } from '@rfb/common';

import styles from './assets/styles/index.module.css';

export interface IAccountsSelectProps {
  optionList: { label: string; value: string }[];
  account: string;
  accounts: IAccountsState['accounts'];
  filter: IAccountsState['filter'];
  isLoading: boolean;
}

export interface IAccountsSelectActions {
  actions: {
    getAccounts: Function;
    getAccountArrests: Function;
    getAccount: Function;
    setFilter: Function;
    setCurrentAccount: Function;
    exportTransactionsData: Function;
  };
}

const AccountsFilter = (props: IAccountsSelectProps & IAccountsSelectActions) => {
  useEffect(() => {
    props.actions.getAccounts();
    // ESLINT Необходимо выполнение только при монтировании компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.filter.account) {
      props.actions.setCurrentAccount(props.filter.account);
      props.actions.getAccount(props.filter);
    }
  }, [props.filter, props.actions]);

  useEffect(() => {
    if (props.filter.account) {
      props.actions.getAccountArrests(props.filter);
      props.actions.setFilter(dates);
    }
    // ESLINT Нужен только при изменяющемся р/с
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter.account]);

  useEffect(() => {
    // Первый расчётный счет - должен быть открытым, если уже не установлен
    if (!props.account) {
      const openAccounts = props.accounts.find((acc) => !acc.close_date);
      const firstAccount = openAccounts ? openAccounts.account : null;
      props.actions.setFilter({ account: firstAccount });
    }
    // ESLINT Нужен только при изменяющемся списке р/с
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accounts]);

  const onDataSave = (): void => {
    props.actions.exportTransactionsData(props.filter);
  };
  const onAccountChange = (account: string): void => {
    if (account !== props.filter.account) {
      props.actions.setFilter({ account });
    }
  };
  const onDatesChange = (newDates: string[]): void =>
    props.actions.setFilter({ start: newDates[0], end: newDates[1] });

  const extendedStatementUrl: string = props.account
    ? convertPathUsingParams(routingConfig.extendedStatement.path, { id: props.account })
    : '';

  const startDate: string = (props.filter.start || dates.start).toString();
  const endDate: string = (props.filter.end || dates.end).toString();

  return (
    <div>
      <div className={styles.filterAccount}>
        <Dropdown
          wrapperClassName={styles.dropDown}
          label="Счёт"
          options={props.optionList}
          value={props.account}
          onChange={onAccountChange}
        />
        <ButtonSave wrapperClassName={styles.button} onSave={onDataSave} />
        <ButtonPrint wrapperClassName={styles.button} />
        <Balance />
      </div>

      <Dates />
      <Restrictions />
      <div className={styles.chooseBlock}>
        <DatePickerRange
          wrapperClassName={styles.datePickerRange}
          start={startDate}
          end={endDate}
          withShift
          onChange={(value: string[]) => {
            if (!!value[0] && !!value[1]) {
              // К сожалению, пока костыль, поскольку по непонятным причинам onChange срабатывает, когда его не просят - просто при рендеринге
              onDatesChange(value);
            }
          }}
        />
        {props.account ? (
          <LinkWithContent wrapperClassName={styles.extendedButton} to={extendedStatementUrl}>
            <Button text="Расширенная выписка" />
          </LinkWithContent>
        ) : (
          <Button isDisabled text="Расширенная выписка" />
        )}
      </div>
      <Summary />
    </div>
  );
};

export default AccountsFilter;
