import React from 'react';
import cn from 'classnames';
import fp from 'lodash/fp';
import styles from './assets/styles/index.module.css';
import { Condition } from '../..';
export var MessageList = function (props) {
    var _a;
    var isInfoType = fp.isEqual(props.type, 'info');
    var isErrorType = fp.isEqual(props.type, 'error');
    var messageClassNames = cn((_a = {},
        _a[styles.message] = true,
        _a[styles.messageInfo] = isInfoType,
        _a[styles.messageError] = isErrorType,
        _a));
    //TODO: Сделать вывод ссылки разумнум способом может быть массив строк messages заменить на массив объектов
    var renderMessage = function (message) {
        var _a;
        return (React.createElement("div", { key: message, className: messageClassNames, role: "alert" },
            message.replace(/<a href=.*\/a>/g, ''),
            React.createElement(Condition, { value: /<a href=".*">/.test(message) },
                React.createElement("a", { className: messageClassNames, href: ((_a = /<a href=".*"/g.exec(message)) !== null && _a !== void 0 ? _a : [' '])[0].substr(9).replaceAll('"', '') }, message.replace(/.*?\<a href=".*">/g, '').replace(/<\/a>.*$/g, '')))));
    };
    return (React.createElement("div", { className: cn(props.wrapperClassName) },
        React.createElement("div", { className: styles.main }, fp.map(renderMessage, props.messages))));
};
MessageList.defaultProps = {
    type: 'info',
};
export default MessageList;
