import { createSelector } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { name } from './index';

export const selectState = fp.path(name);

export default {
  selectDataFilter: createSelector(selectState, fp.path('dataFilter')),
  selectFilter: createSelector(selectState, fp.path('filter')),
  selectFilterTemp: createSelector(selectState, fp.path('filterTemp')),
  selectIsFilteringByPeriod: createSelector(selectState, fp.path('isFilteringByPeriod')),
  selectOperationData: createSelector(selectState, fp.path('data')),
  selectRequestCount: createSelector(selectState, fp.path('requestCount')),
  selectOperationDataView: createSelector(selectState, fp.path('operationDataView')),
  selectRequestAttachment: createSelector(selectState, fp.path('requestAttachment')),

  selectPageCount: createSelector(selectState, fp.path('pageCount')),
  selectIsLoading: createSelector(selectState, fp.path('isLoading')),
  selectErrorListByField: createSelector(
    selectState,
    (state) => (field: string) => fp.pathOr([], ['errors', field], state)
  ),
  selectCurrentOperationDataRecord: createSelector(
    selectState,
    fp.path('currentOperationDataRecord')
  ),
  selectCurrentRepoOperationData: createSelector(selectState, fp.path('currentRepoOperationData')),
};
