import React from 'react';
import AppTemplate from 'templates/AppTemplate';

const Help = () => {
  const renderContent = () => (
    <a
      href="https://api.rosbank-auto.ru/doc/pravila-raboty-v-lichnom-kabinete-ur.pdf"
      target="_blank"
    >
      Правила работы в личном кабинете
    </a>
  );

  return <AppTemplate content={renderContent()} />;
};

export default Help;
