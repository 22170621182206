import React from 'react';

import AppTemplate from 'templates/AppTemplate';

import UserProfile from 'modules/user/components/Profile';

import styles from './assets/styles/index.module.css';

const Main = () => {
  const renderContent = () => (
    <div className={styles.main}>
      <UserProfile />
    </div>
  );

  return <AppTemplate content={renderContent()} />;
};

export default Main;
