import React, { useEffect } from 'react';
import fp from 'lodash/fp';
import { getTransformedApiDate } from '@rfb/common';
import {
  Button,
  ButtonReset,
  ButtonSave,
  Condition,
  DatePickerRange,
  Dropdown,
  LinkWithContent,
  Switch,
} from '@rfb/ui-kit';
import routingConfig from 'configs/routing';
import { ITrancheState } from '../../store';
import styles from './assets/styles/index.module.css';

export interface ITrancheFilterProps {
  filter: ITrancheState['filter'];
  filterTemp: ITrancheState['filterTemp'];
  clientLineStatusOptions: { value: string; label: string }[];
  clientLineName?: string;
  clientLine: object;
  statusOptions: { value: string; label: string }[];
  paymentListCount: number;
  amount: string;
  showSelected: boolean;
  isReadOnly: boolean;
}

export interface ITrancheFilterPropsExternal {
  clientLineId?: string;
  clientLineType?: string;
}

export interface ITrancheFilterActions {
  actions: {
    set: Function;
    setFilter: Function;
    resetFilterByOmitList: Function;
    setFilterTemp: Function;
    applyTempFilter: Function;
    toggleExtraTableData: Function;
    exportData: Function;
  };
  clientLineActions: {
    set: Function;
    getDataFullById: Function;
  };
}

export const selectedByPeriodValue: string = 'selectedByPeriod';

const Filter = (
  props: ITrancheFilterProps & ITrancheFilterPropsExternal & ITrancheFilterActions
) => {
  useEffect(() => {
    if (props.clientLineId && fp.isEmpty(props.clientLine)) {
      props.clientLineActions.set({ currentId: props.clientLineId });
      props.clientLineActions.getDataFullById({
        number: props.clientLineId,
        type: props.clientLineType,
      });
    }
    // ESLINT Необходимо для отправки запроса только в момент монтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkSelectedByPeriodValue = (value: string): boolean =>
    fp.isEqual(value, selectedByPeriodValue);

  const onDataSave = () => {
    props.actions.exportData({
      ...props.filter,
      clientLineId: fp.defaultTo('', props.clientLineId),
      type: props.clientLineId ? props.clientLineType : props.filter.type,
    });
  };
  const onClientLineStatusChange = (clientLineStatus: string) => {
    const isSelectedByPeriodValue = checkSelectedByPeriodValue(clientLineStatus);
    props.actions.setFilterTemp({
      clientLineStatus,
      dateStart: isSelectedByPeriodValue
        ? props.filterTemp.dateStart ||
          getTransformedApiDate(new Date(Date.now() - 1000 * 3600 * 24 * 7).toISOString())
        : '',
      dateEnd: isSelectedByPeriodValue
        ? props.filterTemp.dateEnd || getTransformedApiDate(new Date().toISOString())
        : '',
    });
  };
  const onDateInputChange = ([dateStart, dateEnd]: string[]) => {
    if (!dateStart && dateEnd) {
      const timeWeekAgo = new Date(dateEnd).getTime() - 1000 * 3600 * 24 * 7;
      const newDateStart = new Date(timeWeekAgo);
      dateStart = getTransformedApiDate(newDateStart.toISOString());
    }
    props.actions.setFilterTemp({ dateStart, dateEnd });
    // dateStart && props.actions.setFilterTemp({ dateStart, dateEnd: props.filterTemp.dateEnd });
    // dateEnd && props.actions.setFilterTemp({ dateStart: props.filterTemp.dateStart, dateEnd });
    //dateStart && props.actions.setFilterTemp({ dateStart });
  };

  const onApplyTempFilterClick = () => {
    props.actions.applyTempFilter();
  };
  const onSwitchChange = () => props.actions.toggleExtraTableData();
  const onShowClick = () => {
    props.actions.set({ showSelected: true });
    props.actions.setFilter({ page: 1 });
  };
  const onReturnClick = () => props.actions.set({ showSelected: false });
  const onFilterClear = () => {
    const omitList: (keyof ITrancheState['filter'])[] = [
      'clientLineStatus',
      'dateStart',
      'dateEnd',
    ];
    props.actions.resetFilterByOmitList(omitList);
  };
  return (
    <div className={styles.main}>
      <div className={styles.blockA}>
        <h1 className={styles.title}>
          {props.clientLineId
            ? `Транши по договору ${props.clientLineName}`
            : 'Информация по траншам'}
        </h1>
        <ButtonSave wrapperClassName={styles.save} onSave={onDataSave} />
      </div>

      <div className={styles.blockB}>
        <Condition value={fp.isEmpty(props.clientLineId)}>
          <Dropdown
            wrapperClassName={styles.dropdown}
            label="Показать транши"
            value={props.filterTemp.clientLineStatus}
            options={props.clientLineStatusOptions}
            onChange={onClientLineStatusChange}
          />
          <Condition value={checkSelectedByPeriodValue(props.filterTemp.clientLineStatus)}>
            <DatePickerRange
              wrapperClassName={styles.datePickerRange}
              start={props.filterTemp.dateStart}
              end={props.filterTemp.dateEnd}
              withShift
              onChange={onDateInputChange}
            />
          </Condition>
        </Condition>
        <Condition value={fp.isEmpty(props.clientLineId)}>
          <Button
            wrapperClassName={styles.buttonShow}
            text="Показать"
            onClick={onApplyTempFilterClick}
          />
        </Condition>
      </div>

      <div className={styles.blockC}>
        <Switch
          prefix="Основные данные"
          postfix="Дополнительные данные"
          isChecked={props.filter.isExtraTableData}
          onChange={onSwitchChange}
        />

        {!props.isReadOnly && (
          <LinkWithContent wrapperClassName={styles.create} to={routingConfig.repayment.path}>
            <Button
              text="Сформировать заявку на погашение"
              isDisabled={fp.isEqual(props.paymentListCount, 0)}
            />
          </LinkWithContent>
        )}
      </div>

      <div className={styles.blockD}>
        <div className={styles.trancheCount}>
          <span className={styles.trancheCountTitle}>Количество выбранных траншей:</span>
          <span className={styles.trancheCountValue}>{props.paymentListCount}</span>
          {props.showSelected ? (
            <span className={styles.show} onClick={onReturnClick}>
              Вернуться
            </span>
          ) : (
            <span className={styles.show} onClick={onShowClick}>
              Показать
            </span>
          )}
        </div>
        <div className={styles.priceTotal}>
          <span className={styles.priceTotalTitle}>Итоговая сумма:</span>
          <span className={styles.priceTotalValue}>{props.amount}</span>
        </div>
        <ButtonReset
          wrapperClassName={styles.reset}
          title="СБРОСИТЬ ФИЛЬТРЫ"
          isActive={props.filter.isFilterActive}
          onClear={onFilterClear}
        />
      </div>
    </div>
  );
};

export default Filter;
