import { rest } from 'msw';
import { clientLine } from './clientLine';
import { rate } from './rate';

export const rfinfoClientLineHandlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/rf-info/client-line`, (req, res, ctx) => {
    console.log('msw-handlers: GET /rf-info/client-line');
    return res(ctx.status(200), ctx.json(clientLine));
  }),
  rest.get(`${process.env.REACT_APP_API_URL}/rf-info/rate`, (req, res, ctx) => {
    console.log('msw-handlers: GET /rf-info/rate');
    return res(ctx.status(200), ctx.json(rate));
  }),
];
