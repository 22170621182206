import { DealerSystems, convertPathUsingParams } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import { ButtonPrint, Condition, Html, Loader, MessageList } from '@rfb/ui-kit';
import fp from 'lodash/fp';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import apiEndpoints from '../../../../configs/api/endpoints';
import { IAttachmentDtoPart, InOutDirection } from '../../configs/type';
import { actions } from '../../store';
import Clip from './assets/images/clip';
import styles from './assets/styles/index.module.css';

export interface INotificationViewProps {
  notification_date: string;
  sender: string;
  subject: string;
  message: string;
  attachments: IAttachmentDtoPart[];
  isReadOnly: boolean;
  inOutDirection: InOutDirection;
  isLoading: boolean;
  errors: string[];
}

export interface INotificationViewPropsExternal {
  id?: number;
  notificationDealerSystem: DealerSystems;
}

export interface INotificationViewActions {
  actions: {
    getNotificationById: Function;
    setNotificationRead: Function;
    resetNotificationCounters: Function;
    docListFileDownload: Function;
  };
}

const NotificationView = (
  props: INotificationViewProps & INotificationViewPropsExternal & INotificationViewActions
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    props.actions.getNotificationById({ id: props.id });
    if (!props.isReadOnly && props.inOutDirection === InOutDirection.IN) {
      props.actions.setNotificationRead({ id: props.id });
      props.actions.resetNotificationCounters();
    }
  }, [props.id, props.actions]);

  const onHtmlClick = (e: TODO_ANY): void => {
    e.preventDefault();
    const isAnchorTag = e.target.tagName === 'A';
    if (isAnchorTag) {
      const strTarget = e.target.toString();
      const isRf: boolean = isAnchorTag && strTarget.includes('/rf');
      dispatch(actions.docListFileDownload({ id: isRf ? 'rf' : 'rb' }));
    }
  };

  if (props.isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <h1 className={styles.title}>
        {props.notificationDealerSystem === DealerSystems.ROSBANK_AVTO
          ? 'РОСБАНК АВТО'
          : 'РУСФИНАНС'}
      </h1>
      <Condition value={!fp.isEmpty(props.errors)}>
        <MessageList type="error" messages={props.errors} />
      </Condition>
      <div className={styles.text}>
        <div
          style={{
            fontSize: '21px',
            color: 'var(--secondary)',
            position: 'relative',
            display: 'flex',
          }}
        >
          {props.subject}
          <ButtonPrint wrapperClassName={styles.print} />
        </div>
        <div
          style={{ marginTop: '15px', fontWeight: '500', fontSize: '10px', color: 'var(--link)' }}
        >
          {props.notification_date}
        </div>
        <div
          style={{ marginTop: '12px', fontWeight: '300', fontSize: '13px', color: 'var(--link)' }}
        >
          {props.attachments?.map((attachment) => (
            <div style={{ marginBottom: '9px' }}>
              <Clip />
              <a
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  color: 'gray',
                }}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  return dispatch(
                       actions.documentumFileDownload({
                        id: attachment.attachment_file_id,
                        filename: attachment.attachment_file_name,
                      })
                  );
                }}
                href={
                  process.env.REACT_APP_ADMIN_API_URL +
                  convertPathUsingParams(apiEndpoints.notifications.documentumFileDownload, {
                    id: attachment.attachment_file_id,
                  })
                } rel="noreferrer"
              >
                {attachment.attachment_file_name}
              </a>
              <br />
            </div>
          ))}
        </div>
        <div style={{ marginTop: '30px' }} onClick={onHtmlClick}>
          <Html text={props.message} />
        </div>
      </div>
    </div>
  );
};

export default NotificationView;
