import React from 'react';

import { AppTemplate as UIKitAppTemplate } from '@rfb/ui-kit';

import AppMenu from 'modules/app/components/Menu';
import UserWidget from 'modules/user/components/Widget';

interface IAppTemplate {
  content: React.ReactElement | React.ReactElement[];
  sidebarLeft?: React.ReactElement | React.ReactElement[];
  sidebarRight?: React.ReactElement | React.ReactElement[];
}

const AppTemplate = (props: IAppTemplate) => {
  const renderHeader = () => (
    <>
      <AppMenu />
      <UserWidget />
    </>
  );

  return (
    <UIKitAppTemplate
      header={renderHeader()}
      content={props.content}
      sidebarLeft={props.sidebarLeft}
      sidebarRight={props.sidebarRight}
    />
  );
};

export default AppTemplate;
