export enum TrancheTypes {
  KL = 'KL',
  FL1 = 'FL1',
  FL2 = 'FL2',
  CC = 'CC',
}

export const trancheTypeTranslations: { [key: string]: string } = {
  [TrancheTypes.KL]: 'К/Л',
  [TrancheTypes.FL1]: 'Ф/Л',
  [TrancheTypes.FL2]: 'Ф/Л',
  [TrancheTypes.CC]: 'Б/Н-К/К'
}
