import React, { SVGProps } from "react";

export const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.09097 0L12 10.9091L10.9091 12L7.18178e-05 1.09091L1.09097 0Z" fill="currentColor" />
    <path
      d="M0 10.9091L10.909 1.83914e-07L11.9999 1.09091L1.0909 12L0 10.9091Z"
      fill="currentColor"
    />
  </svg>
);
