import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import fp from 'lodash/fp';
import CalendarPopup from '../CalendarPopup';
import DateInput from './components/DateInput';
import styles from './assets/styles/index.module.css';
// TODO устранить дублирование, объединив с DatePicker
export var DatePickerLargeTextSize = function (props) {
    var _a = useState(fp.isEmpty(props.value) ? undefined : props.value), valueDate = _a[0], setValueDate = _a[1];
    useEffect(function () {
        if (valueDate !== props.value) {
            setValueDate(fp.isEmpty(props.value) ? undefined : props.value);
        }
        // ESLINT ???
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);
    var onCalendarChange = function (value) {
        var _val = (typeof value === 'object' ? fp.head(value) : value);
        setValueDate(_val);
        props.onChange(_val);
    };
    var onDateChange = function (value) {
        if (fp.isEqual(valueDate, value))
            return;
        var _val = (typeof value === 'object' ? fp.head(value) : value);
        setValueDate(_val);
        props.onChange(_val);
    };
    var classNames = cn(props.wrapperClassName, {});
    return (React.createElement("div", { className: classNames },
        React.createElement("div", { className: styles.main },
            React.createElement(CalendarPopup, { wrapperClassName: styles.calendar, value: valueDate, type: "single", onCalendarChange: onCalendarChange }),
            React.createElement(DateInput, { value: valueDate || '_', onChange: onDateChange, keyUnique: props.keyUnique }))));
};
export default DatePickerLargeTextSize;
