import React, { useEffect } from 'react';

import fp from 'lodash/fp';

import {Table, ButtonSave} from '@rfb/ui-kit';

import { getTransformedDate, getTransformedAmount } from '@rfb/common';
import { toDate } from '@rfb/common/lib/utils/format/date';

import { IDTORFInfoAccountExtendedStatementResponse } from 'dto/rf-info';

import styles from './assets/styles/index.module.css';

export interface IExtendedStatementPropsExternal {
  id: string;
}

export interface IExtendedStatementProps {
  statementData: IDTORFInfoAccountExtendedStatementResponse;
  dateStart: string;
  dateEnd: string;
  page: number;
  pageCount: number;
  isLoading: boolean;
}

export interface IExtendedStatementActions {
  actions: {
    getAccountExtendedStatement: Function,
    exportAccountExtendedStatement: Function,
    setFilter: Function,
  };
}

const ExtendedStatement = (
  props: IExtendedStatementPropsExternal & IExtendedStatementProps & IExtendedStatementActions
) => {
  const { statements, ...summary } = props.statementData;
  const onDataSave = (): void => {
    props.actions.exportAccountExtendedStatement({
      account: props.id,
      dateStart: props.dateStart,
      dateEnd: props.dateEnd,
    });
  };

  useEffect(() => {
    props.id &&
      props.actions.getAccountExtendedStatement({
        account: props.id,
        dateStart: props.dateStart,
        dateEnd: props.dateEnd,
        page: props.page,
      });
  }, [props.id, props.actions, props.dateStart, props.dateEnd, props.page]);

  const tableHeaders = [
    { title: 'Дата проводки', value: 'doc_date' },
    { title: 'Номер документа', value: 'doc_number' },
    { title: 'в/о', value: 'vo' },
    { title: 'Бик банка контрагента', value: 'bic_contractor' },
    { title: 'Счет контрагента', value: 'account_contractor' },
    { title: 'Сумма по дебету', value: 'qty_debt' },
    { title: 'Сумма по кредиту', value: 'qty_cred' },
    { title: 'Контрагент', value: 'contractor' },
    { title: 'ИНН контрагента', value: 'inn_contractor' },
    { title: 'Назначение платежа', value: 'comment' },
  ];

  const tableRows = statements.map((item) => {
    let row = {
      data: [
        toDate(item['doc_date']),
        item['doc_number'],
        item['vo'],
        item['bic_contractor'],
        item['account_contractor'],
        getTransformedAmount(fp.toNumber(item['qty_debt'])),
        getTransformedAmount(fp.toNumber(item['qty_cred'])),
        item['contractor'],
        item['inn_contractor'],
        item['comment'],
      ],
    };

    return row;
  });

  return (
    <div className={styles.extendedStatement}>
      <div className={styles.titleInfo}>
        <h2 className={styles.title}>Расширенная выписка</h2>
        <ButtonSave wrapperClassName={styles.button} onSave={onDataSave} />
      </div>
      <div className={styles.headInfo}>
        <div>Филиал РОСБАНК Авто ПАО РОСБАНК</div>
        <div>
          {`выписка по лицевому счету с ${getTransformedDate(
            props.dateStart
          )} по ${getTransformedDate(props.dateEnd)}`}
        </div>
      </div>
      <div className={styles.personalAccountInfo}>{`Лицевой счет: ${props.id}`}</div>

      <Table
        headers={tableHeaders}
        rows={tableRows}
        page={props.page}
        pageCount={props.pageCount}
        isLoading={props.isLoading}
        onSortingChange={() => false}
        sorting={{ value: '', direction: 'ASC' }}
        onPageChange={(page: number) => props.actions.setFilter({ pageStatements: page })}
      />

      <div className={styles.summaryInfo}>
        <div>
          <span className={styles.label}>Входящее сальдо: </span>
          <span>{getTransformedAmount(fp.toNumber(summary.rest_in))}</span>
        </div>
        <div>
          <span className={styles.label}>Оборот по дебету: </span>
          <span>{getTransformedAmount(fp.toNumber(summary.turn_debt))}</span>
        </div>
        <div>
          <span className={styles.label}>Оборот по кредиту: </span>
          <span>{getTransformedAmount(fp.toNumber(summary.turn_cred))}</span>
        </div>
        <div className={styles.emptyString}></div>
        <div>
          <span className={styles.label}>Количество документов: </span>
          <span>{summary.doc_quantity}</span>
        </div>
        <div>
          <span className={styles.label}>Исходящее сальдо: </span>
          <span>{getTransformedAmount(fp.toNumber(summary.rest_out))}</span>
        </div>
      </div>
    </div>
  );
};

export default ExtendedStatement;
