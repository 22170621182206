import { bindActionCreators } from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import { connect } from 'react-redux';

import { ITable } from '@rfb/ui-kit';
import { IDTORFInfoTranchesResponse } from 'dto/rf-info';
import { TranchePayment } from '../../configs/payment';
import { TrancheStatus, trancheStatusValue } from '../../configs/status';

import { getTransformedAmount } from 'helpers/transform/amount';
import { getTransformedDate } from 'helpers/transform/date';
import {
  getStatusId,
  getTransformedNumberForView,
  getTransformedStatus,
} from '../../helpers/transform';

import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import { store } from 'root-store';
import storage from '../../../../utils/storage';
import { readOnlyRoleKey } from '../../../app/store/sagas';
import { dealerSystemsKey } from '../../../auth/store';
import { ClientLineTypes } from '../../../client-line/configs/type';
import { fullPaymentFieldList } from '../../helpers/amount';
import { ITrancheState, actions, name, selectors } from '../../store';
import styles from './assets/styles/index.module.css';
import Component, {
  ITrancheTableActions,
  ITrancheTableProps,
  ITrancheTablePropsExternal,
} from './component';

type TTrancheTableHeader = ITable['headers'][number] & {
  value: keyof IDTORFInfoTranchesResponse['tranches'][number] | 'full_repayment' | 'prepayment';
  isExtra?: true;
};

type TTrancheTableColumn = {
  header: TTrancheTableHeader;
  transform?: (value: any, row?: ITrancheState['data'][number]) => any;
};

const mapStateToProps = (
  state: { [name]: ITrancheState },
  props: ITrancheTablePropsExternal
): ITrancheTableProps & ITrancheTablePropsExternal => {
  const dataFilter: ITrancheState['dataFilter'] = selectors.selectDataFilter(state);
  const filter: ITrancheState['filter'] = selectors.selectFilter(state);
  const showSelected: boolean = selectors.selectShowSelected(state);
  const tranches: ITrancheState['data'] = selectors.selectTrancheList(state);
  const paymentList: ITrancheState['paymentList'] = selectors.selectPaymentList();
  const data: ITrancheState['data'] | ITrancheState['paymentList'] = showSelected
    ? fp.slice((filter.page - 1) * filter.perPage, filter.page * filter.perPage, paymentList)
    : tranches;
  const isReadOnly: boolean = storage.get(readOnlyRoleKey)

  const columns: TTrancheTableColumn[] = [
    {
      header: {
        value: 'full_repayment',
        title: 'Полное погашение',
        isFixed: true,
        isDisabled: isReadOnly,
        columnClassName: styles.columnCheckbox,
      },
    },
    {
      header: {
        value: 'prepayment',
        title: 'Пред оплата',
        isFixed: true,
        columnClassName: styles.columnCheckbox,
      },
    },
    {
      header: {
        value: 'vin',
        title: 'VIN',
        type: 'input',
        inputTooltipText: 'Введите любые три символа',
        filterValue: filter.vin,
        isFixed: true,
        isDisabled: showSelected,
        columnClassName: styles.columnData,
        columnKey: filter.lastResetTimestamp,
        onFilterChange: (vin) =>
          fp.some(Boolean, [fp.gte(fp.size(vin), 3), fp.isEmpty(vin)])
            ? store.dispatch(actions.setFilter({ vin }))
            : fp.noop,
      },
    },
    {
      header: {
        value: 'name_brand',
        title: 'Бренд',
        type: 'select',
        filterList: dataFilter.brands,
        filterValue: filter.brand,
        isDisabled: showSelected,
        columnClassName: styles.columnData,
        onFilterChange: (brand) => store.dispatch(actions.setFilter({ brand })),
      },
    },
    {
      header: {
        value: 'model',
        title: 'Модель',
        type: 'select',
        filterList: dataFilter.models,
        filterValue: filter.model,
        isDisabled: showSelected,
        columnClassName: styles.columnData,
        onFilterChange: (model) => store.dispatch(actions.setFilter({ model })),
      },
    },
    {
      header: {
        value: 'number',
        title: 'Договор',
        type: 'select',
        filterList: dataFilter.contracts,
        filterValue: filter.number,
        isDisabled: showSelected,
        columnClassName: styles.columnData,
        onFilterChange: (number) => store.dispatch(actions.setFilter({ number })),
      },
      transform: fp.pipe(
        fp.nthArg(1),
        fp.paths(['number', 'type']),
        fp.spread(getTransformedNumberForView)
      ),
    },
    {
      header: {
        value: 'status',
        title: 'Статус',
        type: 'select',
        filterList: fp.map((status) => getTransformedStatus(status), dataFilter.statuses),
        filterValue: trancheStatusValue[filter.status],
        isDisabled: showSelected,
        columnClassName: styles.columnData,
        onFilterChange: (statusValue: TODO_ANY) =>
          store.dispatch(actions.setFilter({ status: getStatusId(statusValue) })),
      },
      transform: getTransformedStatus,
    },
    {
      header: {
        value: 'real_start_date',
        title: 'Дата финансирования',
        type: 'sorting',
        sortingType: 'number',
        isDisabled: showSelected,
        columnClassName: styles.columnData,
      },
      transform: (date: string | null) => (date ? getTransformedDate(date) : '-'),
    },
    {
      header: {
        value: 'sum_amount',
        title: 'Сумма финансирования',
        type: 'sorting',
        sortingType: 'number',
        isDisabled: showSelected,
        columnClassName: styles.columnData,
      },
      transform: getTransformedAmount,
    },
    {
      header: {
        value: 'sum_pre_pay',
        title: 'Сумма предоплаты',
        type: 'sorting',
        sortingType: 'number',
        isDisabled: showSelected,
        columnClassName: styles.columnData,
      },
      transform: getTransformedAmount,
    },
    {
      header: {
        value: 'date_beg_pay',
        title: 'Дата начала платного периода',
        type: 'sorting',
        sortingType: 'number',
        isDisabled: showSelected,
        isExtra: true,
        columnClassName: styles.columnData,
      },
      transform: (date: string | null) => (date ? getTransformedDate(date) : '-'),
    },
    {
      header: {
        value: 'finish_date',
        title: 'Срок погашения',
        type: 'sorting',
        sortingType: 'number',
        isDisabled: showSelected,
        isExtra: true,
        columnClassName: styles.columnData,
      },
      transform: (date: string | null) => (date ? getTransformedDate(date) : '-'),
    },
    {
      header: {
        value: 'rest_main_debt',
        title: 'Остаток основного долга',
        type: 'sorting',
        sortingType: 'number',
        isDisabled: showSelected,
        isExtra: true,
        columnClassName: styles.columnData,
      },
      transform: getTransformedAmount,
    },
    {
      header: {
        value: 'overdue_debt',
        title: 'Просроченный долг',
        type: 'sorting',
        sortingType: 'number',
        isDisabled: showSelected,
        isExtra: true,
        columnClassName: styles.columnData,
      },
      transform: getTransformedAmount,
    },
    {
      header: {
        value: 'percent_rate',
        title: '% ставка',
        type: 'sorting',
        sortingType: 'number',
        isDisabled: showSelected,
        isExtra: true,
        columnClassName: styles.columnData,
      },
      transform: (value) => fp.toNumber(value).toFixed(2) + '%',
    },
    {
      header: {
        value: 'sum_percent',
        title: 'Проценты',
        type: 'sorting',
        sortingType: 'number',
        isDisabled: showSelected,
        isExtra: true,
        columnClassName: styles.columnData,
      },
      transform: getTransformedAmount,
    },
    {
      header: {
        value: 'sum_fin',
        title: 'Штрафы',
        type: 'sorting',
        sortingType: 'number',
        isDisabled: showSelected,
        isExtra: true,
        columnClassName: styles.columnData,
      },
      transform: getTransformedAmount,
    },
    {
      header: {
        value: 'sum_comiss',
        title: 'Комиссия',
        type: 'sorting',
        sortingType: 'number',
        isDisabled: showSelected,
        isExtra: true,
        columnClassName: styles.columnData,
      },
      transform: getTransformedAmount,
    },
  ];

  const currentViewColumns = fp.filter(
    fp.pipe(fp.path('header.isExtra'), filter.isExtraTableData ? fp.always(true) : fp.isNil),
    columns
  );
  const checkboxList = fp.map((row: ITrancheState['data'][number]) => {
    const full = { ...row, payment_type: TranchePayment.FULL };
    const pre = { ...row, payment_type: TranchePayment.PRE };
    const conditionCheckedFull: boolean[] = [
      fp.isEqual(row.payment_type, TranchePayment.FULL),
      fp.some(full, paymentList),
    ];
    const conditionCheckedPre: boolean[] = [
      fp.isEqual(row.payment_type, TranchePayment.PRE),
      fp.some(pre, paymentList),
    ];

    const isDisabled: boolean = isReadOnly || fp.some(Boolean, [
      fp.isEqual(row.status, fp.toNumber(TrancheStatus.CLOSED)),
      fp.isEqual(row.type, ClientLineTypes.CC),
      fp.pipe(fp.paths(fullPaymentFieldList), fp.every(fp.isEqual(0)))(row),
    ]);

    const isEmptyPrePaymentSum: boolean = !pre.sum_pre_pay;
    return [
      {
        isCheckbox: true,
        isChecked: fp.some(Boolean, conditionCheckedFull),
        isDisabled: isDisabled || !row.full_repayment_active,
        //isDisabled: false, Включать для отладки
        onChange: () => store.dispatch(actions.togglePaymentList(full)),
      },
      {
        isCheckbox: true,
        isChecked: fp.some(Boolean, conditionCheckedPre),
        isDisabled: fp.any(Boolean, [isDisabled, isEmptyPrePaymentSum, !row.prepayment_active]),
        //isDisabled: false, Включать для отладки
        onChange: () => store.dispatch(actions.togglePaymentList(pre)),
      },
    ];
  }, data);

  const rowDataList = fp.pipe(
    fp.map((row: ITrancheState['data'][number]) =>
      fp.map(
        (column: TTrancheTableColumn) =>
          fp.isFunction(column.transform)
            ? column.transform(fp.path(column.header.value, row), row)
            : fp.path(column.header.value, row),
        currentViewColumns
      )
    ),
    fp.zip(checkboxList),
    fp.map(([checkboxList, row]: [any, any]) => [...checkboxList, ...fp.drop(2, row)]),
    fp.map((data) => ({ data }))
  )(data);
  const rowConfigList = fp.pipe(
    fp.map((row: ITrancheState['data'][number]) => ({
      isDisabled: fp.every(Boolean, [
        fp.isString(row.payment_type),
        !fp.find({ vin: row.vin }, paymentList),
      ]),
      //isDisabled: false, Включать для отладки
      isMarked: row.flag_overdue_debt,
    })),
    fp.map((config) => ({ config }))
  )(data);

  const result = {
    filter,
    headers: fp.map(fp.path('header'), currentViewColumns),
    rows: fp.pipe(fp.zip(rowConfigList), fp.map(fp.mergeAll))(rowDataList),
    page: filter.page,
    pageCount: showSelected
      ? fp.ceil(selectors.selectPaymentListCount(state) / filter.perPage)
      : selectors.selectPageCount(state),
    rfInfoErrorList: selectors.selectErrorListByField(state)('rfInfo'),
    isLoading: selectors.selectIsLoading(state),
    clientLineId: props.clientLineId,
    clientLineType: props.clientLineType,
    dealerSystems: storage.get(dealerSystemsKey),
  };

  return result;
};

const mapDispatchToProps = (dispatch: any): ITrancheTableActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
