import React, { useEffect } from 'react';
import { Html } from '@rfb/ui-kit';
import routingConfig from '../../../../configs/routing';
import SystemChoice from '../common/SystemChoice';
import storage from '../../../../utils/storage';
import { dealerSystemsKey } from '../../../auth/store';

export interface IInformationReminderProps {
  rb_text: string;
  rf_text: string;
}

export interface IInformationReminderPropsExternal {
  history: {
    push: (path: string) => void;
  };
}

export interface IInformationReminderActions {
  actions: {
    getReminderText: Function;
  };
}

const Reminder = (
  props: IInformationReminderProps & IInformationReminderPropsExternal & IInformationReminderActions
) => {
  useEffect(() => {
    props.actions.getReminderText();
  }, [props.actions]);

  return (
    <div>
      <SystemChoice
        dealerSystemConfig={storage.get(dealerSystemsKey).data}
        twoSystemCaption="Памятка по взаимодействию"
        oneSystemCaptionTemplate="Памятка по взаимодействию c"
        rbRenderContent={<Html text={props.rb_text ?? ''} />}
        rfRenderContent={<Html text={props.rf_text ?? ''} />}
        rbButtonName="Памятка по взаимодействию с РОСБАНК АВТО"
        rfButtonName="Памятка по взаимодействию с РУСФИНАНС"
        oneSystemPath={routingConfig.reminder.path}
        twoSystemPath={routingConfig.twoSystemReminder.path}
      />
    </div>
  );
};

export default Reminder;
