import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import fp from 'lodash/fp';
import { pathToRegexp } from 'path-to-regexp';
import ArrowClosedIcon from '../../icons/ArrowClosed';
import ArrowOpenedIcon from '../../icons/ArrowOpened';
import styles from './assets/styles/index.module.css';
import { NotificationItem, MailNotificationItem } from './MailMenu';
export var MenuItemType;
(function (MenuItemType) {
    MenuItemType[MenuItemType["DEFAULT"] = 0] = "DEFAULT";
    MenuItemType[MenuItemType["NOTIFICATION"] = 1] = "NOTIFICATION";
})(MenuItemType || (MenuItemType = {}));
var emptyActiveSubmenuIndex = -1;
export var MenuHorizontal = function (props) {
    var _a = useState(emptyActiveSubmenuIndex), activeSubMenuIndex = _a[0], setActiveSubMenuIndex = _a[1];
    var isActiveSubMenu = function (index) { return fp.equals(index, activeSubMenuIndex); };
    var hasActiveLinks = function (item) {
        var pathname = window.location.pathname;
        return fp.pipe(fp.map(fp.path('link')), fp.map(pathToRegexp), fp.any(function (regexp) { return regexp.test(pathname); }))(item.items);
    };
    var onSubMenuHover = function (index) { return function () { return setActiveSubMenuIndex(index); }; };
    var onSubMenuBlur = function () { return setActiveSubMenuIndex(emptyActiveSubmenuIndex); };
    var onSubMenuLinkClick = function () { return setActiveSubMenuIndex(emptyActiveSubmenuIndex); };
    var renderLink = function (item) { return (React.createElement(NavLink, { className: styles.link, activeClassName: styles.linkActive, to: item.link, exact: item.isExact }, fp.has('count', item) ? (item.title ? (React.createElement(NotificationItem, { title: item.title, count: item.count })) : (React.createElement(MailNotificationItem, { count: item.count }))) : (item.title))); };
    var renderSubMenuLink = function (item) { return (React.createElement(NavLink, { key: item.link, className: cn(styles.link, styles.subMenuLink), to: item.link, onClick: onSubMenuLinkClick }, item.count ? React.createElement(NotificationItem, { title: item.title, count: item.count }) : item.title)); };
    var renderSubMenuItem = function (item) { return (React.createElement("div", { role: "menuitem" }, fp.has('count', item) ? (item.title ? (React.createElement(NotificationItem, { title: item.title, count: item.count })) : (React.createElement(MailNotificationItem, { count: item.count }))) : (item.title))); };
    var renderSubMenu = function (item) {
        var _a, _b;
        var index = fp.findIndex(item, props.config);
        var subMenuTitle = cn((_a = {},
            _a[styles.link] = true,
            _a[styles.subMenuTitle] = true,
            _a[styles.subMenuTitleActive] = hasActiveLinks(item),
            _a));
        var subMenuItemsClassNames = cn((_b = {},
            _b[styles.subMenuItems] = true,
            _b[styles.subMenuItemsVisible] = isActiveSubMenu(index),
            _b));
        var Icon = isActiveSubMenu(index) ? ArrowOpenedIcon : ArrowClosedIcon;
        return (React.createElement("div", { onMouseEnter: onSubMenuHover(index), onMouseLeave: onSubMenuBlur },
            React.createElement("div", { className: subMenuTitle },
                renderSubMenuItem(item),
                React.createElement(Icon, { className: styles.icon })),
            React.createElement("div", { className: subMenuItemsClassNames }, fp.map(renderSubMenuLink, item.items))));
    };
    var renderMenuElement = function (item) { return (React.createElement("div", { key: item.title, className: styles.menuElement, role: "menuitem" }, fp.has('items', item) ? renderSubMenu(item) : renderLink(item))); };
    return (React.createElement("div", { className: styles.main, role: "menubar" }, fp.map(renderMenuElement, props.config)));
};
export default MenuHorizontal;
