import React  from 'react';

import fp from 'lodash/fp';

import { Condition, MessageList, Table, ITable } from '@rfb/ui-kit';

import { IAccountsState } from '../../../../store';

import styles from './assets/styles/index.module.css';

export interface IAccountsTableProps {
  headers: ITable['headers'];
  rows: (className: string) => ITable['rows'];
  pageCount: number;
  filter: IAccountsState['filter'];
  rfInfoErrorList: string[],
  isLoading: boolean;
}

export interface IAccountsTableActions {
  actions: {
    setFilter: Function;
  }
}

const Transactions = (props: IAccountsTableProps & IAccountsTableActions) => {
  return (
    <div>
      <Condition value={!fp.isEmpty(props.rfInfoErrorList)}>
        <MessageList type="error" messages={props.rfInfoErrorList} />
      </Condition>

      <Table
        headers={props.headers}
        rows={props.rows(styles.noData)}
        sorting={{ value: 'doc_date', direction: 'ASC' }}
        pageCount={props.pageCount}
        page={props.filter.pageTransactions as number}
        isLoading={props.isLoading}
        onSortingChange={() => {}}
        onPageChange={(page: number) => props.actions.setFilter({ pageTransactions: page })}
      />
    </div>
  );
};

export default Transactions;
