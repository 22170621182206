var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var ArrowLeft = function (props) { return (React.createElement("svg", __assign({}, props, { width: "24", height: "20", viewBox: "0 0 24 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M11.3898 18.5714L3.86441 11.0204L24 11.0204V8.97959L3.86441 8.97959L11.3898 1.42857L9.9661 0L-1.90735e-06 10L9.9661 20L11.3898 18.5714Z", fill: "#282423" }))); };
export default ArrowLeft;
