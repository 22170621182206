import { createSelector } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { name } from './index';

export const selectState = fp.path(name);

export default {
  selectUserDealers: createSelector(selectState, fp.pathOr([], 'userDealers')),
  selectLogin: createSelector(selectState, fp.path('login')),
  selectPassword: createSelector(selectState, fp.path('password')),
  selectPasswordRepeat: createSelector(selectState, fp.path('passwordRepeat')),
  selectPasswordOld: createSelector(selectState, fp.path('passwordOld')),
  selectInn: createSelector(selectState, fp.path('inn')),
  selectPhoneNumber: createSelector(selectState, fp.path('phoneNumber')),
  selectKpp: createSelector(selectState, fp.path('kpp')),
  selectErrorListByField: createSelector(selectState, (state) => (field: string) => {
    return fp.pathOr([], ['errorList', field], state);
  }),
  selectServiceTermsText: createSelector(selectState, fp.path('serviceTermsText')),
  selectIsPasswordRestoreSent: createSelector(selectState, fp.path('isPasswordRestoreSent')),
  selectIsSending: createSelector(selectState, fp.path('isSending')),
  selectIsLoading: createSelector(selectState, fp.path('isLoading')),

  selectFilteredDealers: createSelector(selectState, fp.path('innFilter')),
};
