export const getResponseBody = [
  {
    "diasoft_id": "10000253051",
    "account": "40702810200000001064",
    "rest": 480859.71,
    "rko_debt": 0,
    "arrest": 1,
    "open_date": "2015-10-16",
    "close_date": null
  }
];
