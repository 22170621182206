var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Next = function (props) { return (React.createElement("svg", __assign({}, props, { width: "10", height: "16", viewBox: "0 0 10 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M1.625 0L9.5 8L1.625 16L0.5 14.8571L7.25 8L0.5 1.14286L1.625 0Z", fill: "#282423" }))); };
export default Next;
