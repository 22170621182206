import fp from 'lodash/fp';
import React, { useEffect } from 'react';

import { ButtonPrint, Loader, MessageList } from '@rfb/ui-kit';

import { ClientLineTypes } from '../../configs/type';
import Data from './components/Data';
import Header from './components/Header';
import Info from './components/Info';

import styles from './assets/styles/index.module.css';

export interface IClientLineDetailProps {
  clientLine: object;
  isLoading: boolean;
  multiBrand: number;
}

export interface IClientLineDetailPropsExternal {
  id: string;
  type: keyof typeof ClientLineTypes;
  multiBrand: number;
}

export interface IClientLineDetailActions {
  actions: {
    set: Function;
    setDataFull: Function;
    getDataFullById: Function;
  };
}

const Detail = (
  props: IClientLineDetailProps & IClientLineDetailPropsExternal & IClientLineDetailActions
) => {
  useEffect(() => {
    props.actions.set({ currentId: props.id });
    props.actions.getDataFullById({
      number: props.id,
      type: props.type,
      multiBrand: props.multiBrand,
    });
    // ESLINT Необходимо выполнение только при монтировании и размонтировании компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.isLoading) {
    return <Loader />;
  }
  return !fp.isEmpty(props.clientLine) ? (
    <div className={styles.main}>
      <div className={styles.header}>
        <Header />
      </div>

      <div className={styles.info}>
        <Info />
        <ButtonPrint wrapperClassName={styles.print} />
      </div>

      <div className={styles.data}>
        <Data />
      </div>
    </div>
  ) : (
    <MessageList type="error" messages={['Что-то пошло не так, обратитесь в поддержку']} />
  );
};

export default Detail;
