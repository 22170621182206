import React from 'react';

import { AuthTemplate } from '@rfb/ui-kit';
import styles from './assets/styles/index.module.css';

interface ISystemUnavailablePageProps {
  message: string;
}

const SystemUnavailablePage = (props: ISystemUnavailablePageProps) => {
  const renderContent = () => (
    <div className={styles.main}>
      <div className={styles.title}>
        <div>{props.message.length > 0 ? props.message : 'Сервер недоступен. Попробуйте зайти позже.'}</div>
      </div>
    </div>
  );

  return <AuthTemplate content={renderContent()} />;
};

export default SystemUnavailablePage;
