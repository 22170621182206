import { connect } from 'react-redux';

import { name, selectors, IClientLineState } from '../../../../store';

import { getTransformedStatusFull } from '../../../../helpers/transform';

import { IDTORFInfoClientLineFullResponse } from 'dto/rf-info';

import Component, { IClientLineDetailInfo } from './component';

const mapStateToProps = (state: { [name]: IClientLineState }): IClientLineDetailInfo => {
  const currentClientLine: IDTORFInfoClientLineFullResponse = selectors.selectCurrentClientLine(
    state
  );

  return {
    type: getTransformedStatusFull(currentClientLine.type),
    brandName: currentClientLine.name_brand,
    productType: currentClientLine.name_product,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
