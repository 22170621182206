import fp from 'lodash/fp';
import React, { useEffect, useState } from 'react';

import { configureValidator, runRulesChain } from '@rfb/common';
import { Button, Condition, Dropdown, FileInput, Input, MessageList } from '@rfb/ui-kit';
import { RequestDataEditForm } from 'dto/financial-block';
import {
  requestAmountRules,
  requestCodeRules,
  requestPdfRules,
  requestVinRules,
} from 'modules/financial-block/configs/validation';
import styles from './assets/styles/index.module.css';

export interface IAllCreateFormProps {
  isLoading: boolean;
  requestDataId?: number;
  requestCode: string;
  requestBrand: string;
  requestVin: string;
  requestAmount: string;
  requestPdf: any;
  requestCodeOptions: any[];
  requestCodeErrorList: string[];
  requestVinErrorList: string[];
  requestAmountErrorList: string[];
  requestPdfErrorList: string[];
  requestInvoiceFileName?: string;
  apiErrorList: string[];
}
export interface IAllCreateFormActions {
  actions: {
    set: Function;
    setForm: Function;
    createRequestData: Function;
    updateRequestData: Function;
    getRequestCodeOptions: Function;
    resetForm: Function;
    setError: Function;
  };
}
export interface IAllCreateFormPropsExternal {
  requestDataId?: number;
  history: any;
  brand_type: string;
  form: 'create' | 'edit';
  backUrl: string;
}

const AddCarForm = (
  props: IAllCreateFormProps & IAllCreateFormActions & IAllCreateFormPropsExternal
) => {
  const [isPorsche, setIsPorsche] = useState<boolean>(false);

  useEffect(() => {
    props.actions.getRequestCodeOptions({ used: false });
    setIsPorsche(props.requestBrand.includes('Porsche'));
    return () => {
      props.actions.resetForm();
    };
    // ESLINT Необходимо выполнение только в момент размонтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validators = {
    requestCode: configureValidator({
      name: 'requestCode',
      rule: requestCodeRules,
      setError: props.actions.setError,
    }),
    requestVin: configureValidator({
      name: 'requestVin',
      rule: requestVinRules,
      setError: props.actions.setError,
    }),
    requestAmount: configureValidator({
      name: 'requestAmount',
      rule: requestAmountRules(10000000),
      setError: props.actions.setError,
    }),
    requestPdf: configureValidator({
      name: 'requestPdf',
      rule: requestPdfRules,
      setError: props.actions.setError,
    }),
  };

  const saveRequestData = (values: any) => {
    if (props.form === 'create') {
      props.actions.createRequestData(values);
    } else if (props.form === 'edit') {
      props.actions.updateRequestData(values);
    }
  };

  const handleSubmit = () => {
    const sendData: RequestDataEditForm = {
      code: props.requestCode,
      brand: props.requestBrand,
      vin: props.requestVin,
      amount: props.requestAmount,
      pdfFile: props.requestPdf,
    };
    const rules = [
      validators.requestAmount(sendData.amount),
      validators.requestCode(sendData.code),
      validators.requestVin(sendData.vin),
    ];
    // if (props.brand_type === 'PORSCHE' && !props.requestInvoiceFileName) {
    if (isPorsche && !props.requestInvoiceFileName) {
      rules.push(validators.requestPdf(sendData.pdfFile));
    }

    runRulesChain(rules)
      .then(() =>
        saveRequestData({
          requestDataId: props.requestDataId,
          data: sendData,
          // brand_type: props.brand_type,
          brand_type: isPorsche ? 'PORSCHE' : props.brand_type,
          onSuccess: () => {
            props.history.push(props.backUrl);
          },
        })
      )
      .catch(fp.noop);
  };
  const onRequestCodeChange = (requestCode: string, option: any): void => {
    validators.requestCode(requestCode).finally((): void =>
      props.actions.setForm({
        requestCode,
        requestBrand: option.label.split(' ')[1].replace(/[\(\)]/g, ''),
      })
    );
    setIsPorsche(option.label.includes('Porsche'));
  };
  const onRequestVinChange = (requestVin: string): void => {
    validators.requestVin(requestVin).finally((): void => props.actions.setForm({ requestVin }));
  };
  const onRequestAmountChange = (requestAmount: string): void => {
    validators.requestAmount(requestAmount);
    props.actions.setForm({ requestAmount });
  };
  const onRequestPdfChange = (requestPdf: any): void => {
    validators
      .requestPdf(requestPdf)
      .finally((): void => props.actions.setForm({ requestInvoiceFileName: '', requestPdf }));
  };

  let hasErrors =
    !fp.isEmpty(props.requestCodeErrorList) ||
    !fp.isEmpty(props.requestVinErrorList) ||
    !fp.isEmpty(props.requestAmountErrorList);

  if (isPorsche) {
    hasErrors = hasErrors || !fp.isEmpty(props.requestPdfErrorList);
  }

  const codeAndBrandDisplayValue =
    props.requestCodeOptions.length === 0
      ? `${props.requestCode}(${props.requestBrand}`
      : props.requestCode;
  return (
    <div>
      <div className={styles.form} role="form">
        <div aria-label="form field">
          <Dropdown
            label="Код дилера и бренд"
            value={codeAndBrandDisplayValue}
            options={props.requestCodeOptions}
            hasError={!fp.isEmpty(props.requestCodeErrorList)}
            onChange={onRequestCodeChange}
          />
          <MessageList type="error" messages={props.requestCodeErrorList} />
        </div>

        <div aria-label="form field">
          <Input
            keyUnique="requestVin"
            type="vin-number"
            label="VIN автомобиля"
            name="requestVin"
            value={props.requestVin}
            hasError={!fp.isEmpty(props.requestVinErrorList)}
            onChange={onRequestVinChange}
          />
          <MessageList type="error" messages={props.requestVinErrorList} />
        </div>

        <div aria-label="form field">
          <Input
            type="money"
            keyUnique="requestAmount"
            label="Сумма (руб)."
            name="requestAmount"
            value={props.requestAmount}
            hasError={!fp.isEmpty(props.requestAmountErrorList)}
            onChange={onRequestAmountChange}
          />
          <MessageList type="error" messages={props.requestAmountErrorList} />
        </div>

        <h2 className={styles.fileSizeWarning}>
          Размер каждого загружаемого файла не должен превышать 5 Мегабайт
        </h2>

        <div className={styles.fileInput}>
          <Condition value={isPorsche}>
            <div aria-label="form field">
              <FileInput
                label="Вложить счет на оплату, PDF"
                value={props.requestPdf}
                onChange={onRequestPdfChange}
                accept="application/pdf"
                defaultFileName={props.requestInvoiceFileName}
              />
              <MessageList type="error" messages={props.requestPdfErrorList} />
            </div>
          </Condition>

          <Condition value={!fp.isEmpty(props.apiErrorList)}>
            <MessageList type="error" messages={props.apiErrorList} />
          </Condition>

          <Button
            isDisabled={props.isLoading || hasErrors}
            wrapperClassName={styles.button}
            type="submit"
            text="Сохранить"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCarForm;
