import React from 'react';
import cn from 'classnames';
import ButtonWithIcon from '../ButtonWithIcon';
import styles from './assets/styles/index.module.css';
export var ButtonPrint = function (props) {
    var onClick = function () { return window.print(); };
    return (React.createElement("div", { className: cn(props.wrapperClassName) },
        React.createElement(ButtonWithIcon, { wrapperClassName: styles.main, type: "print", text: "\u0420\u0430\u0441\u043F\u0435\u0447\u0430\u0442\u0430\u0442\u044C", isActive: true, onClick: onClick })));
};
export default ButtonPrint;
