import validator from 'utils/validator';

import fp from 'lodash/fp';

import translationConfig from 'configs/validation/translations';

export const codeRules = validator
  .string()
  .required()
  .test('code-is-number', translationConfig.number.typeError, fp.gt(fp.__, 0))
  .length(4);
