import React from 'react';

import styles from './assets/styles/index.module.css';
import AccountsMain from 'modules/accounts/components/AccountsMain';
import AppFullWidthTemplate from '../../../templates/AppFullWidthTemplate';

const Accounts = () => {
  const renderContent = () => (
    <div className={styles.main}>
      <div className={styles.top}>
        <h1 className={styles.title}>Расчётные счета</h1>
      </div>

      <div className={styles.content}>
        <AccountsMain />
      </div>
    </div>
  );

  return <AppFullWidthTemplate content={renderContent()} />;
};

export default Accounts;
