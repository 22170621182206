import React, { useEffect } from 'react';
import { Html } from '@rfb/ui-kit';

import styles from './assets/styles/index.module.css';

export interface IInformationTariffsProps {
  text: string;
}

export interface IInformationTariffsActions {
  actions: {
    getTariffsText: Function,
  };
}

const Tariffs = (props: IInformationTariffsProps & IInformationTariffsActions) => {
  useEffect(() => {
    props.actions.getTariffsText();
    // ESLINT Необходимо выполнение только в момент монтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>Тарифы</h1>
      <div className={styles.text}><Html text={props.text} /></div>
    </div>
  );
};

export default Tariffs;
