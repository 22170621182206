import { DealerSystems, convertPathUsingParams, getTransformedAmount } from '@rfb/common';
import {
  Button,
  ButtonWithIcon,
  Condition,
  ITable,
  MessageList,
  Table as UIKitTable,
} from '@rfb/ui-kit';
import cn from 'classnames';
import routingConfig from 'configs/routing';
import fp from 'lodash/fp';
import React, { useEffect } from 'react';
import { IFinancialState } from '../../store';
import styles from './assets/styles/index.module.css';

export interface IFTTableProps {
  headers: ITable['headers'];
  rows: ITable['rows'];
  pageCount: number;
  totalSum: number;
  totalRowCount: number;
  filter: IFinancialState['filter'];
  isLoading: boolean;
  isFinancingRequestInProgress: boolean;
  apiErrorList: string[];
  isReadOnly: boolean;
}

export interface IFTTableExternal {
  history: any;
  brand_type?: string;
  state?: string;
}

export interface IFTTableActions {
  actions: {
    getRequestDataUsed: Function;
    docDownloadUsed: Function;
    setFilter: Function;
  };
}

const FTTableUsed = (props: IFTTableProps & IFTTableActions & IFTTableExternal) => {
  useEffect(() => {
    props.actions.getRequestDataUsed({
      ...props.filter,
    });
  }, [props.filter, props.actions]);

  const getDocState = props.state === 'DOC';

  const onSortingChange = (sorting: ITable['sorting']): void =>
    props.actions.setFilter({ sorting });

  return (
    <div className={styles.main}>
      <div className={cn(styles.topBar, styles.flexCenter)}>
        {!props.isReadOnly && (
          <ButtonWithIcon
            type="add"
            text="Добавить автомобиль"
            isActive={!props.isLoading && !props.isFinancingRequestInProgress && !getDocState}
            onClick={() => {
              let url;
              const isRf = props.history.location.pathname.includes('/rf');
              if (isRf) {
                url = convertPathUsingParams(routingConfig.financialBlockFTCreateBySystem.path, {
                  system: DealerSystems.RUSFINANCE,
                });
              } else {
                url =
                  convertPathUsingParams(routingConfig.financialBlockFTCreate.path, {
                    system: DealerSystems.ROSBANK_AVTO,
                    type: 'used',
                  }) + props.history.location.search;
              }
              props.history.push(url);
            }}
          />
        )}

        <div className={styles.label}>
          Кол-во автомобилей:
          <div className={styles.text}>{props.totalRowCount}</div>
        </div>
        <div className={styles.label}>
          Итоговая сумма:
          <div className={styles.text}>{getTransformedAmount(props.totalSum)}</div>
        </div>
        <Condition value={!getDocState}>
          {!props.isReadOnly && (
            <Button
              wrapperClassName={styles.wizardButton}
              text="Отправить запрос на кредитную заявку"
              isDisabled={
                props.isLoading ||
                !fp.isEmpty(props.apiErrorList) ||
                props.isFinancingRequestInProgress ||
                props.rows.length === 0
              }
              onClick={() => {
                const url = convertPathUsingParams(routingConfig.financialBlockConfirmation.path, {
                  system: DealerSystems.ROSBANK_AVTO,
                  type: 'used',
                });

                props.history.push(url);
              }}
            />
          )}
        </Condition>
        <Condition value={getDocState}>
          <Button
            text="Скачать документ для просмотра"
            isDisabled={
              props.isLoading ||
              !fp.isEmpty(props.apiErrorList) ||
              props.isFinancingRequestInProgress
            }
            onClick={() => {
              props.actions.docDownloadUsed({ history: props.history });
            }}
          />
        </Condition>
      </div>

      <div className={styles.main}>
        <Condition value={!fp.isEmpty(props.apiErrorList)}>
          <MessageList type="error" messages={props.apiErrorList} />
        </Condition>
        <UIKitTable
          headers={props.headers}
          rows={props.rows}
          sorting={props.filter.sorting}
          pageCount={props.pageCount}
          page={props.filter.page as number}
          isLoading={props.isLoading || props.isFinancingRequestInProgress}
          onSortingChange={onSortingChange}
          onPageChange={(page: number) => props.actions.setFilter({ page })}
        />
      </div>
    </div>
  );
};

export default FTTableUsed;
