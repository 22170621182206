import Change from '@rfb/ui-kit/lib/icons/Change';
import Remove from '@rfb/ui-kit/lib/icons/Remove';
import { IFinancialState } from 'modules/financial-block/store';
import React from 'react';
import styles from './assets/styles/index.module.css';

import cn from 'classnames';

import { DealerSystems, convertPathUsingParams } from '@rfb/common';
import routingConfig from 'configs/routing';
import { RepoRequestDataRecordDto } from 'dto/financial-block';
import { getAutoType } from 'modules/financial-block/helpers';
import notification from 'utils/notification';

export interface FTButtonCellRepoProps {
  filter: IFinancialState['filter'];
  record: RepoRequestDataRecordDto;
}

export interface FTButtonCellRepoExternal {
  history: any;
  requestDataId: number;
  // brand_type: string;
  // state?: string;
}

export interface FTButtonCellRepoActions {
  actions: {
    setForm: Function;
    getRepoRequestData: Function;
    deleteRepoRequestDataById: Function;
    getRepoAttachments: Function;
  };
}

const FTButtonRepoCell = ({
  actions,
  ...props
}: FTButtonCellRepoProps & FTButtonCellRepoActions & FTButtonCellRepoExternal) => {
  return (
    <div>
      <span
        onClick={() => {
          actions.setForm({
            requestDataId: props.record.repo_request_data_id,
            requestBrandCode: props.record.brand_code,
            requestBrand: props.record.brand_name,
            requestModel: props.record.model_name,
            requestModelId: props.record.model_id,
            requestVin: props.record.request_vin,
            originCountry: props.record.origin_country,
            priceWithVAT: props.record.price_with_vat,
            vatAmount: props.record.vat_amount,
            accountNumber: props.record.account_number,
            accountNumberDate: props.record.account_date,
            supplierDocDate: props.record.supplier_doc_date,
            deliveryLocation: props.record.delivery_location,
            recieverShortName: props.record.reciever,
            paymentPurpose: props.record.payment_purpose,
          });
          const url =
            convertPathUsingParams(routingConfig.financialBlockFTEditRepo.path, {
              requestDataId: props.requestDataId,
              system: DealerSystems.RUSFINANCE,
              type: getAutoType(props.history),
            }) + props.history.location.search;
          props.history.push(url);
        }}
      >
        <Change className={cn(styles.icon)} />
      </span>
      <span
        style={{ marginLeft: '12px' }}
        onClick={() => {
          notification.confirm('Вы уверены, что хотите удалить запись', () => {
            actions.deleteRepoRequestDataById({
              requestDataId: props.requestDataId,
              onSuccess: () => {
                const repoRequestTypeCode = getAutoType(props.history).toUpperCase();
                actions.getRepoRequestData({
                  repoRequestTypeCode,
                  onSuccess: (operationDataId: number) => {
                    if (operationDataId) {
                      actions.getRepoAttachments({ operationDataId });
                    }
                  },
                  ...props.filter,
                });
              },
            });
          });
        }}
      >
        {/* <Remove className={cn(styles.icon, { [styles.iconDisabled]: getDocState })} /> */}
        <Remove className={cn(styles.icon)} />
      </span>
    </div>
  );
};

export default FTButtonRepoCell;
