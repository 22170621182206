import React from 'react';
import { History } from '@rfb/ui-kit';
import routingConfig from 'configs/routing';
import AppTemplate from 'templates/AppTemplate';
import InformationFeedback from 'modules/information/components/Feedback';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { convertPathUsingParams } from '@rfb/common';

const FeedbackFromNotificationPage = (props: TODO_ANY) => {
  const renderLeftSidebar = () => {
    const backPath = convertPathUsingParams(routingConfig.notifications.path, {
      dealerSystem: props.match.params.dealerSystem,
    });
    return <History type="back" to={backPath} />;
  };

  const renderContent = () => (
    <InformationFeedback
      notificationDealerSystem={props.match.params.dealerSystem}
      history={props.history}
    />
  );

  return <AppTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default FeedbackFromNotificationPage;
