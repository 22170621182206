import { rest } from 'msw';
import {
  profileChooseDealer_400_1010_responseBody,
  profileChooseDealer_400_1120_responseBody,
  profileChooseDealer_400_2010_responseBody,
  profileChooseDealer_403_1032_responseBody,
  profileChooseDealer_403_1033_responseBody,
} from './provileChooseDealer';
import { isSmsConfirmed } from '../security_send-confirm-sms/securitySendConfirmSmsHandlers';

export let isDealerSelected: string | null = null;

export let isFirstTime = true; // После клика на возврат из UserWidget

export const profileChooseDealerHandlers = [
  rest.post(`${process.env.REACT_APP_API_URL}/profile/choose-dealer`, (req, res, ctx) => {
    console.log('msw-handlers: POST /profile/choose-dealer');
    // @ts-ignore
    isDealerSelected = req.body['diasoft_id'];
    console.log(
      'msw-handlers: POST /profile/choose-dealer: isDealerSelected = ' +
        JSON.stringify(isDealerSelected)
    );

    // return isSmsConfirmed ?
    //   isDealerSelected === null ?
    //     res(ctx.status(403), ctx.json(profileChooseDealer_403_1033_responseBody)) :
    //     //        res(ctx.status(403), ctx.json(profileChooseDealer_400_1120_responseBody)) :
    //
    //     res(ctx.status(204)) :
    //   //        res(ctx.status(403), ctx.json(profileChooseDealer_400_1120_responseBody)) :
    //   //        res(ctx.status(403), ctx.json(profileChooseDealer_400_2010_responseBody)) :
    //
    //   res(ctx.status(403), ctx.json(profileChooseDealer_403_1032_responseBody));
    return res(ctx.status(204));
    //return res(ctx.status(403), ctx.json(profileChooseDealer_400_1010_responseBody));
  }),
];
