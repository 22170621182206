import React, { useEffect } from 'react';
import { Routing } from '@rfb/ui-kit';
import { Roles, getAvailableRouteList } from '@rfb/common';
import history from 'utils/history';
import routingConfig from 'configs/routing';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import SystemUnavailablePage from '../../../../pages/information/unavailability';

export interface IAppGuestProps {
  state: TODO_ANY;
}

export interface IAppGuestActions {
  actions: {
    checkSystemAvailability: Function,
  };
}

const AppGuest = (props: IAppGuestProps & IAppGuestActions) => {
  useEffect(() => {
    props.actions.checkSystemAvailability();
  }, []);

  const currentRoutingList = getAvailableRouteList(routingConfig, props.state, Roles.GUEST);
  return props.state.app.isSystemAvailable ?
    <Routing history={history} config={currentRoutingList} /> :
    <SystemUnavailablePage message={props.state.app.systemUnavailabilityMessage} />;
};

export default AppGuest;
