import { rest } from 'msw';
import { tranches } from './tranches';
import { tranches_after } from './tranches-after';

let isFirstTime = true;
export const rfinfoTranchesHandlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/rf-info/tranches`, (req, res, ctx) => {
    console.log('msw-handlers: GET /rf-info/tranches');
    const response = isFirstTime ? tranches : tranches_after;
    isFirstTime = false;
    return res(ctx.status(200), ctx.json(response));
  }),
];
