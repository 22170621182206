import React from 'react';

import { AuthTemplate, ButtonPrint } from '@rfb/ui-kit';

import AuthServiceTerms from 'modules/auth/components/ServiceTerms';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const ServiceTerms = (props: TODO_ANY) => {
  const renderContent = () => (
    <div className={styles.main}>
      <div className={styles.content}>
        <h1 className={styles.title}>Условия обслуживания</h1>
        <AuthServiceTerms history={props.history} />
      </div>
    </div>
  );

  const renderRightSidebar = () => (
    <div className={styles.sidebarRight}>
      <ButtonPrint />
    </div>
  );

  return <AuthTemplate content={renderContent()} sidebarRight={renderRightSidebar()} />;
};

export default ServiceTerms;
