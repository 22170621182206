import { connect } from 'react-redux';

import storage from 'utils/storage';

import Component, { IAppProps } from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (state: TODO_ANY): IAppProps => ({
  state,
  token: (storage as TODO_ANY).get('token'),
});

const mapDispatchToProps = ()=> ({});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
