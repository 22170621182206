import React from 'react';

import fp from 'lodash/fp';

import { getTransformedAmount } from '@rfb/common';
import { toDate } from '@rfb/common/lib/utils/format/date';

export const getTransformedTableRow = (data: string[], classForNoData: string) => {
  return {
    data: data.map((item, key) => {
      switch (key) {
        case 0:
          return toDate(item);
        case 3:
          return item ? getTransformedAmount(fp.toNumber(item)) : '-';
        case 4:
          return item ? (
            `-${getTransformedAmount(fp.toNumber(item))}`
          ) : (
            <span className={classForNoData}>-</span>
          );
        default:
          return item;
      }
    }),
  };
};

export const getDetailedTableRow = (data: string[], classForArrest: string) => {
  return {
    data: data.map((item, key) => {
      const sum = fp.isNumber(item) ? getTransformedAmount(fp.toNumber(item)) : item;
      switch (key) {
        case 0:
          return toDate(item);
        case 3:
          return sum || <span className={classForArrest}>Арест всего остатка</span>;
      }
      return item;
    }),
  };
};
