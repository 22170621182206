var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Close = function (props) { return (React.createElement("svg", __assign({}, props, { width: "12", height: "12", viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M1.09097 0L12 10.9091L10.9091 12L7.18178e-05 1.09091L1.09097 0Z", fill: "currentColor" }),
    React.createElement("path", { d: "M0 10.9091L10.909 1.83914e-07L11.9999 1.09091L1.0909 12L0 10.9091Z", fill: "currentColor" }))); };
export default Close;
