import { connect } from 'react-redux';

import { IClientLineState, name, selectors } from '../../../../store';

import { getTransformedAmount } from 'helpers/transform/amount';
import { getTransformedDate } from 'helpers/transform/date';
import {
  getTransformedCommissionTypeList,
  getTransformedPercentRate,
} from '../../../../helpers/transform';

import { IDTORFInfoClientLineFullResponse } from 'dto/rf-info';

import Component, { IClientLineDetailData } from './component';

const mapStateToProps = (state: { [name]: IClientLineState }): IClientLineDetailData => {
  const currentClientLine: IDTORFInfoClientLineFullResponse =
    selectors.selectCurrentClientLine(state);

  const getTransformedDateOrPlaceholder = (date: string | null): string =>
    date ? getTransformedDate(date) : '-';

  return {
    finishDate: getTransformedDateOrPlaceholder(currentClientLine.finish_date),
    limitAmount: getTransformedAmount(currentClientLine.limit_amount),
    percentRate: getTransformedPercentRate(currentClientLine.percent_rate),
    namePercentRate: currentClientLine.name_percent_rate,
    percentRateDate: getTransformedDateOrPlaceholder(currentClientLine.date_percent_rate),
    commissionTypeList: getTransformedCommissionTypeList(currentClientLine.comiss_type),
    mainDebtTotal: getTransformedAmount(currentClientLine.total_main_debt),
    restLimit: getTransformedAmount(currentClientLine.rest_limit),
    commissionDebtSumm: getTransformedAmount(currentClientLine.summ_comiss_debt),
    linePayDateNear: getTransformedDateOrPlaceholder(currentClientLine.date_near_line_pay),
    isOverdueDebt: Boolean(currentClientLine.overdue_debt),
    isLineBlock: Boolean(currentClientLine.line_block),
    isFlagNearLinePay: Boolean(currentClientLine.flag_near_line_pay),
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
