import React, { useEffect } from 'react';
import styles from './assets/styles/index.module.css';

import { Table, ITable } from '@rfb/ui-kit';
import Header from './components/Header';
import { IAccountsState } from '../../store';

export interface IAccountsTableProps {
  headers: ITable['headers'];
  rows: (className: string) => ITable['rows'];
  pageCount: number;
  isLoading: boolean;
  filter: IAccountsState['filter'];
  noRestrictions: boolean;
}

export interface IAccountRestrictionsDetailPropsExternal {
  id: string;
}

export interface IAccountsTableActions {
  actions: {
    getAccountArrests: Function;
    getAccounts: Function;
    setFilter: Function;
  }
}

const DetailedRestrictions = (props: IAccountsTableProps & IAccountsTableActions & IAccountRestrictionsDetailPropsExternal) => {
  useEffect(() => {
      props.actions.setFilter({ account: props.id });
      props.actions.getAccountArrests({
        account: props.id,
        page: props.filter.pageArrests
      });
  }, [props.actions, props.id, props.filter.pageArrests]);

  return <div className={styles.main}>
    <Header id={props.id} />

    {!props.noRestrictions && <div className={styles.data}>
      <Table
        headers={props.headers}
        rows={props.rows(styles.arrest)}
        sorting={{ value: 'doc_date', direction: 'ASC' }}
        pageCount={props.pageCount}
        page={props.filter.pageArrests as number}
        isLoading={props.isLoading}
        onSortingChange={() => {}}
        onPageChange={(page: number) => props.actions.setFilter({ pageArrests: page })}
      />
    </div>}
  </div>;
};

export default DetailedRestrictions;
