import { rest } from 'msw';
import {
  getResponseBody
} from './responseBody';

export const rfinfoAccountStatementsHandlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/rf-info/account-statements`, (req, res, ctx) => {
    console.log('msw-handlers: GET /rf-info/account-statements');
    return res(ctx.status(200), ctx.json(getResponseBody));
  }),
];
