import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import fp from 'lodash/fp';
export var Routing = function (props) {
    var renderRoute = function (route) { return (React.createElement(Route, { key: route.path, path: route.path, component: route.component, exact: route.isExact })); };
    return (React.createElement(Router, { history: props.history },
        props.children,
        React.createElement(Switch, null, fp.map(renderRoute, props.config))));
};
export default Routing;
