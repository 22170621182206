import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { DealerSystems } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { getDealerSystemConfig } from 'helpers/dealer';
import { IFinancialState, actions, name, selectors } from 'modules/financial-block/store';
import Component, {
  IFinancialPickerActions,
  IFinancialPickerProps,
  IFinancialPickerPropsExternal,
} from './component';

const mapStateToProps = (
  state: { [name]: IFinancialState },
  props: { history: TODO_ANY; dealerSystem?: DealerSystems }
): IFinancialPickerProps & IFinancialPickerPropsExternal => {
  const isAfterConfirm = props.history.location.search.includes('confirm=true');
  const result = {
    isLoading: selectors.selectIsLoading(state) || isAfterConfirm,
    apiErrorList: selectors.selectErrorListByField(state)('api'),
    codeType: selectors.selectCodeType(state),
    dealerSystemConfig: getDealerSystemConfig(),
    history: props.history,
  };
  return result;
};

const mapDispatchToProps = (dispatch: any): IFinancialPickerActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
