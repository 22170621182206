var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Print = function (props) { return (React.createElement("svg", __assign({}, props, { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M19.75 22.75H8.25V15.75H19.75V22.75Z", stroke: "#282423", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M9 5.25V9.75H19V5.25H9Z", stroke: "#282423", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M3.75 18.75H8.25V15.75H19.75V18.75H24.25V9.75H3.75V18.75Z", stroke: "#282423", strokeWidth: "1.5" }),
    React.createElement("rect", { x: "20.875", y: "12.375", width: "0.75", height: "0.75", stroke: "#282423", strokeWidth: "0.75" }))); };
export default Print;
