import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { name, actions, selectors, IAuthState } from '../../store';

import Component, {
  IAuthLoginProps,
  IAuthLoginPropsExternal,
  IAuthLoginActions,
} from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IAuthState },
  props: { history: TODO_ANY }
): IAuthLoginProps & IAuthLoginPropsExternal => ({
  login: selectors.selectLogin(state),
  loginErrorList: selectors.selectErrorListByField(state)('login'),
  password: selectors.selectPassword(state),
  passwordErrorList: selectors.selectErrorListByField(state)('password'),
  apiErrorList: selectors.selectErrorListByField(state)('api'),
  isSending: selectors.selectIsSending(state),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): IAuthLoginActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
