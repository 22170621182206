import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { name, actions, selectors, IUserState } from '../../store';

import Component, {
  IUserPasswordChangeProps,
  IUserPasswordChangePropsExternal,
  IUserPasswordChangeActions,
} from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IUserState },
  props: { history: TODO_ANY }
): IUserPasswordChangeProps & IUserPasswordChangePropsExternal => ({
  passwordOld: selectors.selectFormValueByField(state)('passwordOld'),
  passwordOldErrorList: selectors.selectErrorListByField(state)('passwordOld'),
  passwordNew: selectors.selectFormValueByField(state)('passwordNew'),
  passwordNewErrorList: selectors.selectErrorListByField(state)('passwordNew'),
  passwordNewRepeat: selectors.selectFormValueByField(state)('passwordNewRepeat'),
  passwordNewRepeatErrorList: selectors.selectErrorListByField(state)('passwordNewRepeat'),
  apiErrorList: selectors.selectErrorListByField(state)('api'),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): IUserPasswordChangeActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
