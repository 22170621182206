import { rest } from 'msw';
import { rfinfoTranchesPayment_POST_500_ResponseBody } from './rfinfoTranchesPayment_POST_500_ResponseBody';

export const rfinfoTranchesPaymentHandlers = [
  // rest.post(`${process.env.REACT_APP_API_URL}/rf-info/tranches/payment`, (req, res, ctx) => {
  //   console.log('msw-handlers: POST /rf-info/tranches/payment');
  //   return res(ctx.status(204));
  // }),
  rest.post(`${process.env.REACT_APP_API_URL}/rf-info/tranches/payment`, (req, res, ctx) => {
    console.log('msw-handlers: POST /rf-info/tranches/payment');
    return res(ctx.status(500), ctx.json(rfinfoTranchesPayment_POST_500_ResponseBody));
  }),
];
