import React from 'react';

import AppFullWidthTemplate from 'templates/AppFullWidthTemplate';

import TrancheFilter from 'modules/tranche/components/Filter';
import TrancheTable from 'modules/tranche/components/Table';

import styles from './assets/styles/index.module.css';

const Main = () => {
  const renderContent = () => (
    <div>
      <div className={styles.filter}>
        <TrancheFilter />
      </div>

      <div className={styles.table}>
        <TrancheTable />
      </div>
    </div>
  );

  return <AppFullWidthTemplate content={renderContent()} />;
};

export default Main;
