import React from 'react';

import fp from 'lodash/fp';

import { getTransformedAmount } from '@rfb/common';

import { Link } from '@rfb/ui-kit';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

export interface IAccountsRestrictionsProps {
  value?: string;
  count?: string;
  krtCount: number;
  id: string;
}

const Restrictions = (props: IAccountsRestrictionsProps) => {
  const pathToId = routingConfig.accounts.path + `/${props.id}`;

  return (
    <div className={styles.restrictions}>
      <div className={styles.debt}>
        <div className={styles.title}>Задолженность за расчётное обслуживание:</div>
        <div className={styles.value}>{getTransformedAmount(fp.toNumber(props.value) || 0)}</div>
      </div>
      <div className={styles.arrest}>
        <div className={styles.detailLink}>
          <Link to={pathToId} title="Ограничение на проведение операций:" />
        </div>
        <div className={styles.value}>
          {props.count || props.krtCount ? 'Да' : 'Нет'}
        </div>
      </div>
    </div>
  );
};

export default Restrictions;
