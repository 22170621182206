import React, { useEffect, useState } from 'react';

import { AuthTemplate, ButtonWithIcon, Input } from '@rfb/ui-kit';
import { actions } from 'modules/auth/store';

import Dealer from '../Dealer';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { useDispatch } from 'react-redux';
import styles from './assets/styles/index.module.css';

export interface IDealerChoiceProps {
  dealers: TODO_ANY;
}

export interface IDealerChoicePropsExternal {
  history: {
    push: (path: string) => void;
  };
}

export interface IDealerChoiceActions {
  actions: {
    setFilter: Function;
  };
}

const DealerChoice = (
  props: IDealerChoiceProps & IDealerChoicePropsExternal & IDealerChoiceActions
) => {
  const [innMask, setInnMask] = useState('');
  const dispatch = useDispatch();

  // Костыль для борьбы с кешированием поля 'Комиссия по договору'
  useEffect(() => {
    const place = 'DealerChoice';
    const key = `is${place}Reloaded`;
    const isReloaded = sessionStorage.getItem(key);
    if (!isReloaded) {
      sessionStorage.setItem(key, 'true');
    }
    return () => {
      sessionStorage.removeItem(key);
    };
  }, []);

  const onInnMaskChange = (innMask: string): void => {
    setInnMask(innMask);
    props.actions.setFilter({ innMask });
  };

  const renderContent = () => (
    <div className={styles.main}>
      <div className={styles.filter}>
        <h1 className={styles.title}>Выберите организацию</h1>
        <Input
          type="inn-number"
          wrapperClassName={styles.innMask}
          label="ИНН"
          value={innMask}
          onChange={onInnMaskChange}
        />
      </div>

      <div className={styles.navigation}>
        <Dealer history={props.history} />
      </div>

      <div>
        <ButtonWithIcon
          wrapperClassName={styles.logout}
          type="exit"
          text="Выход"
          isActive
          onClick={() => dispatch(actions.logout({}))}
        />
      </div>
    </div>
  );

  return <AuthTemplate content={renderContent()} />;
};

export default DealerChoice;
