import { DealerSystems, convertPathUsingParams } from '@rfb/common';
import { Button, Condition, ITable, Table } from '@rfb/ui-kit';
import apiEndpoints from 'configs/api/endpoints';
import {
  IDTOOperationDataResponse,
  OperationStatusId,
  RepoOperationDataViewDTO,
  TRepoAttachment,
} from 'dto/operation-data';
import { IOperationDataState } from 'modules/operation-data/store';
import React, { useEffect, useState } from 'react';
import new_api from 'utils/new-api';
import notification from 'utils/notification';
import DownloadLink from './DownloadLink';
import StageTracker from './StageTracker';
import styles from './assets/styles/index.module.css';

export enum RequestFinancingStage {
  CREATING = 'Создание',
  AGREEMENT = 'Согласование',
  SIGNING = 'Подписание',
  EXECUTED = 'Исполнена банком',
  NOT_DEFINED = 'Не определён',
}

export interface IOperationDataViewTableProps {
  stage: RequestFinancingStage;
  filter: IOperationDataState['filter'];
  headers: ITable['headers'];
  rows: ITable['rows'];
  page: number;
  pageCount: number;
  currentOperationDataRecord: IDTOOperationDataResponse['records'][number]; // получено из записи списка Журнала операций, поэтому может терять актуальность
  operationDataView: RepoOperationDataViewDTO;
  requestAttachment: TRepoAttachment;
  isLoading: boolean;
  errors: string[];
}

export interface IOperationDataViewTablePropsExternal {
  id?: number;
  dealerSystem: DealerSystems;
}

export interface IOperationDataViewTableActions {
  actions: {
    getOperationDataById: Function;
    getRepoRequestDataWithOperationDataId: Function;
    getRepoAttachments: Function;
    generateFileForDownload: Function;
    signAndSave: Function;
    resetData: Function;
    setFilter: Function;
    resetFilterByOmitList: Function;
  };
}

const OperationDataViewTable = (
  props: IOperationDataViewTableProps &
    IOperationDataViewTablePropsExternal &
    IOperationDataViewTableActions
) => {
  const operationStatusType = props.currentOperationDataRecord.operation_status_id;
  const [isFileShown, setFileShown] = useState(false);
  const [isFileSigned, setFileSigned] = useState(false);
  const [isFileSignAndSaveButtonDisable, setFileSignAndSaveButtonDisable] = useState(false);

  useEffect(() => {
    props.actions.getRepoRequestDataWithOperationDataId({
      operationDataId: props.id,
    });
    props.actions.getRepoAttachments({
      operationDataId: props.id,
    });
    props.actions.getOperationDataById({ operationDataId: props.id });
  }, [props.id, props.actions]);

  useEffect(() => {
    props.actions.getRepoRequestDataWithOperationDataId({
      operationDataId: props.id,
      ...props.filter,
    });
    return () => {
      props.actions.resetData();
    };
  }, [props.filter]);

  const onFilterClear = () => {
    const omitList: (keyof IOperationDataState['filter'])[] = [];
    props.actions.resetFilterByOmitList(omitList);
  };

  const onSortingChange = (sorting: ITable['sorting']): void =>
    props.actions.setFilter({ sorting });

  const handleFileGenerateAndShow = () => {
    props.actions.generateFileForDownload({
      operationDataId: props.id,
      fileTypeCode: 'REQ',
      onSuccess,
    });
  };

  // TODO Устранить дублирование с financial-block
  const onSuccess = async (fileUrl: string, fileName: string) => {
    const url = process.env.REACT_APP_NEW_API_URL + fileUrl;
    const config = {
      responseType: 'blob',
    };
    try {
      const response = await new_api.get(url, config);
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      //window.open(fileUrl, '_blank');
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.target = '_blank';
      link.click();

      setFileShown(true);

      props.actions.getOperationDataById({ operationDataId: props.id }); // Для обновления статуса
    } catch (error) {
      notification.info('Документ недоступен. Попробуйте повторить попытку позднее');
    }
  };

  const handleFileSignAndSave = () => {
    const operationDataId = props.currentOperationDataRecord.operation_data_id;
    setFileSignAndSaveButtonDisable(true);
    props.actions.signAndSave({
      operationDataId,
      onSuccess: () => {
        setFileSigned(true);
        if (props.requestAttachment === null) {
          props.actions.getRepoAttachments({
            operationDataId,
          });
        }
        props.actions.getOperationDataById({ operationDataId: props.id }); // Для обновления статуса
      },
    });
  };

  function getStageNumber(stages: string[], stage: string): number {
    return stages.findIndex((element) => element.includes(stage)) + 1;
  }
  const stages = ['1.Создание', '2.Согласование', '3.Подписание', '4.Исполнена банком'];
  const currentStage = getStageNumber(stages, props.stage);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginLeft: '280px', marginBottom: '10px' }}>
          <StageTracker stages={stages} currentStage={currentStage} />
        </div>
      </div>
      <div className={styles.main}>
        <Table
          headers={props.headers}
          rows={props.rows}
          sorting={props.filter.sorting}
          page={props.page}
          pageCount={props.pageCount}
          isLoading={props.isLoading}
          onSortingChange={onSortingChange}
          onPageChange={(page: number) => props.actions.setFilter({ page })}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '40px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '300px',
          }}
        >
          <Condition
            value={
              operationStatusType === OperationStatusId.AGREEMENT_OR_SIGNING_IS_NEEDED ||
              operationStatusType === OperationStatusId.READY_FOR_FINANCING_OR_SIGNED ||
              operationStatusType === OperationStatusId.EXECUTED
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '45px',
              }}
            >
              <div>Количество автомобилей: {props.rows.length}</div>
              <div>Итоговая сумма: {props.operationDataView?.summary.request_sum}</div>
            </div>
          </Condition>
          <Condition
            value={
              operationStatusType === OperationStatusId.READY_FOR_FINANCING_OR_SIGNED ||
              operationStatusType === OperationStatusId.EXECUTED
            }
          >
            {!!props.requestAttachment && (
              <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', bottom: '8px', width: '300px' }}>
                  <DownloadLink
                    fileName={props.requestAttachment.attachment_file_name}
                    fileUrl={`${convertPathUsingParams(
                      apiEndpoints.financialBlock.getGeneratedFileDownload,
                      {
                        operationDataId: props.currentOperationDataRecord.operation_data_id,
                        operationAttachmentId: props.requestAttachment.operation_attachment_id,
                      }
                    )}`}
                  />
                </div>
              </div>
            )}
          </Condition>
        </div>
        <Condition
          value={
            operationStatusType === OperationStatusId.AGREEMENT_OR_SIGNING_IS_NEEDED &&
            props.currentOperationDataRecord?.signed_enable
          }
        >
          <Condition value={!isFileShown}>
            <Button
              wrapperClassName={styles.signingButton}
              text={'Подписать заявку'}
              onClick={handleFileGenerateAndShow}
            />
          </Condition>
          <Condition value={isFileShown}>
            <Button
              wrapperClassName={styles.signingButton}
              text={'Подписать заявку и сохранить'}
              isDisabled={isFileSignAndSaveButtonDisable}
              onClick={handleFileSignAndSave}
            />
          </Condition>
        </Condition>
      </div>
    </div>
  );
};

export default OperationDataViewTable;
