import React, { useEffect } from 'react';

import fp from 'lodash/fp';

import { Button, Condition, Form, Input, Link, MessageList } from '@rfb/ui-kit';

import routingConfig from 'configs/routing';

import { configureValidator, runRulesChain } from 'helpers/validation';

import { loginRules, passwordAuthRules } from '../../configs/validation';

import { IDTOTokenCreateRequest } from 'dto/auth';

import styles from './assets/styles/index.module.css';

export interface IAuthLoginProps {
  login: string;
  loginErrorList: string[];
  password: string;
  passwordErrorList: string[];
  apiErrorList: string[];
  isSending: boolean;
}

export interface IAuthLoginPropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void;
  };
}

export interface IAuthLoginActions {
  actions: {
    set: Function;
    setError: Function;
    reset: Function;
    login: Function;
  };
}

const Login = (props: IAuthLoginProps & IAuthLoginPropsExternal & IAuthLoginActions) => {
  useEffect(() => {
    return () => {
      props.actions.reset();
    };
    // ESLINT Необходимо выполнение только в момент размонтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validators = {
    login: configureValidator({
      name: 'login',
      rule: loginRules,
      setError: props.actions.setError,
    }),
    password: configureValidator({
      name: 'password',
      rule: passwordAuthRules,
      setError: props.actions.setError,
    }),
  };

  const onLoginChange = (login: string): void => {
    validators.login(login).finally((): void => props.actions.set({ login }));
  };
  const onPasswordChange = (password: string): void => {
    validators.password(password).finally((): void => props.actions.set({ password }));
  };
  const onFormSubmit = (): void => {
    const data: IDTOTokenCreateRequest = { login: props.login, password: props.password };
    const rules = [validators.login(data.login), validators.password(data.password)];
    runRulesChain(rules).then(() => props.actions.login({ data, history: props.history }));
  };
  return (
    <div className={styles.main}>
      <h1 className={styles.title}>Вход в личный кабинет</h1>

      <Form onSubmit={onFormSubmit}>
        <Input
          wrapperClassName={styles.login}
          label="Логин"
          value={props.login}
          hasError={!fp.isEmpty(props.loginErrorList)}
          onChange={onLoginChange}
        />
        <MessageList type="error" messages={props.loginErrorList} />

        <Input
          wrapperClassName={styles.password}
          type="password"
          label="Пароль"
          value={props.password}
          hasError={!fp.isEmpty(props.passwordErrorList)}
          onChange={onPasswordChange}
        />
        <MessageList type="error" messages={props.passwordErrorList} />

        <Condition value={!fp.isEmpty(props.apiErrorList)}>
          <MessageList type="error" messages={props.apiErrorList} />
        </Condition>

        <Link
          wrapperClassName={styles.restorePassword}
          title="Восстановить пароль?"
          to={routingConfig.passwordRestore.path}
        />

        <Button
          wrapperClassName={styles.button}
          type="submit"
          text="Войти"
          isDisabled={props.isSending}
        />
      </Form>
    </div>
  );
};

export default Login;
