import { rest } from 'msw';
import { profileView_403 } from './provileView-403';
import { isDealerSelected } from '../profile_choose-dealer/profileChooseDealerHandlers';
import { profileView_GET } from './provileView';
import { profileViewWithReadOnlyRole_GET } from './provileViewReadOnly';

export const profileViewHandlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/profile/view`, (req, res, ctx) => {
    console.log('msw-handlers: GET /profile/view');
    console.log('msw-handlers: GET /profile/view: isDealerSelected = ' + isDealerSelected);
    const response = isDealerSelected
      ? profileView_GET
      : // : profileViewWithReadOnlyRole_GET
        profileView_403;
    return isDealerSelected
      ? res(ctx.status(200), ctx.json(response))
      : res(ctx.status(403), ctx.json(response));

    //return res(ctx.status(200), ctx.json(profileView_GET));
    //return res(ctx.status(200), ctx.json(profileViewWithReadOnlyRole_GET));
    //return res(ctx.status(403), ctx.json(profileView_403));
  }),
];
