var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Save = function (props) { return (React.createElement("svg", __assign({}, props, { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M22 11V23.5H10V22H8.5V23.5V25H10H22H23.5V23.5V9.5L17.7476 3.5H17.5H16H10H8.5V5V11H10V5H16V10.25V11H16.75H21.5H22ZM21.422 9.5L17.5 5.40924V9.5H21.422ZM20 12.5H4V20.5H20V12.5Z", fill: "#282423" }),
    React.createElement("path", { d: "M5.18531 14.3H8.14931V15.11H6.08531V16.088H7.97531V16.898H6.08531V18.5H5.18531V14.3ZM9.28055 14.3H10.1805V18.5H9.28055V14.3ZM11.5594 14.3H12.4594V17.69H14.5174V18.5H11.5594V14.3ZM15.5257 14.3H18.4837V15.092H16.4257V15.986H18.3037V16.772H16.4257V17.708H18.5197V18.5H15.5257V14.3Z", fill: "white" }))); };
export default Save;
