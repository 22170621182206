import React from 'react';

import fp from 'lodash/fp';

import { Button, Condition, Input, Form, MessageList } from '@rfb/ui-kit';

import { configureValidator, runRulesChain } from 'helpers/validation';
import { codeWordRules } from 'modules/auth/configs/validation';

import { DTOCodeWordChangeRequest } from 'dto/security';
import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

export interface IUserCodeWordChangeProps {
  codeWord: string;
  codeWordErrorList: string[];
  apiErrorList: string[];
}

export interface IUserCodeWordChangePropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void;
  };
}

export interface IUserCodeWordChangeActions {
  actions: {
    setForm: Function;
    setError: Function;
  };
}

const CodeWordChange = (
  props: IUserCodeWordChangeProps & IUserCodeWordChangePropsExternal & IUserCodeWordChangeActions
) => {
  const validators = {
    codeWord: configureValidator({
      name: 'codeWord',
      rule: codeWordRules,
      setError: props.actions.setError,
    }),
  };

  const onCodeWordChange = (codeWord: string): void => {
    validators
      .codeWord(codeWord)
      .finally((): void => props.actions.setForm({ codeWord: codeWord?.toUpperCase() }));
  };

  const onFormSubmit = (): void => {
    const data: DTOCodeWordChangeRequest = { key_word: props.codeWord };
    const rules = [validators.codeWord(data.key_word)];
    runRulesChain(rules).then(() =>
      props.history.push(routingConfig.profileCodeWordChangeConfirmation.path)
    );
  };

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <h1>Изменить кодовое слово</h1>
      </div>

      <Form onSubmit={onFormSubmit}>
        <Input
          wrapperClassName={styles.codeWord}
          label="Новое кодовое слово"
          value={props.codeWord}
          onChange={onCodeWordChange}
        />
        <MessageList type="error" messages={props.codeWordErrorList} />

        <Condition value={!fp.isEmpty(props.apiErrorList)}>
          <MessageList type="error" messages={props.apiErrorList} />
        </Condition>

        <Button type="submit" text="Сохранить" />
      </Form>
    </div>
  );
};

export default CodeWordChange;
