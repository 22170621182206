import { Logger, downloadLogs } from '@rfb/common';
import { Button } from '@rfb/ui-kit';
import React from 'react';
import AppTemplate from 'templates/AppTemplate';

const ClientLogPage = () => {
  const renderContent = () => (
    <Button
     text="Получить журнал работы приложения"
     onClick={() => downloadLogs(Logger.getInstance())}
    />
  );

  return <AppTemplate content={renderContent()} />;
};

export default ClientLogPage;


