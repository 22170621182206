import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { name, actions, selectors, IInformationState } from '../../store';

import Component, { IInformationTariffsProps, IInformationTariffsActions } from './component';

const mapStateToProps = (state: { [name]: IInformationState }): IInformationTariffsProps => ({
  text: selectors.selectTariffsText(state),
});

const mapDispatchToProps = (dispatch: any): IInformationTariffsActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
