var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var NextDouble = function (props) { return (React.createElement("svg", __assign({}, props, { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M1.125 0L9 8L1.125 16L0 14.8571L6.75 8L0 1.14286L1.125 0Z", fill: "#282423" }),
    React.createElement("path", { d: "M8.125 0L16 8L8.125 16L7 14.8571L13.75 8L7 1.14286L8.125 0Z", fill: "#282423" }))); };
export default NextDouble;
