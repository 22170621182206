// ESLINT Данный формат переводов необходим для использования в утилите валидации
/* eslint no-template-curly-in-string: "off" */

export default {
  string: {
    email: 'Значение должно быть адресом электронной почты',
    min: 'Минимальное количество символов - ${min}',
    max: 'Максимальное количество символов - ${max}',
    length: 'Требуемое количество символов - ${length}',
  },

  number: {
    typeError: 'Значение должно быть числом',
  },

  mixed: {
    required: 'Поле обязательно для заполнения',
  },

  app: {
    password:
      'Не удовлетворяет требованиям безопасности',
    passwordAuth:
      'Пароль должен содержать числа, символы верхнего регистра и символы нижнего регистра',
    passwordRepeat: 'Пароли не совпадают',
    subjectRequired: 'Выберите тему сообщения',
    messageRequired: 'Введите ваше сообщение',
    phoneIncorrect: 'Некорректный номер телефона',
  },
};
