"use strict";
exports.__esModule = true;
exports.Roles = void 0;
var Roles;
(function (Roles) {
    Roles["MASTER_ADMIN"] = "MASTER_ADMIN";
    Roles["ADMIN"] = "ADMIN";
    Roles["BANK_EMPLOYEE"] = "BANK_EMPLOYEE";
    Roles["RF_EMPLOYEE"] = "RF_EMPLOYEE";
    Roles["USER"] = "USER";
    Roles["GUEST"] = "GUEST";
    Roles["READ_ONLY"] = "READ_ONLY";
})(Roles = exports.Roles || (exports.Roles = {}));
