import React from 'react';

import { History } from '@rfb/ui-kit';

import AppTemplate from 'templates/AppTemplate';

import UserPasswordChange from 'modules/user/components/PasswordChange';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const PasswordChange = (props: TODO_ANY) => {
  const renderLeftSidebar = (): JSX.Element => (
    <div className={styles.sidebarLeft}>
      <History type="back" to={routingConfig.profile.path} />
    </div>
  );

  const renderContent = () => (
    <div className={styles.content}>
      <UserPasswordChange history={props.history} />
    </div>
  );

  return <AppTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default PasswordChange;
