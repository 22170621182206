import React from 'react';

import { AuthTemplate } from '@rfb/ui-kit';

import AuthPasswordExpired from 'modules/auth/components/PasswordExpired';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const PasswordExpired = (props: TODO_ANY) => {
  const renderContent = () => (
    <div className={styles.content}>
      <AuthPasswordExpired history={props.history} />
    </div>
  );

  return <AuthTemplate content={renderContent()} />;
};

export default PasswordExpired;
