export const tranches_after = {
  tranches: [
    {
      vin: 'XWEPH81ACM0006587',
      name_brand: 'Kia',
      model: 'SPORTAGE PE,2.0L GAS MT AWD',
      number: '1358',
      type: 'FL2',
      status: 0,
      sum_amount: 1826818,
      sum_pre_pay: 0,
      real_start_date: '2021-03-03',
      date_beg_pay: '2021-04-06',
      finish_date: '2021-08-26',
      rest_main_debt: 1826818,
      overdue_debt: 0,
      percent_rate: 0.08,
      sum_percent: 0,
      sum_fin: 0,
      sum_comiss: 0,
      flag_overdue_debt: false,
      repayment_amount: null,
      payment_type: 'full-repayment',
    },
    {
      vin: 'XWEPH81ACM0006665',
      name_brand: 'Honda',
      model: 'SPORTAGE PE,2.0L GAS MT AWD',
      number: '1358',
      type: 'FL2',
      status: 1,
      sum_amount: 1826818,
      sum_pre_pay: 0,
      real_start_date: '2021-03-15',
      date_beg_pay: '2021-04-20',
      finish_date: '2021-09-10',
      rest_main_debt: 1826818,
      overdue_debt: 0,
      percent_rate: 0.08,
      sum_percent: 0,
      sum_fin: 0,
      sum_comiss: 0,
      flag_overdue_debt: false,
      repayment_amount: null,
      payment_type: 'full-repayment',
    },
    {
      vin: 'XWEPH81ACM0006698',
      name_brand: 'Kia',
      model: 'SPORTAGE PE,2.0L GAS MT AWD',
      number: '1358',
      type: 'FL2',
      status: 2,
      sum_amount: 1826818,
      sum_pre_pay: 0,
      real_start_date: '2021-03-15',
      date_beg_pay: '2021-04-17',
      finish_date: '2021-09-08',
      rest_main_debt: 1826818,
      overdue_debt: 0,
      percent_rate: 0.08,
      sum_percent: 0,
      sum_fin: 0,
      sum_comiss: 0,
      flag_overdue_debt: false,
      repayment_amount: null,
      payment_type: null
    },
    // {
    //   "vin": "XWEG3417BM0006524",
    //   "name_brand": "Kia",
    //   "model": "K5 DL3, 2.5 GAS AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 2078717,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-04-16",
    //   "date_beg_pay": "2021-05-21",
    //   "finish_date": "2021-10-12",
    //   "rest_main_debt": 2078717,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEH5816BM0009095",
    //   "name_brand": "Kia",
    //   "model": "CEED SW CD, 1.6L GAS AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 1541967,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-04-16",
    //   "date_beg_pay": "2021-05-21",
    //   "finish_date": "2021-10-12",
    //   "rest_main_debt": 1541967,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEG3417BM0006141",
    //   "name_brand": "Kia",
    //   "model": "K5 DL3, 2.5 GAS AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 2078717,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-04-20",
    //   "date_beg_pay": "2021-05-25",
    //   "finish_date": "2021-10-14",
    //   "rest_main_debt": 2078717,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEJ381EBMC003506",
    //   "name_brand": "Kia",
    //   "model": "SOUL SK3, 1.6 MPI GAS 6AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 1247467,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-05-06",
    //   "date_beg_pay": "2021-06-09",
    //   "finish_date": "2021-11-01",
    //   "rest_main_debt": 1247467,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEH5816BM0009536",
    //   "name_brand": "Kia",
    //   "model": "CEED SW CD, 1.6L GAS AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 1489717,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-05-11",
    //   "date_beg_pay": "2021-06-16",
    //   "finish_date": "2021-11-08",
    //   "rest_main_debt": 1489717,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEKN814DM0007582",
    //   "name_brand": "Kia",
    //   "model": "MOHAVE HM PE2, 3.0L DSL AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 3764967,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-05-13",
    //   "date_beg_pay": "2021-06-17",
    //   "finish_date": "2021-11-08",
    //   "rest_main_debt": 3764967,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEPH81ACM0006828",
    //   "name_brand": "Kia",
    //   "model": "SPORTAGE PE,2.0L GAS MT AWD",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 1817368,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-05-18",
    //   "date_beg_pay": "2021-06-22",
    //   "finish_date": "2021-11-12",
    //   "rest_main_debt": 1817368,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": "full-repayment"
    // },
    // {
    //   "vin": "XWEJ381UBM0011102",
    //   "name_brand": "Kia",
    //   "model": "SOUL SK3, 2.0 MPI GAS 6AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 1546717,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-05-21",
    //   "date_beg_pay": "2021-06-25",
    //   "finish_date": "2021-11-16",
    //   "rest_main_debt": 1546717,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEJ381UBM0011210",
    //   "name_brand": "Kia",
    //   "model": "SOUL SK3, 2.0 MPI GAS 6AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 1603717,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-05-27",
    //   "date_beg_pay": "2021-07-01",
    //   "finish_date": "2021-11-22",
    //   "rest_main_debt": 1603717,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEH5816BM0010030",
    //   "name_brand": "Kia",
    //   "model": "CEED SW CD, 1.6L GAS AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 1570467,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-06-01",
    //   "date_beg_pay": "2021-07-06",
    //   "finish_date": "2021-11-26",
    //   "rest_main_debt": 1570467,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEG3417BM0007176",
    //   "name_brand": "Kia",
    //   "model": "K5 DL3, 2.5 GAS AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 2116717,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-06-07",
    //   "date_beg_pay": "2021-07-10",
    //   "finish_date": "2021-12-01",
    //   "rest_main_debt": 2116717,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEG2414BM0004365",
    //   "name_brand": "Kia",
    //   "model": "K5 DL3, 2.0 GAS AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 1727217,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-06-15",
    //   "date_beg_pay": "2021-07-20",
    //   "finish_date": "2021-12-13",
    //   "rest_main_debt": 1727217,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEG3414BM0009136",
    //   "name_brand": "Kia",
    //   "model": "K5 DL3, 2.0 GAS AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 1955217,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-06-18",
    //   "date_beg_pay": "2021-07-23",
    //   "finish_date": "2021-12-14",
    //   "rest_main_debt": 1955217,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEG6417BM0005390",
    //   "name_brand": "Kia",
    //   "model": "K5 DL3, 2.5 GAS AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 2183217,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-06-18",
    //   "date_beg_pay": "2021-07-23",
    //   "finish_date": "2021-12-14",
    //   "rest_main_debt": 2183217,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEG6417BM0005620",
    //   "name_brand": "Kia",
    //   "model": "K5 DL3, 2.5 GAS AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 2183217,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-06-18",
    //   "date_beg_pay": "2021-07-23",
    //   "finish_date": "2021-12-14",
    //   "rest_main_debt": 2183217,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWEJ381UBM0011594",
    //   "name_brand": "Kia",
    //   "model": "SOUL SK3, 2.0 MPI GAS 6AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 1622717,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-07-01",
    //   "date_beg_pay": "2021-08-03",
    //   "finish_date": "2021-12-27",
    //   "rest_main_debt": 1622717,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // },
    // {
    //   "vin": "XWENB81BBM0000275",
    //   "name_brand": "Kia",
    //   "model": "CARNIVAL KA4, 2.2 DSL AT",
    //   "number": "1358",
    //   "type": "FL2",
    //   "status": 0,
    //   "sum_amount": 2943217,
    //   "sum_pre_pay": 0,
    //   "real_start_date": "2021-07-01",
    //   "date_beg_pay": "2021-07-30",
    //   "finish_date": "2021-12-21",
    //   "rest_main_debt": 2943217,
    //   "overdue_debt": 0,
    //   "percent_rate": 0.08,
    //   "sum_percent": 0,
    //   "sum_fin": 0,
    //   "sum_comiss": 0,
    //   "flag_overdue_debt": false,
    //   "repayment_amount": null,
    //   "payment_type": null
    // }
  ],
  filters: {
    models: [
      'CARNIVAL KA4, 2.2 DSL AT',
      'CEED SW CD, 1.6L GAS AT',
      'K5 DL3, 2.0 GAS AT',
      'K5 DL3, 2.5 GAS AT',
      'MOHAVE HM PE2, 3.0L DSL AT',
      'PICANTO JA PE,1.2L GAS AT',
      'RIO FB PE,1.6L GAS AT',
      'SELTOS SP2, 1,6 MPI GAS 6AT 2WD',
      'SORENTO MQ4, 2.5L GAS AWD AT',
      'SOUL SK3, 1.6 MPI GAS 6AT',
      'SOUL SK3, 2.0 MPI GAS 6AT',
      'SPORTAGE PE,2.0L GAS AT',
      'SPORTAGE PE,2.0L GAS AT AWD',
      'SPORTAGE PE,2.0L GAS MT AWD',
    ],
    brands: ['Kia', 'Honda'],
    contracts: ['1358'],
    statuses: ['0', '1', '2'],
    finish_dates: [
      '2021-08-26',
      '2021-09-08',
      '2021-09-10',
      '2021-10-12',
      '2021-10-14',
      '2021-11-01',
      '2021-11-08',
      '2021-11-12',
      '2021-11-16',
      '2021-11-22',
      '2021-11-26',
      '2021-12-01',
      '2021-12-13',
      '2021-12-14',
      '2021-12-21',
      '2021-12-27',
      '2022-01-10',
      '2022-01-11',
      '2022-01-12',
      '2022-01-13',
      '2022-01-17',
    ],
  },
};
