import { createSelector } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { name } from './index';

export const selectState = fp.path(name);
const selectFilter = createSelector(selectState, fp.path('filter'));
const selectData = createSelector(selectState, fp.path('requestData'));
const selectRepoData = createSelector(selectState, fp.path('repoRequestData.records'));
const selectRepoDataData = createSelector(selectState, fp.path('repoRequestData.data.records'));
const deliveryLocations = createSelector(selectState, fp.path('deliveryLocations'));
const selectors = {
  selectPdfFileName: createSelector(selectState, fp.path('pdf_file_name')), // TODO Убрать после отладки
  selectRequestData: selectData,
  selectRepoRequestData: selectRepoData,
  selectRequestDataById: createSelector(selectData, (values) => (id: number) => {
    return values.find((value: any) => value.request_data_id === id);
  }),
  selectRepoDataById: createSelector(selectRepoData, (values) => (id: number) => {
    return values.find((value: any) => value.request_data_id === id);
  }),

  selectDataWithFieldList: createSelector(
    selectData,
    (state) => (fieldList: string[]) =>
      fp.map((value: { [key: string]: string }) => fp.paths(fieldList, value), state)
  ),
  selectPageCount: createSelector(selectState, fp.path('pageCount')),
  selectTotalCount: createSelector(selectState, fp.path('totalCount')),
  selectTotalSum: createSelector(selectState, fp.path('totalSum')),
  selectAdvanceSum: createSelector(selectState, fp.path('advanceSum')),
  selectIsNoCreditLines: createSelector(selectState, fp.path('isNoCreditLines')),
  selectIsLoading: createSelector(selectState, fp.path('isLoading')),
  selectUsedCarSettings: createSelector(selectState, fp.path('usedCarSettings')),
  selectIsFinancingRequestInProgress: createSelector(
    selectState,
    fp.path('isFinancingRequestInProgress')
  ),
  selectIsRequestIncludePorsche: createSelector(selectState, fp.path('isRequestIncludePorsche')),
  selectCodeType: createSelector(selectState, fp.path('codeTypeUsed')),
  selectHasPorscheCodes: createSelector(selectState, fp.path('codeTypeBrand')),
  selectDealerBrandCodes: createSelector(selectState, fp.path('codes')),
  selectRequestCodeOptions: createSelector(selectState, fp.path('requestCodeOptions')),
  selectRequestModelOptions: createSelector(selectState, fp.path('requestModelOptions')), // TODO Нужно ?
  selectBrandOptionsUsed: createSelector(selectState, fp.path('usedBrandOptions')),
  selectBrandModelOptionsUsed: createSelector(selectState, fp.path('usedBrandModelOptions')),
  selectForm: createSelector(selectState, fp.path('form')),
  selectContractPdf: createSelector(selectState, fp.path('requestContractPdf')),
  selectActOfAcceptancePdf: createSelector(selectState, fp.path('requestActOfAcceptancePdf')),
  selectProofOfPaymentPdf: createSelector(selectState, fp.path('requestProofOfPaymentPdf')),
  selectFilter,
  selectFilterByField: createSelector(
    selectFilter,
    (state) => (field: string) => fp.path(field, state)
  ),

  selectAdvancePercent: createSelector(selectState, fp.path('advancePercent')),
  selectAdvancePercentDemo: createSelector(selectState, fp.path('advancePercentDemo')),
  selectDeliveryLocations: createSelector(selectState, fp.path('deliveryLocations')),
  selectPaymentDataList: createSelector(selectState, fp.path('paymentDataList')),
  selectRepoRequestDataBrands: createSelector(selectState, fp.path('repoRequestDataBrands')),
  selectLastFinancingRepoRequestDataFinancingInfoResponse: createSelector(
    selectState,
    fp.path('lastFinancingRepoRequestDataFinancingInfoResponse')
  ),
  selectRepoRequestDataModels: createSelector(selectState, fp.path('repoRequestDataModels')),
  selectCurrentLimitAmount: createSelector(selectState, fp.path('currentLimitAmount')),
  selectRepoFiles: createSelector(selectState, fp.path('repoAttachments')),
  selectOperationDataId: createSelector(selectState, fp.path('operationDataId')),
  selectRepoAttachments: createSelector(selectState, fp.path('repoAttachments')),
  selectUploadedRepoAttachments: createSelector(selectState, fp.path('uploadedRepoAttachments')),
  selectGeneratedRepoAttachments: createSelector(selectState, fp.path('generatedRepoAttachments')),
  selectIsLimitRequestChangeNeed: createSelector(selectState, fp.path('isLimitRequestChangeNeed')),
  selectIsRepoApplyCompleted: createSelector(selectState, fp.path('isRepoApplyCompleted')),
  selectIsOperationHasRecords: createSelector(selectState, fp.path('isOperationHasRecords')),
  selectSupplierDocDateStatus: createSelector(selectState, fp.path('supplierDocDateStatus')),
  selectSupplierDocDateAddPercent: createSelector(
    selectState,
    fp.path('supplierDocDateAddPercent')
  ),
  selectFormPrefilling: createSelector(selectState, fp.path('formPrefilling')),
  selectCurrentRepoCodeType: createSelector(selectState, fp.path('currentRepoCodeType')),
  selectErrorListByField: createSelector(selectState, (state) => (field: string) => {
    return fp.pathOr([], ['errorList', field], state);
  }),
};

export default selectors;
