import Change from '@rfb/ui-kit/lib/icons/Change';
import Remove from '@rfb/ui-kit/lib/icons/Remove';
import { IFinancialState } from 'modules/financial-block/store';
import React from 'react';
import styles from './assets/styles/index.module.css';

import cn from 'classnames';

import { convertPathUsingParams, DealerSystems } from '@rfb/common';
import routingConfig from 'configs/routing';
import { FinancingRequestDTO } from 'dto/rf-info';
import notification from 'utils/notification';

export interface FTButtonCellProps {
  filter: IFinancialState['filter'];
  record: FinancingRequestDTO;
}

export interface FTButtonCellExternal {
  history: any;
  requestDataId: number;
  brand_type: string;
  state?: string;
}

export interface FTButtonCellActions {
  actions: {
    setForm: Function;
    getRequestData: Function;
    deleteRequestData: Function;
  };
}

const FTButtonCell = ({
  actions,
  ...props
}: FTButtonCellProps & FTButtonCellActions & FTButtonCellExternal) => {
  const getDocState = props.state === 'DOC';

  return (
    <div>
      <span
        onClick={() => {
          if (!getDocState) {
            actions.setForm({
              requestDataId: props.record.request_data_id,
              requestAmount: props.record.request_amount,
              requestVin: props.record.request_vin,
              requestCode: props.record.request_code,
              requestBrand: props.record.request_brand,
              requestInvoiceFileName: props.record.request_invoice_file_name,
            });
            props.history.push(
              convertPathUsingParams(routingConfig.financialBlockFTEdit.path, {
                requestDataId: props.requestDataId,
                system: props.history.location.pathname.includes('/rf')
                  ? DealerSystems.RUSFINANCE
                  : DealerSystems.ROSBANK_AVTO,
              }) + props.history.location.search
            );
          }
        }}
      >
        <Change className={cn(styles.icon, { [styles.iconDisabled]: getDocState })} />
      </span>
      <span
        style={{ marginLeft: '12px' }}
        onClick={() => {
          if (!getDocState) {
            notification.confirm('Вы уверены, что хотите удалить запись', () => {
              actions.deleteRequestData({
                requestDataId: props.requestDataId,
                onSuccess: () => actions.getRequestData(props.filter),
              });
            });
          }
        }}
      >
        <Remove className={cn(styles.icon, { [styles.iconDisabled]: getDocState })} />
      </span>
    </div>
  );
};

export default FTButtonCell;
