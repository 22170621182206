import { connect } from 'react-redux';

import routingConfig from 'configs/routing';

import Component, { IInformationNavigationProps } from './component';
import storage from '../../../../utils/storage';
import { readOnlyRoleKey } from '../../../app/store/sagas';

const mapStateToProps = (): IInformationNavigationProps => {
  const isReadOnly: boolean = storage.get(readOnlyRoleKey);

  const props = {
    config: [
      {
        link: routingConfig.contacts.path,
        text: 'Контакты',
      },
      {
        link: routingConfig.reminder.path,
        text: 'Памятка по взаимодействию',
      },
      {
        link: routingConfig.tariffs.path,
        text: 'Тарифы',
      },
    ],
  };

  !isReadOnly &&
    props.config.push({
      link: routingConfig.feedback.path,
      text: 'Обратная связь',
      // @ts-ignore
      isRed: true,
    });

  return props;
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
