var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var DocFile = function (props) { return (React.createElement("svg", __assign({}, props, { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M22 23.5V11H16V5H10V11H8.5V3.5H17.7476L23.5 9.5V25H8.5V22H10V23.5H22ZM17.5 5.40924L21.422 9.5H17.5V5.40924Z", fill: "currentColor" }),
    React.createElement("path", { d: "M7.94733 16.4C7.94733 16.832 7.83733 17.158 7.61733 17.378C7.40133 17.598 7.08333 17.708 6.66333 17.708H5.99133V15.092H6.66333C7.08333 15.092 7.40133 15.202 7.61733 15.422C7.83733 15.642 7.94733 15.968 7.94733 16.4Z", fill: "currentColor" }),
    React.createElement("path", { d: "M13.3305 16.4C13.3305 16.788 13.2165 17.11 12.9885 17.366C12.7605 17.622 12.4385 17.75 12.0225 17.75C11.6025 17.75 11.2785 17.622 11.0505 17.366C10.8225 17.11 10.7085 16.788 10.7085 16.4C10.7085 16.012 10.8225 15.69 11.0505 15.434C11.2785 15.178 11.6025 15.05 12.0225 15.05C12.4385 15.05 12.7605 15.178 12.9885 15.434C13.2165 15.69 13.3305 16.012 13.3305 16.4Z", fill: "currentColor" }),
    React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M20 12.5H4V20.5H20V12.5ZM6.66333 14.3H5.09133V18.5H6.66333C7.38333 18.5 7.92933 18.31 8.30133 17.93C8.67733 17.546 8.86533 17.036 8.86533 16.4C8.86533 15.764 8.67733 15.256 8.30133 14.876C7.92933 14.492 7.38333 14.3 6.66333 14.3ZM9.79045 16.4C9.79045 16.688 9.83645 16.962 9.92845 17.222C10.0245 17.482 10.1625 17.714 10.3425 17.918C10.5265 18.122 10.7625 18.284 11.0505 18.404C11.3385 18.524 11.6625 18.584 12.0225 18.584C12.3825 18.584 12.7065 18.524 12.9945 18.404C13.2825 18.284 13.5165 18.122 13.6965 17.918C13.8805 17.714 14.0185 17.482 14.1105 17.222C14.2065 16.962 14.2545 16.688 14.2545 16.4C14.2545 16.112 14.2065 15.838 14.1105 15.578C14.0185 15.318 13.8805 15.086 13.6965 14.882C13.5165 14.678 13.2825 14.516 12.9945 14.396C12.7065 14.276 12.3825 14.216 12.0225 14.216C11.6625 14.216 11.3385 14.276 11.0505 14.396C10.7625 14.516 10.5265 14.678 10.3425 14.882C10.1625 15.086 10.0245 15.318 9.92845 15.578C9.83645 15.838 9.79045 16.112 9.79045 16.4ZM15.3609 15.458C15.2329 15.75 15.1689 16.08 15.1689 16.448C15.1689 16.8 15.2329 17.116 15.3609 17.396C15.4929 17.676 15.6669 17.9 15.8829 18.068C16.1029 18.236 16.3449 18.364 16.6089 18.452C16.8729 18.54 17.1489 18.584 17.4369 18.584C17.8769 18.584 18.2829 18.494 18.6549 18.314V17.432C18.3989 17.664 18.0389 17.78 17.5749 17.78C17.3869 17.78 17.2049 17.754 17.0289 17.702C16.8569 17.646 16.6989 17.566 16.5549 17.462C16.4149 17.358 16.3029 17.22 16.2189 17.048C16.1349 16.872 16.0929 16.672 16.0929 16.448C16.0929 15.976 16.2369 15.62 16.5249 15.38C16.8169 15.136 17.1669 15.014 17.5749 15.014C17.9909 15.014 18.3409 15.124 18.6249 15.344V14.486C18.2649 14.306 17.8649 14.216 17.4249 14.216C17.1369 14.216 16.8609 14.262 16.5969 14.354C16.3329 14.442 16.0929 14.574 15.8769 14.75C15.6649 14.926 15.4929 15.162 15.3609 15.458Z", fill: "currentColor" }))); };
export default DocFile;
