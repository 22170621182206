import { rest } from 'msw';
import { securitySendConfirmSms } from './securitySendConfirmSms';

export let isSmsConfirmed = false;

export const securitySendConfirmSmsHandlers = [
  rest.post(`${process.env.REACT_APP_API_URL}/security/send-confirm-sms`, (req, res, ctx) => {
    console.log('msw-handlers: POST /security/send-confirm-sms');
    isSmsConfirmed = true;
    return res(ctx.status(200), ctx.json(securitySendConfirmSms));
  }),
];
