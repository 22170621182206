import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { TAttachment } from 'dto/operation-data';
import { actions } from 'modules/operation-data/store';
import React from 'react';
import { useDispatch } from 'react-redux';
import { LinkButton } from '../link-button';
import DropDownConfig from './DropDownConfig';

interface TMenuElement {
  title: string;
  file_id: string;
}
interface DropDownCellProps {
  items: TMenuElement[];
}
interface AttachmentCellProps {
  items: TAttachment[];
}

export const DropDownCell = (props: DropDownCellProps) => {
  return (
    <DropDownConfig
      config={[
        {
          title: props.items.length
            ? `${props.items[0]}` + (props.items.length > 1 ? ', ...' : '')
            : '',
          items: props.items.map((item) => ({ title: item.title, file_id: item.file_id })),
        },
      ]}
    />
  );
};

const renderItem = (item: TAttachment, dispatch: TODO_ANY) => {
  return (
    <div style={{ marginTop: 5, marginBottom: 5 }}>
      <LinkButton
        onClick={() =>
          dispatch(actions.documentumFileDownload({ id: item.file_id, filename: item.file_name }))
        }
      >
        {item.file_name}
      </LinkButton>
    </div>
  );
};

export const AttachmentCell = (props: AttachmentCellProps) => {
  const dispatch = useDispatch();
  return <>{props.items.map((item) => renderItem(item, dispatch))}</>;
};
