import React from 'react';

import { History, AuthTemplate } from '@rfb/ui-kit';
import AuthLoginFirst from 'modules/auth/components/LoginFirst';
import routingConfig from 'configs/routing';
import styles from './assets/styles/index.module.css';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { actions } from 'modules/auth/store';
import { useDispatch } from 'react-redux';

const LoginFirst = (props: TODO_ANY) => {
  const dispatch = useDispatch();
  const onBackClick = () => {
    dispatch(actions.chooseDealer({
      data: { diasoft_id: '' },
      onSuccess: () => {
        window.location.replace(routingConfig.login.path);
      },
    }));
  };

  const renderContent = (): JSX.Element => (
    <div className={styles.content}>
      <AuthLoginFirst history={props.history} />
    </div>
  );

  const renderLeftSidebar = (): JSX.Element => (
    <div onClick={onBackClick}>
      <History type="back" to={routingConfig.login.path} />
    </div>
  );

  return <AuthTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default LoginFirst;
