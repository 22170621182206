export enum ClientLineStatus {
  OPENED = '0',
  CLOSED = '1',
  ALL = '2'
}

export const clientLineStatusOptions: {[key: string]: string} = {
  [ClientLineStatus.OPENED]: 'Открытый',
  [ClientLineStatus.CLOSED]: 'Закрытый',
}
