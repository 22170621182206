import { put, takeEvery } from 'redux-saga/effects';

import api from 'utils/api';
import new_api from 'utils/new-api';
import storage from 'utils/storage';
import storageDB from 'utils/storage-db';

import { convertApiErrorCodesToMessages } from 'helpers/app';

import apiEndpoints from 'configs/api/endpoints';
import routingConfig from 'configs/routing';

import { actions as appActions } from 'modules/app/store';
import { actions, dealerSystemsKey } from './index';

import { IDTOTokenCreateResponse } from 'dto/auth';
import { IDTOVariableAgreementResponse } from 'dto/variable';

import { ApiErrors } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { DTOUserInfo } from 'dto/rf-info';

function* login(action: TODO_ANY) {
  try {
    yield put(actions.set({ isSending: true }));
    const result: { data: IDTOTokenCreateResponse } = yield new_api.post(
      apiEndpoints.auth.tokenCreate,
      action.payload.data
    );
    yield put(
      actions.loginSuccessful({
        token: result.data.token,
        email: action.payload.data.login,
        history: action.payload.history,
      })
    );
  } catch (error) {
    yield put(actions.loginFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isSending: false }));
  }
}

function* loginSuccessful(action: TODO_ANY) {
  (storage as TODO_ANY).set('token', action.payload.token);
  storage.set('email', action.payload.email);
  yield put(actions.set({ token: action.payload.token }));
  action.payload.history.push(routingConfig.phoneNumberValidation.path);
}

function* loginFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

function* logout() {
  try {
    yield api.post(apiEndpoints.auth.tokenDestroy, {});
    yield put(actions.logoutSuccessful({}));
  } catch (error) {
    yield put(actions.logoutFailure(error.response?.data));
  }
}

function* logoutSuccessful() {
  storage.clearAll();
  storageDB.clearAll();
  window.location.replace(routingConfig.login.path);
  yield;
}

function* logoutFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

function* sendDealerData(action: TODO_ANY) {
  try {
    yield new_api.post(apiEndpoints.profile.validate, action.payload.data);
    yield put(actions.sendDealerDataSuccessful({ history: action.payload.history }));
  } catch (error) {
    yield put(actions.sendDealerDataFailure(error.response?.data));
  }
}

function* sendDealerDataSuccessful(action: TODO_ANY) {
  action.payload.history.push(routingConfig.main.path);
  yield put(appActions.set({ apiErrorCode: 0 }));
}

function* sendDealerDataFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

function* sendPhoneNumber(action: TODO_ANY) {
  try {
    yield new_api.post(apiEndpoints.profile.phoneNumber, action.payload.data);
    yield put(actions.sendPhoneNumberSuccessful({ history: action.payload.history }));
  } catch (error) {
    yield put(actions.sendPhoneNumberFailure(error.response?.data));
  }
}

function* sendPhoneNumberSuccessful(action: TODO_ANY) {
  action.payload.history.push(routingConfig.smsLoginConfirm.path);
  yield put(appActions.set({ apiErrorCode: 0 }));
}

function* sendPhoneNumberFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

function* restorePassword(action: TODO_ANY) {
  try {
    yield put(actions.set({ isSending: true }));
    yield api.post(apiEndpoints.security.passwordReset, action.payload.data);
    yield put(
      actions.restorePasswordSuccessful({
        data: action.payload.data,
        history: action.payload.history,
      })
    );
  } catch (error) {
    yield put(actions.restorePasswordFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isSending: false }));
  }
}

function* restorePasswordSuccessful(action: TODO_ANY) {
  action.payload.history.push(routingConfig.passwordRestoreSent.path);
  storage.set('restore-password-email', action.payload.data.login);
  yield put(actions.set({ isPasswordRestoreSent: true }));
}

function* restorePasswordFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

function* changePassword(action: TODO_ANY) {
  try {
    yield api.post(apiEndpoints.security.passwordChange, action.payload.data);
    yield put(actions.changePasswordSuccessful({ history: action.payload.history }));
  } catch (error) {
    yield put(actions.changePasswordFailure(error.response?.data));
  }
}

function* changePasswordSuccessful(action: TODO_ANY) {
  action.payload.history.push(routingConfig.main.path);
  yield put(appActions.set({ apiErrorCode: 0 }));
}

function* changePasswordFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

function* getServiceTermsText() {
  try {
    const result: { data: IDTOVariableAgreementResponse } = yield api.get(
      apiEndpoints.variable.agreement
    );
    yield put(actions.getServiceTermsTextSuccessful(result.data.text));
  } catch (error) {
    yield put(actions.getServiceTermsTextFailure(error.response?.data));
  }
}

function* getServiceTermsTextSuccessful(action: TODO_ANY) {
  yield put(actions.set({ serviceTermsText: action.payload }));
}

function* getServiceTermsTextFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

function* sendServiceTermsAgreement(action: TODO_ANY) {
  try {
    yield new_api.post(apiEndpoints.profile.agreement, {});
    yield put(
      actions.sendServiceTermsAgreementSuccessful({
        history: action.payload.history,
      })
    );
  } catch (error) {
    yield put(actions.sendServiceTermsAgreementFailure(error.response?.data));
  }
}

function* sendServiceTermsAgreementSuccessful(action: TODO_ANY) {
  action.payload.history.push(routingConfig.main.path);
  yield put(appActions.set({ apiErrorCode: 0 }));
}

function* sendServiceTermsAgreementFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

function* disagreeServiceTerms(action: TODO_ANY) {
  storage.remove('token');
  action.payload.history.push(routingConfig.login.path);
  yield put(appActions.set({ apiErrorCode: 0 }));
}

function* getUserDealer(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    const result: { data: DTOUserInfo } = yield new_api.get(apiEndpoints.rfInfo.user);
    yield put(actions.getUserDealerSuccessful({ ...action.payload, data: result.data }));
  } catch (error) {
    yield put(actions.getUserDealerFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getUserDealerSuccessful(action: TODO_ANY) {
  yield put(actions.set({ userDealers: action.payload.data }));
  if (action.payload.autoChooseDealer && action.payload.data?.length === 1) {
    const data = {
      diasoft_id: action.payload.data[0].diasoft_id,
      dealerName: action.payload.data[0].name,
    };
    yield put(
      actions.chooseDealer({
        data,
        onSuccess: () => {
          action.payload.history.push(routingConfig.main.path);
        },
      })
    );
  }
}

function* getUserDealerFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

function* chooseDealer(action: TODO_ANY) {
  console.log(`//sagas/chooseDealer BEGIN: action.payload = ${JSON.stringify(action.payload)}`);
  try {
    yield put(actions.set({ isLoading: true }));
    yield new_api.post(apiEndpoints.profile.chooseDealer, {
      diasoft_id: action.payload.data.diasoft_id,
    });
    if (action.payload.data.diasoft_id.length > 0) {
      // Если это не сброс дилера
      storage.set('dealerName', action.payload.data.dealerName);
      const dealerSystemResponse = yield new_api.get(apiEndpoints.profile.dealerSystem, {
        diasoft_id: action.payload.data.diasoft_id,
      });
      console.log(
        `//sagas/chooseDealer: dealerSystemResponse = ${JSON.stringify(dealerSystemResponse)}`
      );
      if (dealerSystemResponse.data?.exception?.code === ApiErrors.SERVER_TEMPORARILY_UNAVAILABLE) {
        yield put(actions.chooseDealerFailure({ code: ApiErrors.SERVER_TEMPORARILY_UNAVAILABLE }));
      }
      storage.set(dealerSystemsKey, dealerSystemResponse.data); // TODO Проверить, нужно ли это ещё
    }
    yield put(
      actions.chooseDealerSuccessful({
        choosePayload: action.payload,
      })
    );
  } catch (error) {
    console.log(`//sagas/chooseDealer ERROR: ${JSON.stringify(error)}`);
    if (error.response.data.code === ApiErrors.SERVICE_TERMS_ERROR) {
      yield put(appActions.set({ apiErrorCode: ApiErrors.SERVICE_TERMS_ERROR }));
      action.payload.onSuccess();
      return;
    }
    if (error.message === 'Network Error') {
      // TODO Излишне ?
      yield put(actions.setError({ api: ['Ошибка сети'] }));
      return;
    }
    yield put(actions.chooseDealerFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
  console.log(`//sagas/chooseDealer END`);
}

function* chooseDealerSuccessful(action: TODO_ANY) {
  if (action.payload.choosePayload.onSuccess) {
    action.payload.choosePayload.onSuccess();
  }
  yield put(appActions.set({ apiErrorCode: 0 }));
}

function* chooseDealerFailure(action: TODO_ANY) {
  const code = action.payload[0] ? action.payload[0].code : action.payload.code; // TODO Странные варианты - разобраться
  if (code === ApiErrors.RF_INFO_VALIDATION_ERROR) {
    yield put(appActions.set({ apiErrorCode: action.payload[0]?.code }));
  } else {
    yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
    storage.remove('dealerName');
  }
}

const sagas = function* () {
  yield takeEvery(actions.login, login);
  yield takeEvery(actions.loginSuccessful, loginSuccessful);
  yield takeEvery(actions.loginFailure, loginFailure);

  yield takeEvery(actions.logout, logout);
  yield takeEvery(actions.logoutSuccessful, logoutSuccessful);
  yield takeEvery(actions.logoutFailure, logoutFailure);

  yield takeEvery(actions.sendDealerData, sendDealerData);
  yield takeEvery(actions.sendDealerDataSuccessful, sendDealerDataSuccessful);
  yield takeEvery(actions.sendDealerDataFailure, sendDealerDataFailure);

  yield takeEvery(actions.sendPhoneNumber, sendPhoneNumber);
  yield takeEvery(actions.sendPhoneNumberSuccessful, sendPhoneNumberSuccessful);
  yield takeEvery(actions.sendPhoneNumberFailure, sendPhoneNumberFailure);

  yield takeEvery(actions.restorePassword, restorePassword);
  yield takeEvery(actions.restorePasswordSuccessful, restorePasswordSuccessful);
  yield takeEvery(actions.restorePasswordFailure, restorePasswordFailure);

  yield takeEvery(actions.getServiceTermsText, getServiceTermsText);
  yield takeEvery(actions.getServiceTermsTextSuccessful, getServiceTermsTextSuccessful);
  yield takeEvery(actions.getServiceTermsTextFailure, getServiceTermsTextFailure);

  yield takeEvery(actions.sendServiceTermsAgreement, sendServiceTermsAgreement);
  yield takeEvery(actions.sendServiceTermsAgreementSuccessful, sendServiceTermsAgreementSuccessful);
  yield takeEvery(actions.sendDealerDataFailure, sendServiceTermsAgreementFailure);

  yield takeEvery(actions.changePassword, changePassword);
  yield takeEvery(actions.changePasswordSuccessful, changePasswordSuccessful);
  yield takeEvery(actions.changePasswordFailure, changePasswordFailure);

  yield takeEvery(actions.disagreeServiceTerms, disagreeServiceTerms);

  yield takeEvery(actions.chooseDealer, chooseDealer);
  yield takeEvery(actions.chooseDealerSuccessful, chooseDealerSuccessful);
  yield takeEvery(actions.chooseDealerFailure, chooseDealerFailure);

  yield takeEvery(actions.getUserDealer, getUserDealer);
  yield takeEvery(actions.getUserDealerSuccessful, getUserDealerSuccessful);
  yield takeEvery(actions.getUserDealerFailure, getUserDealerFailure);
};
export default sagas;
