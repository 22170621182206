import { createSelector } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { name } from './index';

export const selectState = fp.path(name);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  selectHelpText: createSelector(selectState, fp.path('helpText')),
  selectAppMenuConfig: createSelector(selectState, fp.path('appMenuConfig')),
  selectApiErrorCode: createSelector(selectState, fp.path('apiErrorCode')),
  selectIsAppInit: createSelector(selectState, fp.path('isAppInit')),
  selectIsLoading: createSelector(selectState, fp.path('isLoading')),
  selectRequestCount: createSelector(selectState, fp.path('requestCount')),
};
