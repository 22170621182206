import React, { useEffect } from 'react';
import { Condition, SystemNavigation } from '@rfb/ui-kit';
import history from 'utils/history';
import { DealerSystems, IDealerSystemConfig } from '@rfb/common';
import { useLocation } from 'react-router-dom';
import styles from './styles/index.module.css';

export interface ISystemChoiceProps {
  dealerSystemConfig: IDealerSystemConfig;
  dealerSystemActive?: DealerSystems;
  twoSystemCaption: string;
  oneSystemCaptionTemplate: string;
  rbRenderContent: React.ReactElement | React.ReactElement[];
  rfRenderContent: React.ReactElement | React.ReactElement[];
  rbButtonName?: string;
  rfButtonName?: string;
  oneSystemPath: string;
  twoSystemPath: string;
  isTwoButtonNeeded?: () => boolean;
  selectedSystem?: string;
}

export interface ISystemChoiceActions {
  actions: {
    set: Function;
  };
}

const SystemChoice = (props: ISystemChoiceProps & ISystemChoiceActions) => {
  const location = useLocation();
  const dealerSystemConfig = props.dealerSystemConfig;

  useEffect(() => {
    if (location.pathname.includes('two-system')) {
      setSelectedSystem('');
    }
  }, [location]);

  function setSelectedSystem(system: DealerSystems | '') {
    props.actions.set({ selectedSystem: system });
  }
  const selectedSystem = props.selectedSystem;

  const onSelect = (system: DealerSystems): void => {
    setSelectedSystem(system);
    history.push(props.oneSystemPath);
  };

  const isTwoButtonNeededDefault = () =>
    !props.dealerSystemActive &&
    dealerSystemConfig.rb_flag === 1 &&
    dealerSystemConfig.rf_flag === 1;

  const isTwoButtonNeeded = props.isTwoButtonNeeded ?? isTwoButtonNeededDefault;

  function isTwoButtonShow() {
    return isTwoButtonNeeded() && !selectedSystem;
  }

  if (isTwoButtonShow()) {
    if (!location.pathname.includes('two-system')) {
      history.push(props.twoSystemPath);
    }
  } else if (!selectedSystem) {
    onSelect(
      props.dealerSystemActive ??
        (dealerSystemConfig.rb_flag === 1 ? DealerSystems.ROSBANK_AVTO : DealerSystems.RUSFINANCE)
    );
  }

  return (
    <div>
      <Condition value={isTwoButtonShow()}>
        <h1 className={styles.title}>{props.twoSystemCaption}</h1>
      </Condition>
      <Condition value={!isTwoButtonShow()}>
        <h1 className={styles.title}>
          {props.oneSystemCaptionTemplate}{' '}
          {selectedSystem === DealerSystems.ROSBANK_AVTO ? 'РОСБАНК АВТО' : 'РУСФИНАНС'}
        </h1>
      </Condition>
      <Condition value={selectedSystem === DealerSystems.ROSBANK_AVTO}>
        {props.rbRenderContent}
      </Condition>
      <Condition value={selectedSystem === DealerSystems.RUSFINANCE}>
        {props.rfRenderContent}
      </Condition>
      <Condition value={isTwoButtonShow()}>
        <SystemNavigation
          rb_name={props.rbButtonName ?? 'РОСБАНК АВТО'}
          rf_name={props.rfButtonName ?? 'РУСФИНАНС'}
          onSelect={onSelect}
        />
      </Condition>
    </div>
  );
};

export default SystemChoice;
