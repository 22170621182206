import { put, takeEvery } from 'redux-saga/effects';

import fp from 'lodash/fp';

import new_api from 'utils/new-api';

import apiEndpoints from 'configs/api/endpoints';

import { actions } from './index';

import {
  IDTORFInfoClientLineFullResponse,
  IDTORFInfoClientLineShortResponse,
  IDTORFInfoHeaders,
  IDTORFInfoRateResponse,
} from 'dto/rf-info';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { convertApiErrorCodesToMessages } from '../../../helpers/app';

function* getDataShort(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));
    const url: string =
      apiEndpoints.rfInfo.clientLines +
      `?line_status=${action.payload.status}&page_number=${action.payload.page}`;
    const result: {
      data: IDTORFInfoClientLineShortResponse;
      headers: IDTORFInfoHeaders;
    } = yield new_api.get(url);
    yield put(actions.getDataShortSuccessful({ data: result.data, headers: result.headers }));
  } catch (error) {
    yield put(actions.getDataShortFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getDataShortSuccessful(action: TODO_ANY) {
  yield put(actions.setData(action.payload.data.records));
  yield put(
    actions.set({ pageCount: fp.toNumber(action.payload.headers['x-pagination-page-count']) })
  );
  if (action.payload.data.exception) {
    yield put(
      actions.setError({ api: convertApiErrorCodesToMessages(action.payload.data.exception) })
    );
  }
}

function* getDataShortFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

function* getDataFullById(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));
    const url: string =
      apiEndpoints.rfInfo.clientLine +
      `?number_line=${action.payload.number}&type_line=${action.payload.type}&multi_brand=${action.payload.multiBrand}`;
    const result: { data: IDTORFInfoClientLineFullResponse } = yield new_api.get(url);
    const rate: { data: IDTORFInfoRateResponse[] } = yield new_api.get(apiEndpoints.rfInfo.rate);
    yield put(actions.getDataFullByIdSuccessful(result.data));
    yield put(actions.set({ rate: fp.head(rate.data) }));
  } catch (error) {
    yield put(actions.getDataFullByIdFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getDataFullByIdSuccessful(action: TODO_ANY) {
  yield put(actions.setDataFull({ [action.payload.number]: action.payload }));
}

function* getDataFullByIdFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload?.message }));
}

export default function* () {
  yield takeEvery(actions.getDataShort, getDataShort);
  yield takeEvery(actions.getDataShortSuccessful, getDataShortSuccessful);
  yield takeEvery(actions.getDataShortFailure, getDataShortFailure);

  yield takeEvery(actions.getDataFullById, getDataFullById);
  yield takeEvery(actions.getDataFullByIdSuccessful, getDataFullByIdSuccessful);
  yield takeEvery(actions.getDataFullByIdFailure, getDataFullByIdFailure);
}
