import React, { useEffect } from 'react';

import fp from 'lodash/fp';

import { Button, Condition, Form, Input, MessageList } from '@rfb/ui-kit';

import { configureValidator, runRulesChain } from 'helpers/validation';

import { phoneRules } from '../../configs/validation';

import styles from './assets/styles/index.module.css';
import { DTOPhoneValidationRequest } from 'dto/phone';

export interface IAuthPhoneNumberValidationProps {
  email: string;
  phoneNumber: string;
  phoneNumberErrorList: string[];
  apiErrorList: string[];
}

export interface IAuthPhoneNumberValidationPropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void;
  };
}

export interface IAuthPhoneNumberValidationActions {
  actions: {
    set: Function;
    setError: Function;
    reset: Function;
    sendPhoneNumber: Function;
  };
}

const PhoneNumberValidation = (
  props: IAuthPhoneNumberValidationProps &
    IAuthPhoneNumberValidationPropsExternal &
    IAuthPhoneNumberValidationActions
) => {
  useEffect(() => {
    return () => {
      props.actions.reset();
    };
    // ESLINT Необходимо выполнение только в момент размонтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validators = {
    phone: configureValidator({
      name: 'phone',
      rule: phoneRules,
      setError: props.actions.setError,
    }),
  };

  const onPhoneNumberChange = (phoneNumber: string): void => {
    validators.phone(phoneNumber).finally((): void => props.actions.set({ phoneNumber }));
  };
  const onFormSubmit = (): void => {
    const data: DTOPhoneValidationRequest = { phone_number: props.phoneNumber };
    const rules = [validators.phone(data.phone_number)];
    runRulesChain(rules).then(() =>
      props.actions.sendPhoneNumber({ data, history: props.history })
    );
  };

  return (
    <div className={styles.main}>
      <h2 className={styles.title}>
        <div>
          Вы вошли как <span className={styles.email}>{props.email}</span>
        </div>
        <div>введите Номер мобильного телефона для получения Кода подтверждения</div>
      </h2>

      <Form onSubmit={onFormSubmit}>
        <Input
          type="phone"
          wrapperClassName={styles.phoneNumber}
          label="Номер телефона"
          value={props.phoneNumber}
          onChange={onPhoneNumberChange}
        />
        <MessageList type="error" messages={props.phoneNumberErrorList} />

        <Condition value={!fp.isEmpty(props.apiErrorList)}>
          <MessageList type="error" messages={props.apiErrorList} />
        </Condition>

        <Button wrapperClassName={styles.button} type="submit" text="Продолжить" />
      </Form>
    </div>
  );
};

export default PhoneNumberValidation;
