export const download = (response: { data: any, headers: any }, filename: string): void => {
  const blob = new Blob([response.data], { type: response.headers['content-type'] });
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(blob);
  anchor.download = filename;
  document.body.appendChild(anchor); //For FF
  anchor.target = '_blank';
  anchor.click();
  document.body.removeChild(anchor);
};
