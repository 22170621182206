import React from 'react';
import cn from 'classnames';
import styles from './assets/styles/index.module.css';

interface LinkButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
}

export const LinkButton = ({ className, ...props }: LinkButtonProps) => {
  return (
    <a
      className={cn(
        {
          [styles.link]: true,
        },
        className
      )}
      {...props}
    >
      {props.children}
    </a>
  );
};
