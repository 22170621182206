import React from 'react';
import AppFullWidthTemplate from 'templates/AppFullWidthTemplate';
import InformationNavigation from 'modules/information/components/Navigation';
import styles from './assets/styles/index.module.css';

const Tariffs = () => {
  const renderContent = () => (
    <div className={styles.main}>
      <h1 className={styles.title}>Информация</h1>

      <div className={styles.navigation}>
        <InformationNavigation />
      </div>
    </div>
  );

  return <AppFullWidthTemplate content={renderContent()} />;
};

export default Tariffs;
