import React, { useEffect } from 'react';

import cn from 'classnames';
import fp from 'lodash/fp';

import styles from './assets/styles/index.module.css';

export interface IClientLineDetailData {
  finishDate: string;
  limitAmount: string;
  namePercentRate: string;
  percentRate: string;
  percentRateDate: string;
  commissionTypeList: string[];
  mainDebtTotal: string;
  restLimit: string;
  commissionDebtSumm: string;
  linePayDateNear: string;
  isOverdueDebt: boolean;
  isLineBlock: boolean;
  isFlagNearLinePay: boolean;
}

const Data = (props: IClientLineDetailData) => {
  // Костыль для борьбы с кешированием поля 'Комиссия по договору'
  useEffect(() => {
    const place = 'ClientLineData';
    const key = `is${place}Reloaded`;

    const isReloaded = sessionStorage.getItem(key);
    if (!isReloaded) {
      sessionStorage.setItem(key, 'true');
    }
    return () => {
      sessionStorage.removeItem(key);
    };
  }, []);

  const renderValue = (value: string, extraClassName?: string) => (
    <div key={value} className={cn([styles.value, extraClassName])}>
      {value}
    </div>
  );
  const renderItem = (title: string, value: string | string[], extraClassName?: string) => (
    <div className={styles.item}>
      <div className={styles.title}>{title}</div>
      <div className={styles.valueWrapper}>
        {fp.map((value) => renderValue(value, extraClassName), fp.flatten([value]))}
      </div>
    </div>
  );

  // Специальная отрисовка - обход проблемы кэша
  const renderComissionItem = (title: string, value: string) => (
    <div className={styles.item}>
      <div className={styles.title}>{title}</div>
      <div className={styles.valueWrapper}>
        <div className={styles.value}>{value}</div>
      </div>
    </div>
  );

  return (
    <div className={styles.main}>
      <div className={styles.sidebar}>
        {renderItem('Срок доступности лимита', props.finishDate)}
        {renderItem('Сумма установленного лимита', props.limitAmount)}
        {renderItem(
          'Шаблон процентной ставки по договору',
          `${props.namePercentRate} + ${props.percentRate}%`
        )}
        {renderItem('Дата установления шаблона', props.percentRateDate)}
        {/* {renderItem('Комиссии по договору', props.commissionTypeList)} */}
        {/* Специальная отрисовка - обход проблемы кэша */}
        {renderComissionItem('Комиссии по договору', props.commissionTypeList[0])}
      </div>

      <div className={styles.sidebar}>
        {renderItem('Общая сумма задолженности', props.mainDebtTotal)}
        {renderItem('Остаток неиспользованного лимита', props.restLimit)}
        {renderItem('Сумма задолженности по комиссии за ведение лимита', props.commissionDebtSumm)}
        {renderItem(
          'Ближайший срок оплаты',
          props.linePayDateNear,
          props.isFlagNearLinePay ? styles.valueAttention : ''
        )}
        {renderItem('Наличие просроченной задолженности', props.isOverdueDebt ? 'Да' : 'Нет')}
        {renderItem('Блокировка договора', props.isLineBlock ? 'Да' : 'Нет')}
      </div>
    </div>
  );
};

export default Data;
