import { trancheTypeTranslations } from '../../configs/type';
import {
  trancheStatus,
  TrancheStatus,
  TrancheStatusValue,
  trancheStatusValue,
} from '../../configs/status';
import { ClientLineTypes } from '../../../client-line/configs/type';

export const getTransformedNumberForView = (number: string, type: string): string =>
  `${ClientLineTypes.CC !== type ? number + '-' : ''}${trancheTypeTranslations[type]}`;

export const getTransformedStatus = (status: TrancheStatus): string => trancheStatusValue[status];

export const getStatusId = (statusValue: TrancheStatusValue): TrancheStatus => {
  return trancheStatus[statusValue];
};
