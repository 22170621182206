import React from 'react';

import fp from 'lodash/fp';

import { getTransformedAmount } from '@rfb/common';

import styles from './assets/styles/index.module.css';

export interface IAccountsBalanceProps {
  value?: string;
}

const Balance = (props: IAccountsBalanceProps) => {
  return (
    <div className={styles.balance}>
      <div className={styles.title}>Остаток на счёте</div>
      <div className={styles.value}>{getTransformedAmount(fp.toNumber(props.value))}</div>
    </div>
  );
};

export default Balance;
