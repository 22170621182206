import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import Component, {
  IDealerChoiceProps,
  IDealerChoiceActions,
  IDealerChoicePropsExternal,
} from './component';
import { name, actions, IAuthState, selectors } from 'modules/auth/store';
import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IAuthState },
  props: { history: TODO_ANY }
): IDealerChoiceProps & IDealerChoicePropsExternal => ({
  dealers: selectors.selectFilteredDealers(state),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): IDealerChoiceActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
