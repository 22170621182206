import { DealerSystems, convertPathUsingParams, getTransformedAmount } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import {
  Button,
  ButtonWithIcon,
  Condition,
  ITable,
  MessageList,
  NotificationConfirm,
  Table,
} from '@rfb/ui-kit';
import cn from 'classnames';
import apiEndpoints from 'configs/api/endpoints';
import routingConfig from 'configs/routing';
import { TRepoAttachment } from 'dto/operation-data';
import fp from 'lodash/fp';
import { getAutoType } from 'modules/financial-block/helpers';
import React, { SVGProps, useEffect, useState } from 'react';
import new_api from 'utils/new-api';
import notification from 'utils/notification';
import { IFinancialState, RepoAutoType } from '../../store';
import styles from './assets/styles/index.module.css';

// Устранить дублирование с admin/RequestEdit, UploadFormRepo
const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="22"
    height="12"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.09097 0L12 10.9091L10.9091 12L7.18178e-05 1.09091L1.09097 0Z" fill="currentColor" />
    <path
      d="M0 10.9091L10.909 1.83914e-07L11.9999 1.09091L1.0909 12L0 10.9091Z"
      fill="currentColor"
    />
  </svg>
);

export interface IFTTableRepoProps {
  headers: ITable['headers'];
  rows: ITable['rows'];
  pageCount: number;
  totalSum: number;
  advanceSum: number;
  filter: IFinancialState['filter'];
  isLoading: boolean;
  isFinancingRequestInProgress: boolean;
  apiErrorList: string[];
  isReadOnly: boolean;
  operationDataId: number;
  isLimitRequestChangeNeed: boolean;
  isRepoApplyCompleted: boolean;
  attachments: FileList;
  currentRepoCodeType: RepoAutoType;
  uploadedRepoAttachments: TRepoAttachment[];
  generatedRepoAttachments: TRepoAttachment[];
}

export interface IFTTableRepoExternal {
  history: TODO_ANY;
  brand_type?: string;
  state?: string;
  codeType?: string; // Из атрибута компонента
}

export interface IFTTableRepoActions {
  actions: {
    getRepoRequestData: Function;
    getRepoAttachments: Function;
    set: Function;
    setFilter: Function;
    saveAndSendForApproval: Function;
    deleteAttachment: Function;
    generateDocuments: Function;
    resetFormPrefilling: Function;
  };
}

export type TGeneratedFile = {
  fileTypeCode: string;
  downloadUrl: string;
  attachmentFileName: string;
};

const FTTableRepo = (props: IFTTableRepoProps & IFTTableRepoActions & IFTTableRepoExternal) => {
  let generatedFileTABName: string | null = null;
  let generatedFileENQName: string | null = null;
  let generatedFileTABdownloadUrl: string | null = null;
  let generatedFileENQdownloadUrl: string | null = null;
  if (props.generatedRepoAttachments.length > 0) {
    const fileTAB = props.generatedRepoAttachments.filter((item: TRepoAttachment) =>
      item.attachment_file_name.includes('Реестр')
    )[0];
    if (fileTAB) {
      const generatedFileTABAttachmentId = fileTAB.operation_attachment_id;
      generatedFileTABName = fileTAB.attachment_file_name;
      generatedFileTABdownloadUrl = `${convertPathUsingParams(
        apiEndpoints.financialBlock.getGeneratedFileDownload,
        {
          operationDataId: props.operationDataId,
          operationAttachmentId: generatedFileTABAttachmentId,
        }
      )}`;
    }

    const fileENQ = props.generatedRepoAttachments.filter((item: TRepoAttachment) =>
      item.attachment_file_name.includes('Запрос на заявку')
    )[0];
    if (fileENQ) {
      const generatedFileENQAttachmentId = fileENQ.operation_attachment_id;
      generatedFileENQName = fileENQ.attachment_file_name;
      generatedFileENQdownloadUrl = `${convertPathUsingParams(
        apiEndpoints.financialBlock.getGeneratedFileDownload,
        {
          operationDataId: props.operationDataId,
          operationAttachmentId: generatedFileENQAttachmentId,
        }
      )}`;
    }
  }

  const [isLimitRequestChangeNeedConfirmed, setLimitRequestChangeNeedConfirmed] = useState(false);

  useEffect(() => {
    let codeType: RepoAutoType = RepoAutoType.NONE;
    if (props.codeType === 'new') {
      codeType = RepoAutoType.NEW;
    } else if (props.codeType === 'demo') {
      codeType = RepoAutoType.DEMO;
    }
    if (props.currentRepoCodeType !== codeType) {
      // Ситуация для сброса
      props.actions.resetFormPrefilling();
      props.actions.set({ currentRepoCodeType: codeType });
    }

    // Для сброса нередактируемого состояния
    return () => {
      if (props.isRepoApplyCompleted) props.actions.set({ isRepoApplyCompleted: false });
    };
  }, []);

  useEffect(() => {
    if (!props.isRepoApplyCompleted) {
      props.actions.getRepoRequestData({
        repoRequestTypeCode: getAutoType(props.history).toUpperCase(),
        onSuccess: (operationDataId: number) => {
          if (operationDataId) {
            props.actions.getRepoAttachments({ operationDataId });
          }
        },
        ...props.filter,
      });
    }
  }, [props.filter, props.actions]);

  const onSortingChange = (sorting: ITable['sorting']): void =>
    props.actions.setFilter({ sorting });

  const onSuccess = () => {
    const url = convertPathUsingParams(routingConfig.financialBlockConfirmation.path, {
      system: DealerSystems.RUSFINANCE,
      type: getAutoType(props.history),
    });
    props.history.push(url);
  };

  function onDeleteAttachment(operationAttachmentId: number) {
    props.actions.deleteAttachment({
      operationDataId: props.operationDataId,
      operationAttachmentId,
      onSuccess: () => {
        props.actions.getRepoAttachments({ operationDataId: props.operationDataId });
      },
    });
  }

  async function handleGeneratedFileDownload(fileUrl: string, fileName: string) {
    const url = process.env.REACT_APP_NEW_API_URL! + fileUrl;
    const config = {
      responseType: 'blob',
    };
    try {
      const response = await new_api.get(url, config);
      // const fileUrl = window.URL.createObjectURL(response.data);
      // const attachmentWindow = window.open();
      // attachmentWindow!.location.href = fileUrl;
      // window.open(fileUrl, '_blank');
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);

      // link.addEventListener('click', event => {
      //   event.preventDefault();
      //   // Пытаемся открыть в новой вкладке
      //   window.open(link.href, '_blank');
      // }, false);

      link.target = '_blank';
      link.click();
      //window.open(fileUrl, '_blank');
    } catch (error) {
      notification.info('Документ недоступен. Попробуйте повторить попытку позднее');
    }
  }

  const areFilesGenerated =
    !!generatedFileTABdownloadUrl &&
    !!generatedFileENQdownloadUrl &&
    // Для ситуации удаления последней записи, когда уже не вызывается getRepoAttachments
    // TODO Можно ли обусловить отсутствием вложений?
    !!props.rows.length;

  const isSaveAndSendForApproveButtonDisabled =
    props.isLoading ||
    !fp.isEmpty(props.apiErrorList) ||
    !areFilesGenerated ||
    props.uploadedRepoAttachments.length === 0;

  return (
    <div className={props.isRepoApplyCompleted ? styles.mainDisabled : styles.main}>
      <Condition value={props.isLimitRequestChangeNeed && !isLimitRequestChangeNeedConfirmed}>
        <NotificationConfirm
          message="Превышена сумма доступного лимита, требуется корректировка запроса на заявку."
          buttonTextAgree="OK"
          onAgree={() => {
            setLimitRequestChangeNeedConfirmed(true);
          }}
        />
      </Condition>

      <div className={cn(styles.topBar, styles.flexCenter)}>
        {!props.isReadOnly && (
          <ButtonWithIcon
            type="add"
            text="Добавить автомобиль"
            isActive={!props.isLoading && !props.isFinancingRequestInProgress}
            onClick={() => {
              const url =
                convertPathUsingParams(routingConfig.financialBlockFTCreate.path, {
                  system: DealerSystems.RUSFINANCE,
                  type: getAutoType(props.history),
                }) + props.history.location.search;
              props.history.push(url);
            }}
          />
        )}

        <div className={styles.label}>
          Кол-во автомобилей:
          <div className={styles.text}>{props.rows.length}</div>
        </div>
        <div style={{ marginTop: '28px' }}>
          <div className={styles.label}>
            Итоговая сумма:
            <div className={styles.text}>{getTransformedAmount(props.totalSum)}</div>
          </div>
          <div className={styles.label}>
            Предварительная сумма аванса:
            <div className={styles.text}>{getTransformedAmount(props.advanceSum)}</div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {!props.isReadOnly && (
            <Button
              wrapperClassName={styles.attachButton}
              text="Вложить документы"
              isDisabled={
                props.isLoading ||
                !fp.isEmpty(props.apiErrorList) ||
                props.isFinancingRequestInProgress ||
                !(props.rows.length > 0)
              }
              onClick={() => {
                const url =
                  convertPathUsingParams(routingConfig.financialBlockUploadFormRepo.path, {
                    system: DealerSystems.RUSFINANCE,
                    type: getAutoType(props.history),
                  }) + props.history.location.search;
                props.history.push(url);
              }}
            />
          )}
          <div style={{ display: 'flex', flexDirection: 'row', marginRight: '20px' }}>
            <h2 style={{ marginRight: '40px' }}>Вложения:</h2>
            {props.rows.length > 0 && ( // TODO Убрать костыль: вложения должны корректно приходить из бека
              <div className={styles.fileContainer}>
                {props.uploadedRepoAttachments.map((attachment) => (
                  <div className={styles.attachment}>
                    <div style={{ color: 'gray' }}>{attachment.attachment_file_name}</div>
                    <Close
                      style={{ width: '40px', marginLeft: '5px' }}
                      className={styles.attachmentDeleteIcon}
                      onClick={() => onDeleteAttachment(attachment.operation_attachment_id)}
                    />
                    <br />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.main}>
        <Condition value={!fp.isEmpty(props.apiErrorList)}>
          <MessageList type="error" messages={props.apiErrorList} />
        </Condition>
        <Table
          headers={props.headers}
          rows={props.rows}
          sorting={props.filter.sorting}
          pageCount={props.pageCount}
          page={props.filter.page as number}
          isLoading={props.isLoading || props.isFinancingRequestInProgress}
          onSortingChange={onSortingChange}
          onPageChange={(page: number) => props.actions.setFilter({ page })}
        />
      </div>

      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          width: '1150px',
        }}
      >
        <Button
          wrapperClassName={styles.wizardButton}
          text="Сформировать документы по запросу"
          isDisabled={
            props.isLoading ||
            !fp.isEmpty(props.apiErrorList) ||
            !(props.rows.length > 0) ||
            areFilesGenerated
          }
          onClick={() => {
            props.actions.generateDocuments({
              operationDataId: props.operationDataId,
              onSuccess: () => {
                const result = props.actions.getRepoAttachments({
                  operationDataId: props.operationDataId,
                });
                return result;
              },
            });
          }}
        />
        {areFilesGenerated && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '50px',
            }}
          >
            <a
              href={generatedFileTABdownloadUrl!}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: 'gray',
              }}
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                handleGeneratedFileDownload(generatedFileTABdownloadUrl!, generatedFileTABName!);
              }}
            >
              Информация для платёжного реестра
            </a>
            <a
              href={generatedFileENQdownloadUrl!}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: 'gray',
              }}
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                handleGeneratedFileDownload(generatedFileENQdownloadUrl!, generatedFileENQName!);
              }}
            >
              Запрос на заявку на финансирование
            </a>
          </div>
        )}
        <Button
          wrapperClassName={styles.wizardButton}
          text="Сохранить и отправить на согласование"
          isDisabled={isSaveAndSendForApproveButtonDisabled}
          onClick={() => {
            props.actions.saveAndSendForApproval({
              operationDataId: props.operationDataId,
              onSuccess,
            });
          }}
        />
      </div>
    </div>
  );
};

export default FTTableRepo;
