import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { actions, IFinancialState, name, selectors } from '../../store';

import Component, {
  IAllCreateFormActions,
  IAllCreateFormProps,
  IAllCreateFormPropsExternal,
} from './component';

import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IFinancialState },
  props: IAllCreateFormPropsExternal
): IAllCreateFormPropsExternal & IAllCreateFormProps => {
  const form = selectors.selectForm(state);

  const result = {
    requestAmount: form.requestAmount,
    requestCode: form.requestCode,
    requestBrand: form.requestBrand,
    requestBrandId: form.requestBrandId,
    requestModel: form.requestModel,
    requestModelId: form.requestModelId,
    requestCarPassportDate: form.requestCarPassportDate,
    requestEngineDisplacement: form.requestEngineDisplacement,
    requestEnginePower: form.requestEnginePower,
    requestElectricalEnginePower: form.requestElectricalEnginePower,
    requestMileage: form.requestMileage,
    requestVin: form.requestVin,
    notVin: !!form.notVin ? true : false, // TODO Разобраться, почему пришлось так сделать
    requestDataId: form.requestDataId,
    requestContractPdf: form.requestContractPdf,
    requestContractFileName: form.requestContractFileName,
    requestContractFileId: form.requestContractFileId,
    requestActOfAcceptancePdf: form.requestActOfAcceptancePdf,
    requestActOfAcceptanceFileName: form.requestActOfAcceptanceFileName,
    requestActOfAcceptanceFileId: form.requestActOfAcceptanceFileId,
    requestProofOfPaymentPdf: form.requestProofOfPaymentPdf,
    requestProofOfPaymentFileName: form.requestProofOfPaymentFileName,
    requestProofOfPaymentFileId: form.requestProofOfPaymentFileId,
    isLoading: selectors.selectIsLoading(state),
    usedCarSettings: selectors.selectUsedCarSettings(state),
    requestAmountErrorList: selectors.selectErrorListByField(state)('requestAmount'),
    requestCodeErrorList: selectors.selectErrorListByField(state)('brandUsed'),
    requestBrandErrorList: selectors.selectErrorListByField(state)('requestBrand'),
    requestEngineDisplacementErrorList: selectors.selectErrorListByField(state)(
      'requestEngineDisplacement'
    ),
    requestEnginePowerErrorList: selectors.selectErrorListByField(state)('requestEnginePower'),
    requestElectricalEnginePowerErrorList: selectors.selectErrorListByField(state)(
      'requestElectricalEnginePower'
    ),
    requestMileageErrorList: selectors.selectErrorListByField(state)('requestMileage'),
    requestModelErrorList: selectors.selectErrorListByField(state)('requestModel'),
    requestCarPassportDateErrorList:
      selectors.selectErrorListByField(state)('requestCarPassportDate'),
    requestContractPdfErrorList: selectors.selectErrorListByField(state)('requestContractPdf'),
    requestActOfAcceptancePdfErrorList: selectors.selectErrorListByField(state)(
      'requestActOfAcceptancePdf'
    ),
    requestProofOfPaymentPdfErrorList: selectors.selectErrorListByField(state)(
      'requestProofOfPaymentPdf'
    ),
    requestVinErrorList: selectors.selectErrorListByField(state)('requestVin'),
    apiErrorList: selectors.selectErrorListByField(state)('api'),
    requestCodeOptions: selectors.selectRequestCodeOptions(state),
    requestModelOptions: selectors.selectBrandModelOptionsUsed(state),
    requestBrandOptions: selectors.selectBrandOptionsUsed(state),
    ...props,
  };
  console.log(`AddUsedCarForm/mapStateToProps END: result = ${JSON.stringify(result)}`);
  console.log(
    `AddUsedCarForm/mapStateToProps END: result.notVin = ${JSON.stringify(result.notVin)}`
  );
  return result;
};

const mapDispatchToProps = (dispatch: TODO_ANY): IAllCreateFormActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
