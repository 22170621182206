import React, { useEffect, useRef } from 'react';

import { configureValidator } from '@rfb/common';
import { TRepoAttachment } from 'dto/operation-data';
import { repoAttachmentsRules as attachmentsRules } from 'modules/financial-block/configs/validation';
import { Close } from 'modules/financial-block/helpers/CloseIcon';
import notification from 'utils/notification';
import styles from './assets/styles/index.module.css';

export interface UploadFormRepoProps {
  operationDataId: number;
  isLoading: boolean;
  attachmentsErrorList: string[];
  apiErrorList: string[];
  attachments: FileList;
  uploadedRepoAttachments: TRepoAttachment[];
}
export interface UploadFormRepoActions {
  actions: {
    uploadForRepo: Function;
    reset: Function;
    setError: Function;
    addAttachments: Function;
    deleteAttachment: Function;
    getRepoAttachments: Function;
  };
}
export interface UploadFormRepoPropsExternal {
  history: any;
  backUrl: string;
}

const UploadFormRepo = (
  props: UploadFormRepoProps & UploadFormRepoActions & UploadFormRepoPropsExternal
) => {
  const innerRef = useRef<HTMLInputElement>(null);

  const validators = {
    files: configureValidator({
      name: 'files',
      rule: attachmentsRules,
      setError: props.actions.setError,
    }),
  };

  function getExtensionFromFilename(filename: string): string {
    const parts = filename.split('.');
    if (parts.length === 1) {
      return ''; // Если в имени файла нет точки, значит расширения нет
    }
    return parts[parts.length - 1];
  }

  function checkFilesForArchives(files: FileList): boolean {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extension = getExtensionFromFilename(file.name);
      if (
        extension === 'zip' ||
        extension === 'rar' ||
        extension === '7z' ||
        extension === 'gz' ||
        extension === 'tar'
      ) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    return () => {
      // props.actions.reset(); TODO Сбрасывает лишнее, в частности lastFinancingRepoRequestDataFinancingInfoResponse
    };
    // ESLINT Необходимо выполнение только в момент размонтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBack = () => {
    props.history.push(props.backUrl);
  };

  const handleAddAttachmentsClick = () => innerRef.current?.click();

  const onAddAttachments = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files;
    if (files && files.length > 0) {
      if (!checkFilesForArchives(files)) {
        notification.info('Вложение архивов недопустимо');
        return;
      }
      props.actions.addAttachments({
        files,
        operationDataId: props.operationDataId,
        onSuccess: () => {
          props.actions.getRepoAttachments({ operationDataId: props.operationDataId });
        },
      });
    }
  };

  function onDeleteAttachment(operationAttachmentId: number) {
    props.actions.deleteAttachment({
      operationDataId: props.operationDataId,
      operationAttachmentId,
      onSuccess: () => {
        props.actions.getRepoAttachments({ operationDataId: props.operationDataId });
      },
    });
  }

  return (
    <div>
      <div className={styles.form} role="form">
        <div style={{ fontSize: '18px', fontWeight: '500' }}>
          <div style={{ marginBottom: '25px' }}>
            - Необходимо приложить следующие документы в формате PDF:
          </div>
          <div className={styles.formText}>- Счёт официального дилера</div>
          <div className={styles.formText}>
            - Документы Поставщика(счёт/товарная накладная/счёт-фактура/иное
          </div>
          <div className={styles.formText}>- Платёжное поручение на оплату аванса</div>
          <div style={{ marginTop: '25px', fontWeight: '400', fontSize: '17px' }}>
            Размер загружаемых файлов не должен превышать 5 Мегабайт
          </div>
        </div>
        <div className={styles.attachments}>
          <div className={styles.attachmentBlock}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <button className={styles.addAttachmentsButton} onClick={handleAddAttachmentsClick}>
                Вложить документ, PDF
              </button>
              <input
                style={{ display: 'none' }}
                type="file"
                multiple
                ref={innerRef}
                onChange={(e) => onAddAttachments(e)}
              />
            </div>
            <div style={{ fontSize: '18px', marginTop: '20px', marginLeft: '20px' }}>
              {props.uploadedRepoAttachments.map((attachment) => (
                <div className={styles.attachment}>
                  <div style={{ color: 'gray' }}>{attachment.attachment_file_name}</div>
                  <Close
                    className={styles.attachmentDeleteIcon}
                    onClick={() => onDeleteAttachment(attachment.operation_attachment_id)}
                  />
                  <br />
                </div>
              ))}
            </div>
            <div style={{ marginTop: '25px', fontWeight: '400', fontSize: '17px' }}>
              Чтобы вернуться к работе с заявкой, нажмите кнопку 'Назад'
            </div>
          </div>

          {/* <Button
            isDisabled={false}
            wrapperClassName={styles.button}
            type="submit"
            text="Сохранить"
            onClick={onBack}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default UploadFormRepo;
