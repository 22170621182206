import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
//import { worker } from './mocks/two-system/browser';
import { store } from 'root-store';
import { worker } from './mocks/browser';

import App from './components/App';

import { LogLevel, Logger } from '@rfb/common';

export const logger = Logger.getInstance(LogLevel.INFO, 10000);
logger.info('Start client application');

if (process.env.REACT_APP_MSW === 'ON') {
  worker.start();
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
