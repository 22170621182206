import React from 'react';
import Notifications from 'modules/notifications/components/Notifications';
import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import AppFullWidthTemplate from '../../../templates/AppFullWidthTemplate';

const NotificationsPage = (props: TODO_ANY) => {
  const notificationDealerSystem = props.match.params.dealerSystem;
  const renderContent = () => (
    <Notifications notificationDealerSystem={notificationDealerSystem} history={props.history} />
  );

  return <AppFullWidthTemplate content={renderContent()} />;
};

export default NotificationsPage;
