export enum TrancheStatus {
  OPENED = '0',
  CLOSED = '1',
  ALL = '2',
}

export enum TrancheStatusValue {
  OPENED = 'Открытый',
  CLOSED = 'Закрытый',
  ALL = 'Все',
}

export const trancheStatusValue: { [key: string]: string } = {
  [TrancheStatus.OPENED]: 'Открытый',
  [TrancheStatus.CLOSED]: 'Закрытый',
  [TrancheStatus.ALL]: 'Все',
};

export const trancheStatus: { [key: string]: TrancheStatus } = {
  [TrancheStatusValue.OPENED]: TrancheStatus.OPENED,
  [TrancheStatusValue.CLOSED]: TrancheStatus.CLOSED,
  [TrancheStatusValue.ALL]: TrancheStatus.ALL,
};
