import React, { useEffect } from 'react';
import { Table, ITable, Condition, MessageList } from '@rfb/ui-kit';
import { Directions, IDealerSystemsUse } from '@rfb/common';
import { IClientLineState } from '../../store';
import styles from './assets/styles/index.module.css';
import fp from 'lodash/fp';

export interface IClientLineTableShortProps {
  headers: ITable['headers'];
  rows: ITable['rows'];
  pageCount: number;
  filter: IClientLineState['filter'];
  isLoading: boolean;
  apiErrorList: string[];
  dealerSystems: IDealerSystemsUse;
}

export interface IClientLineTableShortActions {
  actions: {
    getDataShort: Function;
    setFilter: Function;
  }
}

const TableShort = (props: IClientLineTableShortProps & IClientLineTableShortActions) => {
  useEffect(() => {
    props.actions.getDataShort(props.filter)
  }, [props.filter, props.actions])

  return (
    <div className={styles.main}>
      <Condition value={!fp.isEmpty(props.apiErrorList)}>
        <MessageList type="error" messages={props.apiErrorList} />
      </Condition>
      <Table
        headers={props.headers}
        rows={props.rows}
        sorting={{ value: props.filter.sortField as string, direction: props.filter.sortDirection as Directions }}
        pageCount={props.pageCount}
        page={props.filter.page as number}
        isLoading={props.isLoading}
        onSortingChange={data => props.actions.setFilter({ sortField: data.value, sortDirection: data.direction })}
        onPageChange={(page: number) => props.actions.setFilter({ page })}
      />
    </div>
  );
};

export default TableShort;
