import React from 'react';
import { NavLink } from 'react-router-dom';
import fp from 'lodash/fp';
import Checkbox from '../../../../../Checkbox';
import styles from './assets/styles/index.module.css';
export var Cell = function (props) {
    var renderCheckbox = function () {
        var _a = props.value, type = _a.type, isChecked = _a.isChecked, isDisabled = _a.isDisabled, onChange = _a.onChange;
        return React.createElement("div", { className: styles.cell },
            React.createElement(Checkbox, { type: type, isChecked: isChecked, isDisabled: isDisabled, onChange: onChange }));
    };
    var renderLink = function () { return (React.createElement(NavLink, { className: styles.link, to: props.link },
        React.createElement("div", { className: styles.cell }, props.value))); };
    var renderDefault = function () { return (React.createElement("div", { className: styles.cell, title: fp.isString(props.value) ? props.value : undefined }, props.value)); };
    var renderByCurrentType = function () {
        var _a;
        if ((_a = props.value) === null || _a === void 0 ? void 0 : _a.isCheckbox)
            return renderCheckbox();
        if (props.link)
            return renderLink();
        return renderDefault();
    };
    return renderByCurrentType();
};
export default Cell;
