import React from 'react';

import { ButtonPrint, History } from '@rfb/ui-kit';

import routingConfig from 'configs/routing';

import AppTemplate from 'templates/AppTemplate';
import InformationContacts from 'modules/information/components/Contacts';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { dealerSystemsKey } from '../../../modules/auth/store';
import storage from '../../../utils/storage';

const Contacts = (props: TODO_ANY) => {
  const dealerSystemConfig = storage.get(dealerSystemsKey).data;
  const twoSystemShow: boolean = dealerSystemConfig.rf_flag === 1;
  const isButtonsPage = props.location.pathname.includes('two-system');
  const renderSidebarLeft = () => (
    <History
      type="back"
      to={
        twoSystemShow
          ? isButtonsPage
            ? routingConfig.information.path
            : routingConfig.twoSystemContacts.path
          : routingConfig.information.path
      }
    />
  );

  const renderContent = () => <InformationContacts history={props.history} />;
  const renderSidebarRight = () => (isButtonsPage ? <div /> : <ButtonPrint />);

  return (
    <AppTemplate
      content={renderContent()}
      sidebarLeft={renderSidebarLeft()}
      sidebarRight={renderSidebarRight()}
    />
  );
};

export default Contacts;
