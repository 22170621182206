import fp from 'lodash/fp';

import { convertPathUsingParams } from '@rfb/common';

import routingConfig from 'configs/routing';
import { clientLineStatusOptions } from '../../configs/status';
import {
  ClientLineTypes,
  clientLineCommissionTypeTranslations,
  clientLineTypeTranslations,
  clientLineTypeTranslationsFull,
} from '../../configs/type';

export const getTransformedNumberForView = (number: string, type: string): string =>
  `${ClientLineTypes.CC !== type ? number + '-' : ''}${clientLineTypeTranslations[type]}`;

export const getTransformedStatus = (status: string): string => clientLineStatusOptions[status];

export const getTransformedStatusFull = (status: string): string =>
  clientLineTypeTranslationsFull[status];

export const getTransformedTableRow = (cellList: string[]) => {
  const [number, status, type, multi_brand, ...rest] = cellList;
  const link: string = convertPathUsingParams(routingConfig.clientLinesDetailById.path, {
    id: number,
    type,
    multiBrand: multi_brand ? 1 : 0,
  });
  const numberForView: string = getTransformedNumberForView(number, type);
  const statusForView: string = getTransformedStatus(status);
  return { config: { link }, data: [numberForView, statusForView, ...rest] };
};

export const getTransformedPercentRate = (value: string): string =>
  fp.pipe(
    fp.split(';'),
    fp.map((p) => fp.toNumber(p).toFixed(2)),
    fp.join('/')
  )(value);

export const getTransformedCommissionTypeList = (value: string): string[] => {
  const commissionTypeList: string[] = fp.split(';', value);
  return fp.map(
    (type: string): string => fp.path(type, clientLineCommissionTypeTranslations),
    commissionTypeList
  );
};
