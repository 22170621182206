import fp from 'lodash/fp';
import Big from 'big.js';

import { ITrancheState } from '../../store';
import { TranchePayment } from '../../configs/payment';

type TTranchePaymentListItem = ITrancheState['paymentList'][number];

export const calculateAccurateSum = (a: Big, b: number | string): Big => a.plus(b);

const prePaymentFieldList: (keyof TTranchePaymentListItem)[] = ['sum_pre_pay'];
export const fullPaymentFieldList: (keyof TTranchePaymentListItem)[] = [
  'rest_main_debt',
  'overdue_debt',
  'sum_percent',
  'sum_fin',
  'sum_comiss',
];
const prepaymentFieldList: (keyof TTranchePaymentListItem)[] = [
  'sum_pre_pay',
  'overdue_debt',
  'sum_percent',
  'sum_fin',
  'sum_comiss',
];

export const calculateTrancheAmount = (tranche: TTranchePaymentListItem): Big => {
  const isPrePaymentType: boolean = fp.isEqual(tranche.payment_type, TranchePayment.PRE);
  return fp.pipe(
    fp.paths(isPrePaymentType ? prePaymentFieldList : fullPaymentFieldList),
    fp.reduce(calculateAccurateSum, Big(0))
  )(tranche);
};

export const calculateTrancheRepaymentAmount = (tranche: TTranchePaymentListItem): Big => {
  const isPrePaymentType: boolean = fp.isEqual(tranche.payment_type, TranchePayment.PRE);
  return fp.pipe(
    fp.paths(isPrePaymentType ? prepaymentFieldList : fullPaymentFieldList),
    fp.reduce(calculateAccurateSum, Big(0))
  )(tranche);
};
