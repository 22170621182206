"use strict";
exports.__esModule = true;
exports.downloadLogs = void 0;
function downloadLogs(logger) {
    var logText = logger.getLogs();
    var blob = new Blob([logText.join('\n')], { type: 'text/plain' });
    var fileName = "error_log_" + new Date().toISOString() + ".txt";
    var link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
exports.downloadLogs = downloadLogs;
