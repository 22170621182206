import React from 'react';

import { History, AuthTemplate } from '@rfb/ui-kit';

import storage from 'utils/storage';

import PhoneNumberValidation from 'modules/auth/components/PhoneNumberValidation';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const PhoneValidation = (props: TODO_ANY) => {
  const onBackClick = (): void => {
    storage.clearAll();
    window.location.reload();
  };

  const renderContent = (): JSX.Element => (
    <div className={styles.content}>
      <PhoneNumberValidation history={props.history} />
    </div>
  );

  const renderLeftSidebar = (): JSX.Element => (
    <div onClick={onBackClick}>
      <History type="back" to={routingConfig.login.path} />
    </div>
  );

  return <AuthTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default PhoneValidation;
