import React from 'react';

import { History, AuthTemplate } from '@rfb/ui-kit';

import InformationFeedbackSent from 'modules/information/components/FeedbackSent';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const FeedbackSent = (props: TODO_ANY) => {
  const renderContent = () => (
    <div className={styles.main}>
      <InformationFeedbackSent history={props.history} />
    </div>
  );

  const renderLeftSidebar = () => <History type="back" to={routingConfig.information.path} />;

  return <AuthTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default FeedbackSent;
