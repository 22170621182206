import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import confirmTypes from '../../configs/confirmTypes';
import { IConfirmState, actions, name, selectors } from '../../store';

import Component, {
  ConfirmCodeActions,
  ConfirmCodeProps,
  ConfirmCodePropsExternal,
} from './component';

const mapStateToProps = (
  state: { [name]: IConfirmState },
  props: {
    header?: string;
    changePersonalDataFlag?: boolean;
    onCancel: Function;
    onSuccess: Function;
    onFailure?: Function;
    confirmTypeCode: confirmTypes;
  }
): ConfirmCodeProps & ConfirmCodePropsExternal => ({
  code: selectors.selectCode(state),
  factorUrl: selectors.selectFactorUrl(state),
  phoneNumber: selectors.selectPhoneNumber(state),
  codeErrorList: selectors.selectErrorListByField(state)('code'),
  apiErrorList: selectors.selectErrorListByField(state)('api'),
  isSending: selectors.selectIsSending(state),
  isLoading: selectors.selectIsLoading(state),
  isBlocked: selectors.selectIsBlocked(state),
  ...props,
});

const mapDispatchToProps = (dispatch: any): ConfirmCodeActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
