import React from 'react';

import { History } from '@rfb/ui-kit';

import AppTemplate from 'templates/AppTemplate';

import UserPhoneNumberChange from 'modules/user/components/PhoneNumberChange';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { useDispatch } from 'react-redux';
import { actions } from 'modules/user/store';

const UserPhoneNumberChangePage = (props: TODO_ANY) => {
  const dispatch = useDispatch();

  const renderLeftSidebar = (): JSX.Element => (
    <div
      className={styles.sidebarLeft}
      onClick={() => {
        dispatch(actions.reset());
      }}
    >
      <History type="back" to={routingConfig.profile.path} />
    </div>
  );

  const renderContent = () => (
    <div className={styles.content}>
      <UserPhoneNumberChange history={props.history} />
    </div>
  );

  return <AppTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default UserPhoneNumberChangePage;
