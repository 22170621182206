import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { name, actions, selectors, IAccountsState } from '../../../../store';

import Component, { IAccountsTableProps, IAccountsTableActions } from './component';

import styles from './assets/styles/index.module.css';
import { getTransformedTableRow } from '../../../../helpers/transform';

const mapStateToProps = (state: { [name]: IAccountsState }): IAccountsTableProps => ({
  headers: [
    { title: 'Дата операций', value: 'doc_date', columnClassName: styles.columnDate },
    { title: 'Номер документа', value: 'doc_number', columnClassName: styles.columnNumber },
    { title: 'Назначение платежа', value: 'comment', columnClassName: styles.columnComment },
    { title: 'Поступление', value: 'qty_debt', columnClassName: styles.columnDebit },
    { title: 'Списание', value: 'qty_cred', columnClassName: styles.columnCredit },
  ],
  rows: (className: string) =>
    fp.pipe(
      selectors.selectTransactions(state),
      fp.map((data) => getTransformedTableRow(data, className))
    )(['doc_date', 'doc_number', 'comment', 'qty_cred', 'qty_debt']),
  pageCount: selectors.selectTransactionsPageCount(state),
  filter: selectors.selectFilter(state),
  rfInfoErrorList: selectors.selectErrorListByField(state)('rfInfo'),
  isLoading: selectors.selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: any): IAccountsTableActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
