import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import rootMiddleware from './root-middleware';
import rootReducer from './root-reducer';
import { runAll as runAllMiddlewares } from './root-saga';

export const store = configureStore({
  reducer: rootReducer,
  middleware: process.env.NODE_ENV === 'development' ? [...rootMiddleware, logger] : rootMiddleware,
});

runAllMiddlewares();
