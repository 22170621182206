import { rest } from 'msw';
import {
  systemAvailabilityAvailableResponseBody,
  systemAvailabilityUnavailableResponseBody,
} from './systemAvailabilityResponseBody';

export const systemAvailabilityHandlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/system/availability`, (req, res, ctx) => {
    console.log('msw-handlers: /system/availability');
    //    return res(ctx.status(500));
    return res(ctx.status(200), ctx.json(systemAvailabilityAvailableResponseBody));
    //    return res(ctx.status(200), ctx.json(systemAvailabilityUnavailableResponseBody));
  }),
];
