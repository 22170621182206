import { connect } from 'react-redux';

import fp from 'lodash/fp';

import { IClientLineState, name, selectors } from '../../../../store';

import { getTransformedDate } from 'helpers/transform/date';
import { getTransformedNumberForView } from '../../../../helpers/transform';

import { IDTORFInfoClientLineFullResponse, IDTORFInfoRateResponse } from 'dto/rf-info';

import Component, { IClientLineDetailHeader } from './component';

const mapStateToProps = (state: { [name]: IClientLineState }): IClientLineDetailHeader => {
  const currentClientLine: IDTORFInfoClientLineFullResponse =
    selectors.selectCurrentClientLine(state);
  const rate: IDTORFInfoRateResponse = selectors.selectRate(state);

  return {
    clientLineId: currentClientLine.number,
    type: currentClientLine.type,
    number: getTransformedNumberForView(currentClientLine.number, currentClientLine.type),
    status: currentClientLine.status,
    rateDate: rate ? getTransformedDate(rate.date_rate) : '?',
    rateValue: fp.toNumber(rate?.value),
    dateBegin: getTransformedDate(currentClientLine.start_date),
    dateEnd: getTransformedDate(currentClientLine.real_finish_date),
    isRateChangeNeed: currentClientLine.is_rate_change_need,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
