var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var LensIcon = function (props) { return (React.createElement("svg", __assign({}, props, { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("circle", { cx: "13", cy: "13", r: "6.25", stroke: "#282423", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M17 17L21 21", stroke: "#282423", strokeWidth: "1.5" }))); };
export default LensIcon;
