import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { name, actions, selectors, IAccountsState } from '../../store';

import Component, {
  IAccountsTableProps,
  IAccountsTableActions,
  IAccountRestrictionsDetailPropsExternal,
} from './component';

import { getDetailedTableRow } from '../../helpers/transform';

const mapStateToProps = (
  state: { [name]: IAccountsState },
  props: IAccountRestrictionsDetailPropsExternal,
  ): IAccountsTableProps & IAccountRestrictionsDetailPropsExternal => ({
  headers: [
    { title: 'Дата', value: 'arrest_date' },
    { title: 'Вид ограничения', value: 'arrest_type' },
    { title: 'Организация', value: 'arrest_organization' },
    { title: 'Сумма ограничений', value: 'arrest_qty' },
  ],
  rows: (className: string) => fp.pipe(
    selectors.selectArrests(state),
    fp.map(data => getDetailedTableRow(data, className))
  )(['arrest_date', 'arrest_type', 'arrest_organization', 'arrest_qty']),
  pageCount: selectors.selectArrestsPageCount(state),
  isLoading: selectors.selectIsLoading(state),
  filter: selectors.selectFilter(state),
  id: props.id,
  noRestrictions: !selectors.selectCurrentArrestsData(state).arrests.length,
});

const mapDispatchToProps = (dispatch: any): IAccountsTableActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
