import _ from 'lodash';
export var getRowManagement = function (config) {
    var getCellIndex = function (target) {
        var parentNode = target.parentNode;
        return _.findIndex(parentNode === null || parentNode === void 0 ? void 0 : parentNode.children, target);
    };
    var getSelector = function (cellIndex) {
        return "." + config.columnClassName + " ." + config.cellClassName + ":nth-child(" + (cellIndex + 1) + ")";
    };
    return {
        addHoverClass: function (event) {
            var addHoverClass = function (element) {
                return element.classList.add(config.cellHoverClassName);
            };
            var cellIndex = getCellIndex(event.currentTarget);
            var rowElementList = document.querySelectorAll(getSelector(cellIndex));
            _.forEach(rowElementList, addHoverClass);
        },
        removeHoverClass: function (event) {
            var removeHoverClass = function (element) {
                return element.classList.remove(config.cellHoverClassName);
            };
            var cellIndex = getCellIndex(event.currentTarget);
            var rowElementList = document.querySelectorAll(getSelector(cellIndex));
            _.forEach(rowElementList, removeHoverClass);
        },
    };
};
