import React, { useState } from 'react';

import fp from 'lodash/fp';

import { convertPathUsingParams } from '@rfb/common';

import { Button, Condition, LinkWithContent, NotificationConfirm } from '@rfb/ui-kit';

import routingConfig from 'configs/routing';
import { ClientLineStatus } from '../../../../configs/status';

import styles from './assets/styles/index.module.css';

export interface IClientLineDetailHeader {
  clientLineId: string;
  type: string;
  number: string;
  status: number;
  rateDate: string;
  rateValue: number;
  dateBegin: string;
  dateEnd?: string;
  isRateChangeNeed: boolean;
}

const Header = (props: IClientLineDetailHeader) => {
  const [isRateChangeNeedConfirmed, setIsRateChangeNeedConfirmed] = useState(false);
  const isOpened: boolean = fp.isEqual(props.status, fp.toNumber(ClientLineStatus.OPENED));
  const tranchesListByNumberUrl: string = convertPathUsingParams(
    routingConfig.tranchesListByClientLineIdAndType.path,
    { clientLineId: props.clientLineId, clientLineType: props.type }
  );

  const mosPrimeDate = new Date(2023, 4, 1);
  function isTodayEarlierThan(date: Date): boolean {
    return new Date() < date;
  }

  return (
    <div className={styles.main}>
      <Condition value={props.isRateChangeNeed && !isRateChangeNeedConfirmed}>
        <NotificationConfirm
          message="Обратитесь в ПАО РОСБАНК (к курирующему менеджеру) для обновления условий текущего договора - замены ставки 2М MosPrime на Ключевую ставку Банка России в расчёте применимой процентной ставки"
          buttonTextAgree="OK"
          onAgree={() => {
            setIsRateChangeNeedConfirmed(true);
          }}
        />
      </Condition>
      <div className={styles.block}>
        <h1 className={styles.title}>
          Договор {props.number} {isOpened ? 'открыт' : 'закрыт'}
        </h1>
        <LinkWithContent wrapperClassName={styles.link} to={tranchesListByNumberUrl}>
          <Button text="К траншам по этому договору" />
        </LinkWithContent>
      </div>

      <div className={styles.block}>
        <div className={styles.dates}>
          <div className={styles.date}>Дата открытия {props.dateBegin}</div>
          <Condition value={!isOpened}>
            <div className={styles.date}>Дата закрытия {props.dateEnd ?? ''}</div>
          </Condition>
        </div>
        <div className={styles.financialRate}>
          <Condition value={isTodayEarlierThan(mosPrimeDate)}>
            Ставка MosPrime 2M на {props.rateDate} составляет{' '}
            {fp.toNumber(props.rateValue).toFixed(2)}%
          </Condition>
          <Condition value={!isTodayEarlierThan(mosPrimeDate)}>
            С 01.05.2023 для расчёта процентной ставки используется Ключевая ставка Банка России.
            <br />
            Ключевая ставка на {props.rateDate} составляет {fp.toNumber(props.rateValue).toFixed(2)}
            %
          </Condition>
        </div>
      </div>
    </div>
  );
};

export default Header;
