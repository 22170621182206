import React from 'react';

import { Dropdown } from '@rfb/ui-kit';

import styles from './assets/styles/index.module.css';

export interface IClientLineStatusSelectProps {
  optionList: { label: string, value: string }[];
  status: string;
}

export interface IClientLineStatusSelectActions {
  actions: {
    setFilter: Function,
  };
}

const StatusSelect = (props: IClientLineStatusSelectProps & IClientLineStatusSelectActions) => {
  const onStatusChange = (status: string): void => props.actions.setFilter({ status });

  return (
    <div className={styles.main}>
      <Dropdown
        label="Статус договора"
        options={props.optionList}
        value={props.status}
        onChange={onStatusChange}
      />
    </div>
  );
};

export default StatusSelect;
