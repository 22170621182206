import React from 'react';

import fp from 'lodash/fp';

import { Condition, MessageList, ButtonWithIcon } from '@rfb/ui-kit';

import styles from './assets/styles/index.module.css';

export interface IUserPersonalDataChangeProps {
  apiErrorList: string[];
}

export interface IUserPersonalDataChangePropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void;
  };
}

export interface IUserPersonalDataChangeActions {
  actions: {
    // downloadStatement: Function;
  };
}

const PersonalDataChange = (
  props: IUserPersonalDataChangeProps &
    IUserPersonalDataChangePropsExternal &
    IUserPersonalDataChangeActions
) => {
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h1>Изменить персональные данные</h1>
      </div>

      <div className={styles.title}>
        <h2>Если Ваши персональные данные изменились, необходимо:</h2>
      </div>

      <ul className={styles.info}>
        <li>
          заполнить и передать в Банк оригинал Заявления на изменение персональных данных
          Представителя Клиента в ИНФО-БАНК;
        </li>
        <li>
          в качестве подтверждения изменения персональных данных приложить к Заявлению документы
          подтверждающие изменения, например, паспорт гражданина РФ.
        </li>
      </ul>

      <Condition value={!fp.isEmpty(props.apiErrorList)}>
        <MessageList type="error" messages={props.apiErrorList} />
      </Condition>

      <ButtonWithIcon
        wrapperClassName={styles.button}
        type="doc"
        isActive
        text="Скачать заявление"
        onClick={() => {
          window.open(
            'https://api.rosbank-auto.ru/doc/shablon-zayavleniya-na-izmenenie-personalnykh-dannykh.docx',
            '_blank'
          );
        }}
      />
    </div>
  );
};

export default PersonalDataChange;
