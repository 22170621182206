import cn from 'classnames';
import fp from 'lodash/fp';
import React, { useState } from 'react';

import Clip from 'modules/financial-block/components/FTTableUsed/assets/images/clip';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../store';
import { LinkButton } from '../../link-button';
import styles from './assets/styles/index.module.css';

export interface DropDownRendererElement {
  title: string;
  file_id: string;
}

export interface DropDownRendererSubElement {
  title: string;
  items?: DropDownRendererElement[];
}

interface DropDownRendererProps {
  config: (DropDownRendererElement | DropDownRendererSubElement)[];
}

const emptyActiveSubmenuIndex = -1;

export const DropDownRenderer = (props: DropDownRendererProps) => {
  const [activeSubMenuIndex, setActiveSubMenuIndex] = useState(emptyActiveSubmenuIndex);
  const isActiveSubMenu = (index: number): boolean => fp.equals(index, activeSubMenuIndex);

  const onSubMenuHover = (index: number) => (): void => {
    return setActiveSubMenuIndex(index);
  };
  const onSubMenuBlur = (): void => setActiveSubMenuIndex(emptyActiveSubmenuIndex);
  const dispatch = useDispatch();
  const renderItem = (item: DropDownRendererElement) => (
    <div style={{ marginTop: 5, marginBottom: 5 }}>
      <LinkButton
        className={styles.linkMargin}
        onClick={() =>
          dispatch(actions.documentumFileDownload({ id: item.file_id, filename: item.title }))
        }
      >
        {item.title}
      </LinkButton>
    </div>
  );
  const renderClip = (item: DropDownRendererElement) => (
    <div style={{ marginTop: 5, marginBottom: 5 }}>
      <Clip />
    </div>
  );

  const renderSubMenu = (item: DropDownRendererSubElement) => {
    const index: number = fp.findIndex(item, props.config);
    const subMenuTitle: string = cn({
      [styles.subMenuTitle]: true,
    });
    const subMenuItemsClassNames: string = cn({
      [styles.subMenuItems]: true,
      [styles.subMenuItemsVisible]: isActiveSubMenu(index),
    });

    return (
      item.items && (
        <div onMouseEnter={onSubMenuHover(index)} onMouseLeave={onSubMenuBlur}>
          <div className={subMenuTitle}>
            <Clip />
          </div>
          <div className={subMenuItemsClassNames}>{fp.map(renderItem, item.items)}</div>
        </div>
      )
    );
  };

  const renderMenuElement = (item: DropDownRendererElement | DropDownRendererSubElement) => (
    <div key={item.title} className={styles.menuElement}>
      {fp.has('items', item) ? renderSubMenu(item) : renderItem(item as DropDownRendererElement)}
    </div>
  );

  return <div className={styles.main}>{fp.map(renderMenuElement, props.config)}</div>;
};

export default DropDownRenderer;
