import { createSlice } from '@reduxjs/toolkit';

import { getStateFromParams } from 'helpers/store';
import { DTOUserInfo } from 'dto/rf-info';

export const dealerSystemsKey: string = 'DEALER_SYSTEM_KEY';

export interface IAuthState {
  login: string;
  password: string;
  passwordRepeat: string;
  passwordOld: string;
  inn: string;
  kpp: string;
  phoneNumber: string;
  serviceTermsText: string;
  userDealers?: DTOUserInfo[];
  isPasswordRestoreSent: boolean;
  isSending: boolean;
  isLoading: boolean;
  errorList: { [key: string]: string[] };
  filter: {
    innMask: string;
  };
}

const initialState: IAuthState = {
  login: '',
  password: '',
  passwordRepeat: '',
  passwordOld: '',
  inn: '',
  kpp: '',
  phoneNumber: '',
  serviceTermsText: '',
  userDealers: undefined,
  isPasswordRestoreSent: false,
  isSending: false,
  isLoading: false,
  errorList: {},
  filter: {
    innMask: '',
  },
};

const authSlice = createSlice({
  name: 'auth',

  initialState: { ...initialState },

  reducers: {
    set: (state, action) => ({ ...state, ...action.payload }),
    setError: (state, action) => ({
      ...state,
      errorList: { ...state.errorList, ...action.payload },
    }),
    setFilter: (state, action) => ({ ...state, filter: { ...state.filter, ...action.payload } }),
    reset: (state) => ({
      ...initialState,
      isPasswordRestoreSent: state.isPasswordRestoreSent,
      userDealers: state.userDealers,
    }),

    login: getStateFromParams,
    loginSuccessful: getStateFromParams,
    loginFailure: getStateFromParams,

    logout: getStateFromParams,
    logoutSuccessful: getStateFromParams,
    logoutFailure: getStateFromParams,

    sendDealerData: getStateFromParams,
    sendDealerDataSuccessful: getStateFromParams,
    sendDealerDataFailure: getStateFromParams,

    sendPhoneNumber: getStateFromParams,
    sendPhoneNumberSuccessful: getStateFromParams,
    sendPhoneNumberFailure: getStateFromParams,

    restorePassword: getStateFromParams,
    restorePasswordSuccessful: getStateFromParams,
    restorePasswordFailure: getStateFromParams,

    getServiceTermsText: getStateFromParams,
    getServiceTermsTextSuccessful: getStateFromParams,
    getServiceTermsTextFailure: getStateFromParams,

    sendServiceTermsAgreement: getStateFromParams,
    sendServiceTermsAgreementSuccessful: getStateFromParams,
    sendServiceTermsAgreementFailure: getStateFromParams,

    changePassword: getStateFromParams,
    changePasswordSuccessful: getStateFromParams,
    changePasswordFailure: getStateFromParams,

    disagreeServiceTerms: getStateFromParams,

    getUserDealer: getStateFromParams,
    getUserDealerSuccessful: getStateFromParams,
    getUserDealerFailure: getStateFromParams,

    chooseDealer: getStateFromParams,
    chooseDealerSuccessful: getStateFromParams,
    chooseDealerFailure: getStateFromParams,
  },
});

export const { name, actions, reducer } = authSlice;
