import React from 'react';

import { History } from '@rfb/ui-kit';

import AppTemplate from 'templates/AppTemplate';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { useDispatch } from 'react-redux';
import ConfirmCode from 'modules/confirmation/components/ConfirmCode';
import confirmTypes from 'modules/confirmation/configs/confirmTypes';
import { actions } from 'modules/tranche/store';
const TrancheRepaymentConfirmPage = (props: TODO_ANY) => {
  const dispatch = useDispatch();

  const onBackClick = (): void => {
    window.location.replace(routingConfig.repayment.path);
  };

  const renderLeftSidebar = (): JSX.Element => (
    <div className={styles.sidebarLeft}>
      <History type="back" to={routingConfig.repayment.path} />
    </div>
  );

  const renderContent = (): JSX.Element => (
    <div className={styles.content}>
      <ConfirmCode
        header="Подтвердить заявку на погашение"
        confirmTypeCode={confirmTypes.IBD_CONFIRM_TRANCHE}
        onSuccess={() => {
          dispatch(
            actions.trancheRepaymentDocApprove({
              repaymentData: props.history.location.state.repaymentData,
              history: props.history,
            })
          );
        }}
        onCancel={onBackClick}
      />
    </div>
  );

  return <AppTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default TrancheRepaymentConfirmPage;
