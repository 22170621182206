import createSagaMiddleware from 'redux-saga';

import fp from 'lodash/fp';

import history from 'utils/history';

import routingConfig from 'configs/routing';

import { sagas as accountsSagas } from 'modules/accounts/store';
import { sagas as appSagas } from 'modules/app/store';
import { sagas as authSagas } from 'modules/auth/store';
import { sagas as clientLineSagas } from 'modules/client-line/store';
import { sagas as informationSagas } from 'modules/information/store';
import { sagas as trancheSagas } from 'modules/tranche/store';
import { sagas as operationDataSagas } from 'modules/operation-data/store';
import { sagas as userSagas } from 'modules/user/store';
import { sagas as confirmationSagas } from 'modules/confirmation/store';
import { sagas as financialBlockSagas } from 'modules/financial-block/store';
import { sagas as notificationVisualizationSagas } from 'modules/notifications/store';

const sagaMiddleware = createSagaMiddleware({
  onError: () => history.push(routingConfig.error500.path),
});

const sagaList = [
  accountsSagas,
  appSagas,
  authSagas,
  clientLineSagas,
  informationSagas,
  trancheSagas,
  operationDataSagas,
  userSagas,
  confirmationSagas,
  financialBlockSagas,
  notificationVisualizationSagas,
];

export const runAll = () => fp.forEach(sagaMiddleware.run, sagaList);

export default sagaMiddleware;
