import { connect } from 'react-redux';

import { IDTORFInfoAccountResponse } from '../../../../../../dto/rf-info';

import { name, selectors } from '../../../../store';

import Component, {
  IAccountsOpenDateProps,
} from './component';

const mapStateToProps = (state: { [name]: IDTORFInfoAccountResponse }): IAccountsOpenDateProps => ({
  open: selectors.selectCurrentAccountData(state).open_date,
  close: selectors.selectCurrentAccountData(state).close_date,
});

export default connect(mapStateToProps)(Component);
