import React from 'react';
import Logo from '../../icons/Logo';
import styles from './assets/styles/index.module.css';
export var AppFullWidthTemplate = function (props) { return (React.createElement("div", { className: styles.main },
    React.createElement("div", { className: styles.header },
        React.createElement("div", { className: styles.logo },
            React.createElement("a", { href: "/" },
                React.createElement(Logo, null))),
        props.header),
    React.createElement("div", { className: styles.content }, props.content))); };
export default AppFullWidthTemplate;
