import React from 'react';

import cn from 'classnames';
import fp from 'lodash/fp';

import { Button, LinkWithContent } from '@rfb/ui-kit';

import { ReactComponent as ArrowForwardIcon } from './assets/images/arrow-right.svg';

import styles from './assets/styles/index.module.css';

export interface IInformationNavigationProps {
  config: IInformationNavigationElement[];
}

interface IInformationNavigationElement {
  link: string;
  text: string;
  isRed?: boolean;
}

const Navigation = (props: IInformationNavigationProps) => {
  const renderArrow = () => (
    <div className={styles.arrow}>
      <ArrowForwardIcon />
    </div>
  );

  const renderNavButton = (item: IInformationNavigationElement) => (
    <LinkWithContent key={item.link} wrapperClassName={styles.link} to={item.link}>
      <Button
        wrapperClassName={cn({ [styles.button]: true, [styles.buttonRed]: item.isRed })}
        view="square"
        text={item.text}
        sidebarRight={renderArrow()}
      />
    </LinkWithContent>
  );

  return <div className={styles.main}>{fp.map(renderNavButton, props.config)}</div>;
};

export default Navigation;
