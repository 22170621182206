import { createSlice } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import storageDB from 'utils/storage-db';

import { getStateFromParams } from 'helpers/store';

import { Directions } from '@rfb/common';

import { ClientLineStatus } from 'modules/client-line/configs/status';
import { TranchePayment } from '../configs/payment';
import { TrancheStatus } from '../configs/status';

import { IDTORFInfoTranchesPaymentRequest, IDTORFInfoTranchesResponse } from 'dto/rf-info';

export const getPaymentListFromDB = (): ITrancheState['paymentList'] => {
  const result = storageDB.get('tranche-payment-list') || [];
  return result;
};

export interface ITrancheState {
  data: IDTORFInfoTranchesResponse['tranches'];
  dataFilter: IDTORFInfoTranchesResponse['filters'];
  paymentList: (Omit<IDTORFInfoTranchesResponse['tranches'][number], 'payment_type'> & {
    payment_type: TranchePayment;
  })[];
  paymentOrderList: number[];
  filter: {
    clientLineId: number;
    clientLineStatus: ClientLineStatus;
    type: 'ALL';
    status: TrancheStatus | '';
    isExtraTableData: boolean;
    vin: string;
    brand: string;
    model: string;
    number: string;
    sorting?: { value: string; direction: Directions };
    dateStart: string;
    dateEnd: string;
    dateFinishStart: string;
    dateFinishEnd: string;
    pageCount: number;
    page: number;
    perPage: number;
    isFilterActive: boolean;
    lastResetTimestamp: number;
  };
  filterTemp: {
    clientLineStatus: ClientLineStatus;
    status: TrancheStatus;
    dateStart: string;
    dateEnd: string;
  };
  pageCount: number;
  showSelected: boolean;
  errorList: { [key: string]: string[] };
  isSending: boolean;
  isLoading: boolean;
  isTrancheRepaymentCompleted: boolean;
  repaymentData: IDTORFInfoTranchesPaymentRequest;
  repaymentFileName: string;
}

const initialState: ITrancheState = {
  data: [],
  dataFilter: { models: [], brands: [], contracts: [], statuses: [] },
  paymentList: getPaymentListFromDB(),
  paymentOrderList: [],
  filter: {
    clientLineId: 0,
    clientLineStatus: ClientLineStatus.OPENED,
    type: 'ALL',
    status: TrancheStatus.OPENED,
    isExtraTableData: false,
    vin: '',
    brand: '',
    model: '',
    number: '',
    sorting: { value: '', direction: Directions.ASC },
    dateStart: '',
    dateEnd: '',
    dateFinishStart: '',
    dateFinishEnd: '',
    pageCount: 0,
    page: 1,
    perPage: 20,
    isFilterActive: false,
    lastResetTimestamp: 0,
  },
  filterTemp: {
    clientLineStatus: ClientLineStatus.OPENED,
    status: TrancheStatus.OPENED,
    dateStart: '',
    dateEnd: '',
  },
  pageCount: 0,
  showSelected: false,
  errorList: {},
  isSending: false,
  isLoading: false,
  isTrancheRepaymentCompleted: false,
  repaymentData: { payments: [] },
  repaymentFileName: '',
};

const trancheSlice = createSlice({
  name: 'tranche',

  initialState,

  reducers: {
    set: (state, action) => ({ ...state, ...action.payload }),
    setError: (state, action) => ({
      ...state,
      errorList: { ...state.errorList, ...action.payload },
    }),
    setFilter: (state, action) => ({
      ...state,
      filter: { ...state.filter, ...action.payload, isFilterActive: true },
    }),
    setFilterTemp: (state, action) => ({
      ...state,
      filterTemp: { ...state.filterTemp, ...action.payload },
    }),
    togglePaymentList: (state, action) => {
      const storagePaymentList = getPaymentListFromDB();
      const hasSameVin = (item: ITrancheState['paymentList'][number]): boolean =>
        fp.isEqual(action.payload.vin, item.vin);
      const hasSamePaymentType = (item: ITrancheState['paymentList'][number]): boolean =>
        fp.isEqual(action.payload.payment_type, item.payment_type);
      const paymentList: ITrancheState['paymentList'] = fp.find(hasSameVin, storagePaymentList)
        ? fp.pipe(
            fp.map((item: ITrancheState['paymentList'][number]) => {
              if (fp.every(Boolean, [hasSameVin(item), hasSamePaymentType(item)])) return null;
              if (hasSameVin(item)) return { ...item, payment_type: action.payload.payment_type };
              return item;
            }),
            fp.reject(fp.isNil)
          )(storagePaymentList)
        : [action.payload, ...storagePaymentList];

      storageDB.set('tranche-payment-list', paymentList);
      return { ...state, paymentList };
    },
    toggleExtraTableData: (state) => ({
      ...state,
      filter: {
        ...state.filter,
        isExtraTableData: !state.filter.isExtraTableData,
        isFilterActive: true,
      },
    }),
    applyTempFilter: (state) => ({
      ...state,
      filter: { ...state.filter, ...state.filterTemp, isFilterActive: true },
    }),
    reset: () => ({ ...initialState }),
    resetFilter: (state) => ({
      ...state,
      filter: { ...initialState.filter, lastResetTimestamp: Date.now() },
    }),
    resetFilterByOmitList: (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        ...fp.omit<ITrancheState['filter']>(action.payload, initialState.filter),
        lastResetTimestamp: Date.now(),
      },
    }),
    resetFilterTemp: (state) => ({ ...state, filterTemp: { ...initialState.filterTemp } }),

    getData: getStateFromParams,
    getDataSuccessful: getStateFromParams,
    getDataFailure: getStateFromParams,

    sendPaymentList: getStateFromParams,
    sendPaymentListFailure: getStateFromParams,

    exportData: getStateFromParams,
    exportDataSuccessful: getStateFromParams,
    exportDataFailure: getStateFromParams,

    trancheRepaymentDocRequestConfirm: getStateFromParams,
    trancheRepaymentDocRequestConfirmSuccessful: getStateFromParams,
    trancheRepaymentDocRequestConfirmFailure: getStateFromParams,

    trancheRepaymentDocApprove: getStateFromParams,

    trancheRepaymentDocDownload: getStateFromParams,

    trancheRepaymentReset: getStateFromParams,
  },
});

export const { name, actions, reducer } = trancheSlice;
