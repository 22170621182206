import { convertPathUsingParams } from '@rfb/common';
import routingConfig from 'configs/routing';
import { IDTOOperationDataResponse } from 'dto/operation-data';
import { actions } from 'modules/operation-data/store';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import history from 'utils/history';

interface OperationNumberProps {
  record: IDTOOperationDataResponse['records'][number];
}

export const OperationNumberCell = ({ record }: OperationNumberProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  return (
    <a
      style={{
        margin: '5px',
        cursor: 'pointer',
        textDecoration: 'underline',
        color: 'gray',
      }}
      target="_blank"
      onClick={(e) => {
        e.preventDefault();
        const url = convertPathUsingParams(routingConfig.operationDataView.path, {
          id: record.operation_data_id,
          dealerSystem: location.pathname.includes('/rf') ? 'rf' : 'rb',
        });
        history.push(url);
        dispatch(actions.set({ currentOperationDataRecord: record }));
      }}
    >
      {record.operation_number}
    </a>
  );
};
