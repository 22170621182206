import React, { useState } from 'react';

import { Alert, Button, Condition } from '@rfb/ui-kit';

import storage from 'utils/storage';

import styles from './assets/styles/index.module.css';

export const authorityExpireStorageKey: string = 'alertAuthorityExpireHidden';

const AuthorityExpire = () => {
  const [hidden, setHidden] = useState(storage.get(authorityExpireStorageKey));

  const onButtonClick = (): void => {
    storage.set(authorityExpireStorageKey, true);
    setHidden(true);
  };

  return (
    <Condition value={!hidden}>
      <Alert>
        <div className={styles.content}>
          <div className={styles.textWrapper}>
            <div className={styles.text}>
              Срок действия Вашей учетной записи подходит к концу. Предоставьте в Банк «Заявление о продлении срока полномочий Пользователя Клиента, использующего сервис «ИНФО-БАНК»
            </div>
          </div>
          <div className={styles.button}>
            <Button text="OK" onClick={onButtonClick} />
          </div>
        </div>
      </Alert>
    </Condition>
  );
};

export default AuthorityExpire;
