import { convertPathUsingParams } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import { History } from '@rfb/ui-kit';
import routingConfig from 'configs/routing';
import { IDTOOperationDataResponse } from 'dto/operation-data';
import OperationDataViewTable from 'modules/operation-data/components/repo/OperationDataViewTable';
import { selectors } from 'modules/operation-data/store';
import React from 'react';
import { store } from 'root-store';
import AppFullWidthTemplate from 'templates/AppFullWidthTemplate';
import styles from './assets/styles/index.module.css';

const OperationDataViewPage = (props: TODO_ANY) => {
  const state = store.getState();
  const currentOperationDataRecord: IDTOOperationDataResponse['records'][number] | null =
    selectors.selectCurrentOperationDataRecord(state);

  const renderContent = () => (
    <div className={styles.content}>
      <h1 className={styles.title}>Заявка журнала операций РУСФИНАНС</h1>
      <h2 className={styles.subtitle}>{`№ ${currentOperationDataRecord?.operation_number}`}</h2>

      <div style={{ marginTop: '40px', marginBottom: '30px' }}>
        <History
          wrapperClassName={styles.wrapperHistory}
          type="back"
          to={convertPathUsingParams(routingConfig.operationDataSystemPicker.path, {
            system: props.match.params.dealerSystem,
          })}
          title={`Журнал операций РУСФИНАНС`}
        />
      </div>

      <OperationDataViewTable
        id={props.match.params.id}
        dealerSystem={props.match.params.dealerSystem}
      />
    </div>
  );
  return <AppFullWidthTemplate content={renderContent()} />;
};

export default OperationDataViewPage;
