import { rest } from 'msw';
import { clientLines_GET_ResponseBody } from './clientLines_GET_ResponseBody';

export const rfinfoClientLinesHandlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/rf-info/client-lines`, (req, res, ctx) => {
    console.log('msw-handlers: GET /rf-info/client-lines');
    //    return res(ctx.status(403), ctx.json({ code: ApiErrors.NOT_CONFIRMED_LOGIN }));
    //    return res(ctx.status(403), ctx.json({ code: ApiErrors.NOT_CHOOSED_DEALER }));
    return res(ctx.status(200), ctx.json(clientLines_GET_ResponseBody));
  }),
];
