import React from 'react';
import './StageTracker.css';

interface StageTrackerProps {
  stages: string[];
  currentStage: number;
}

const StageTracker: React.FC<StageTrackerProps> = ({ stages, currentStage }) => {
  const totalStages = stages.length;
  const totalWidth = 1700;
  const stageWidth = totalWidth / totalStages - 190;

  return (
    <div className="stage-tracker">
      {stages.map((stage, index) => (
        <div
          style={{
            marginTop: '45px',
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            width: `${stageWidth}px`,
          }}
        >
          <div key={index} className="stage">
            {index === 0 ? (
              // ? <div style={{ position: 'absolute', right: `${stageWidth/4}px`, width: `${stageWidth/4}px`, height: '4px', backgroundColor: 'black' }}/>
              <div />
            ) : index < currentStage ? (
              <div
                style={{
                  position: 'absolute',
                  right: `${stageWidth / 4}px`,
                  width: `${stageWidth}px`,
                  height: '4px',
                  backgroundColor: 'black',
                }}
              />
            ) : (
              <div
                style={{
                  position: 'absolute',
                  right: `${stageWidth / 4}px`,
                  width: `${stageWidth}px`,
                  height: '4px',
                  backgroundColor: 'lightgray',
                }}
              />
            )}
            {/* { index > 0 && index < currentStage
                ? <div style={{ position: 'absolute', right: `${stageWidth/4}px`, width: `${stageWidth}px`, height: '4px', backgroundColor: 'black' }}/>
                : <div style={{ position: 'absolute', right: `${stageWidth/4}px`,  width: `${stageWidth}px`, height: '4px', backgroundColor: 'lightgray' }}/>
              }                 */}

            <div className="stage-label" style={{ opacity: `${index < currentStage ? 1 : 0.3}` }}>
              {stage}
            </div>
            {index === currentStage - 1 && <div className="stage-circle" />}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StageTracker;
