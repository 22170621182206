import { bindActionCreators } from '@reduxjs/toolkit';
import { ITable } from '@rfb/ui-kit';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import { store } from 'root-store';
import { IOperationDataState, actions, name, selectors } from '../../../store';
import Component, {
  IOperationDataTableActions,
  IOperationDataTableProps,
  IOperationDataTablePropsExternal,
} from './component';

import styles from './assets/styles/index.module.css';

import { getTransformedDataAndTime } from '@rfb/common/lib/utils/format/date/dateAndTime';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { IDTOOperationDataResponse } from 'dto/operation-data';
import { selectors as appSelectors } from 'modules/app/store';
import { getTransformedAmount } from '../../../../../helpers/transform/amount';
import { getTransformedOperationNumberCell, getTypeValue } from '../../../helpers/transform';
import { getTransformedAttachments } from './helpers';

type TOperationDataTableHeader = ITable['headers'][number] & {
  value: keyof IDTOOperationDataResponse['records'][number] | 'upload_errors';
  isExtra?: true;
};

type TOperationDataTableColumn = {
  header: TOperationDataTableHeader;
  transform?: (value: any, row?: IOperationDataState['data'][number]) => any;
};

const mapStateToProps = (
  state: { [name]: IOperationDataState },
  props: { dealerSystem: string }
): IOperationDataTableProps & IOperationDataTablePropsExternal => {
  const dataFilter: IOperationDataState['dataFilter'] = selectors.selectDataFilter(state);
  const filter: IOperationDataState['filter'] = selectors.selectFilter(state);
  const records: IOperationDataState['data'] = selectors.selectOperationData(state);

  const columns: TOperationDataTableColumn[] = [
    {
      header: {
        value: 'attachments',
        title: '',
        type: 'attachment',
        isFixed: true,
        columnClassName: styles.attachmentsColumnData,
      },
      transform: getTransformedAttachments,
    },
    {
      header: {
        value: 'operation_create_date',
        title: 'ДАТА И ВРЕМЯ ОПЕРАЦИИ',
        type: 'sorting',
        sortingType: 'number',
        isFixed: true,
        columnClassName: styles.columnDateAndTime,
      },
      transform: (date: string | null) => (date ? getTransformedDataAndTime(date) : ' '),
    },
    {
      header: {
        value: 'operation_number',
        title: 'НОМЕР ЗАЯВКИ',
        isFixed: true,
        columnClassName: styles.columnDatа,
      },
      transform: getTransformedOperationNumberCell,
    },
    {
      header: {
        value: 'operation_type_code',
        title: 'ВИД ОПЕРАЦИИ',
        isFixed: true,
      },
      transform: getTypeValue,
    },
    {
      header: {
        value: 'operation_status_name',
        title: 'СТАТУС',
        type: 'select',
        filterList: dataFilter.operation_status_name,
        filterValue: filter.operation_status_name,
        isFixed: true,
        columnClassName: styles.columnData,
        onFilterChange: (statusValue: TODO_ANY) =>
          store.dispatch(actions.setFilter({ operation_status_name: statusValue })),
      },
    },
    {
      header: {
        value: 'total_sum',
        title: 'ОБЩАЯ СУММА РУБ.',
        isFixed: true,
        columnClassName: styles.columnData,
      },
      transform: (total_sum: number | null) =>
        total_sum != null ? getTransformedAmount(total_sum) : ' ',
    },
    {
      header: {
        value: 'approved_sum',
        title: 'УТВЕРЖДЁННАЯ СУММА РУБ.',
        isFixed: true,
        columnClassName: styles.columnDataApprovedSum,
      },
      transform: (approved_sum: number | null) =>
        approved_sum != null ? getTransformedAmount(approved_sum) : ' ',
    },
    {
      header: {
        value: 'advance_sum',
        title: 'МИНИМАЛЬНАЯ СУММА АВАНСА РУБ.',
        isFixed: true,
        columnClassName: styles.columnDataAdvancedSum,
      },
      transform: (advance_sum: number | null) =>
        advance_sum != null ? getTransformedAmount(advance_sum) : ' ',
    },
    {
      header: {
        value: 'user_name',
        title: 'ПОЛЬЗОВАТЕЛЬ',
        type: 'input',
        filterValue: filter.user_name,
        isFixed: false,
        columnClassName: styles.columnData,
        columnKey: filter.lastResetTimestamp,
        onFilterChange: (user_name: string | string[]) =>
          fp.some(Boolean, [fp.gte(fp.size(user_name), 3), fp.isEmpty(user_name)])
            ? store.dispatch(actions.setFilter({ user_name }))
            : fp.noop,
      },
    },
  ];

  const rowDataList = fp.pipe(
    fp.map((row: IOperationDataState['data'][number]) => {
      return fp.map((column: TOperationDataTableColumn) => {
        return fp.isFunction(column.transform)
          ? column.transform(fp.path(column.header.value, row), row)
          : fp.path(column.header.value, row);
      }, columns);
    }),
    fp.map((data) => ({ data }))
  )(records);

  const rowConfigList = fp.pipe(fp.map((config) => ({ config })))(records);

  return {
    filter,
    filterTemp: selectors.selectFilterTemp(state),
    isFilteringByPeriod: selectors.selectIsFilteringByPeriod(state),
    headers: fp.map(fp.path('header'), columns),
    rows: fp.pipe(fp.zip(rowConfigList), fp.map(fp.mergeAll))(rowDataList),
    page: filter.page,
    pageCount: selectors.selectPageCount(state),
    dealerSystem: props.dealerSystem,
    requestCount: appSelectors.selectRequestCount(state),
    apiErrorList: selectors.selectErrorListByField(state)('api'),
    isLoading: selectors.selectIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch: any): IOperationDataTableActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
