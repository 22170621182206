import React from 'react';

import { History, AuthTemplate } from '@rfb/ui-kit';

import storage from 'utils/storage';

import ConfirmCode from '../../../modules/confirmation/components/ConfirmCode';

import routingConfig from 'configs/routing';
import { actions } from 'modules/app/store';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import confirmTypes from 'modules/confirmation/configs/confirmTypes';
import { useDispatch } from 'react-redux';

const LoginConfirm = (props: TODO_ANY) => {
  const dispatch = useDispatch();

  const onBackClick = (): void => {
    storage.clearAll();
    window.location.reload();
  };

  const renderContent = (): JSX.Element => (
    <div className={styles.content}>
      <ConfirmCode
        changePersonalDataFlag={true}
        confirmTypeCode={confirmTypes.LOGIN}
        onSuccess={() => {
          props.history.push(routingConfig.main.path);
          dispatch(actions.set({ apiErrorCode: 0 }));
        }}
        onCancel={onBackClick}
      />
    </div>
  );

  const renderLeftSidebar = (): JSX.Element => (
    <div onClick={onBackClick}>
      <History type="back" to={routingConfig.login.path} />
    </div>
  );

  return <AuthTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default LoginConfirm;
