import React from 'react';
import { useDispatch } from 'react-redux';
import styles from '../assets/styles/index.module.css';
import { actions } from '../../../store';

export const LinkCell = (props: { requestInvoice: string }) => {
  const dispatch = useDispatch();

  return (
    <div
      className={styles.link}
      onClick={() => {
        dispatch(actions.getRequestFileBody({ requestInvoice: props.requestInvoice }));
      }}
    >
      Открыть
    </div>
  );
};
