import { rest } from 'msw';
import { rfinfoCode } from './rfinfoCode';

export const rfinfoCodeHandlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/rf-info/code`, (req, res, ctx) => {
    console.log('msw-handlers: GET /rf-info/code');
    return res(ctx.status(200), ctx.json(rfinfoCode));
    //return res(ctx.status(404), ctx.json(rfinfoCode));
  }),
];
