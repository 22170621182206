var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var LockUnchecked = function (props) { return (React.createElement("svg", __assign({}, props, { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("rect", { x: "6.75", y: "13.75", width: "14.5", height: "9.5", stroke: "#C4C4C4", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M1.75 14.5V8.75C1.75 6.40279 3.65279 4.5 6 4.5V4.5C8.34721 4.5 10.25 6.40279 10.25 8.75V14.5", stroke: "#C4C4C4", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M12.0001 17.4999L16.0002 17.4999M14.0002 17.4999L14.0002 20.3283", stroke: "#C4C4C4", strokeWidth: "1.5" }))); };
export default LockUnchecked;
