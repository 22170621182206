import React from 'react';

import styles from './assets/styles/index.module.css';
import { toDate } from '@rfb/common/lib/utils/format/date';

export interface IAccountsOpenDateProps {
  open?: string;
  close?: string;
}

const Dates = (props: IAccountsOpenDateProps) => {

  return props.open
    ? <div className={styles.date}>
      <div className={styles.openDate}>
        <div className={styles.title}>Дата открытия:</div>
        <div className={styles.value}>{toDate(props.open)}</div>
      </div>
      {props.close && <div className={styles.closeDate}>
        <div className={styles.title}>Дата закрытия:</div>
        <div className={styles.value}>{toDate(props.close)}</div>
      </div>}
    </div>
    : null;
};

export default Dates;
