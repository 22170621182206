import React from 'react';
import ReactPaginate from 'react-paginate';
import cn from 'classnames';
import ArrowPrevIcon from '../../../../icons/ArrowPrev';
import ArrowNextIcon from '../../../../icons/ArrowNext';
import './assets/styles/override.css';
import styles from './assets/styles/index.module.css';
export var Pagination = function (props) {
    var renderLabelPrev = function () { return React.createElement(ArrowPrevIcon, null); };
    var renderLabelNext = function () { return React.createElement(ArrowNextIcon, null); };
    var mainClassNameOverride = 'ui-kit-table-pagination';
    var pageClassNameOverride = 'ui-kit-table-pagination__page';
    return (React.createElement("div", { className: cn(styles.main, mainClassNameOverride) },
        React.createElement(ReactPaginate, { pageCount: props.pageCount, pageRangeDisplayed: 4, marginPagesDisplayed: 1, forcePage: props.page - 1, previousLabel: renderLabelPrev(), previousClassName: cn(styles.paginatePage, pageClassNameOverride), nextLabel: renderLabelNext(), nextClassName: cn(styles.paginatePage, pageClassNameOverride), containerClassName: styles.paginateContainer, pageClassName: cn(styles.paginatePage, pageClassNameOverride), activeClassName: styles.paginatePageActive, breakClassName: cn(styles.paginatePage, styles.paginateBreak, pageClassNameOverride), onPageChange: function (_a) {
                var selected = _a.selected;
                return props.onPageChange(selected + 1);
            } })));
};
export default Pagination;
