import React from 'react';
import CalendarComponent from 'react-calendar';
import cn from 'classnames';
import fp from 'lodash/fp';
import { getTransformedApiDate, getTransformedDateObject } from '@rfb/common';
import NextIcon from '../../icons/Next';
import Next2Icon from '../../icons/NextDouble';
import 'react-calendar/dist/Calendar.css';
import './assets/styles/override.css';
import styles from './assets/styles/index.module.css';
var locale = 'ru-RU';
export var Calendar = function (props) {
    var renderNextIcon = function () { return (React.createElement("div", null,
        React.createElement(NextIcon, null))); };
    var renderNext2Icon = function () { return (React.createElement("div", null,
        React.createElement(Next2Icon, null))); };
    var renderPrevIcon = function () { return (React.createElement("div", { className: styles.prevIcon },
        React.createElement(NextIcon, null))); };
    var renderPrev2Icon = function () { return (React.createElement("div", { className: styles.prevIcon },
        React.createElement(Next2Icon, null))); };
    /*
      NOTICE
      Результатом функции, передаваемой в formatMonthYear, должен быть string
      Нам необходимо вернуть JSX.Element для стилизации месяца и года
      По этой причине используется TODO_ANY
    */
    var renderMonthYear = function (_, date) {
        var _a, _b;
        var now = new Date();
        var isCurrentYear = fp.isEqual(date.getFullYear(), now.getFullYear());
        var isCurrentMonth = fp.isEqual(date.getMonth(), now.getMonth());
        var monthClassNames = cn((_a = {},
            _a[styles.current] = fp.every(Boolean, [isCurrentMonth, isCurrentYear]),
            _a));
        var yearClassNames = cn((_b = {},
            _b[styles.current] = isCurrentYear,
            _b));
        return (React.createElement("div", { className: styles.monthYear },
            React.createElement("span", { className: monthClassNames }, fp.capitalize(date.toLocaleString(locale, { month: 'long' }))),
            ' ',
            React.createElement("span", { className: yearClassNames }, date.getFullYear())));
    };
    var onChange = function (value) {
        if (fp.isArray(value)) {
            var _a = fp.sortBy(fp.identity, value), startDate = _a[0], endDate = _a[1];
            props.onChange([
                getTransformedApiDate(startDate.toString()),
                getTransformedApiDate(endDate.toString()),
            ]);
            return;
        }
        props.onChange(getTransformedApiDate(value.toString()));
    };
    var getValue = function () {
        if (fp.isEmpty(props.value))
            return undefined;
        if (fp.isArray(props.value))
            return fp.map(getTransformedDateObject, props.value);
        return getTransformedDateObject(props.value);
    };
    var isRangeType = fp.isEqual(props.type, 'range');
    var mainClassNames = cn([styles.calendar, 'ui-kit-calendar']);
    return (React.createElement("div", { className: cn(props.wrapperClassName) },
        React.createElement(CalendarComponent, { className: mainClassNames, value: getValue(), selectRange: isRangeType, returnValue: isRangeType ? 'range' : 'start', prevLabel: renderPrevIcon(), prev2Label: renderPrev2Icon(), nextLabel: renderNextIcon(), next2Label: renderNext2Icon(), tileClassName: styles.tile, formatMonthYear: renderMonthYear, locale: locale, onChange: onChange })));
};
Calendar.defaultProps = {
    type: 'single',
};
export default Calendar;
