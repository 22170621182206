import fp from 'lodash/fp';
import React, { SVGProps, useEffect, useRef, useState } from 'react';

import { configureValidator, runRulesChain, TUsedCarSettings } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import {
  Button,
  ButtonWithIcon,
  Condition,
  DatePickerLargeTextSize,
  Dropdown,
  Input,
  MessageList,
  Switch,
  SwitchPositionEnum,
} from '@rfb/ui-kit';
import { RequestDataUsedEditForm } from 'dto/financial-block';
import {
  brandRules,
  carBodyNumberRules,
  requestAmountRules,
  requestAttachmentPdfRules,
  requestCarPassportDateRules,
  requestCodeRules,
  requestElectricalEnginePowerRules,
  requestEngineDisplacementRules,
  requestEnginePowerRules,
  requestMileageRules,
  requestModelRules,
  requestPdfRules,
  requestVinRules,
} from 'modules/financial-block/configs/validation';
import styles from './assets/styles/index.module.css';

type TOption = {
  label: string;
  value: number;
};

export interface IAllCreateFormProps {
  isLoading: boolean;
  usedCarSettings: TUsedCarSettings;
  requestDataId?: number;
  requestCode: string;
  requestBrand: string;
  requestBrandId: number;
  requestModel: string;
  requestModelId: number;
  notVin: boolean;
  requestVin: string;
  requestCarPassportDate: string;
  requestEngineDisplacement: string;
  requestEnginePower: string;
  requestElectricalEnginePower: string;
  requestMileage: string;
  requestAmount: string;
  requestCodeOptions: any[];
  requestModelOptions: any[];
  requestBrandOptions: TODO_ANY[];
  requestCodeErrorList: string[];
  requestBrandErrorList: string[];
  requestModelErrorList: string[];
  requestVinErrorList: string[];
  requestCarPassportDateErrorList: string[];
  requestEngineDisplacementErrorList: string[];
  requestEnginePowerErrorList: string[];
  requestElectricalEnginePowerErrorList: string[];
  requestMileageErrorList: string[];
  requestAmountErrorList: string[];
  requestContractPdfErrorList: string[];
  requestActOfAcceptancePdfErrorList: string[];
  requestProofOfPaymentPdfErrorList: string[];
  requestInvoiceFileName?: string;
  requestContractPdf: File;
  requestContractFileName: string;
  requestContractFileId: string;
  requestActOfAcceptancePdf: File;
  requestActOfAcceptanceFileName: string;
  requestActOfAcceptanceFileId: string;
  requestProofOfPaymentPdf: File;
  requestProofOfPaymentFileName: string;
  requestProofOfPaymentFileId: string;
  apiErrorList: string[];
}
export interface IAllCreateFormActions {
  actions: {
    set: Function;
    setForm: Function;
    resetForm: Function;
    resetVin: Function;
    createRequestDataUsed: Function;
    updateRequestDataUsed: Function;
    getRequestCodeOptions: Function;
    getBrandOptions: Function;
    getBrandModelOptions: Function;
    setError: Function;
    getUsedCarSettings: Function;
  };
}
export interface IAllCreateFormPropsExternal {
  requestDataId?: number;
  history: any;
  brand_type: string;
  form: 'create' | 'edit';
  backUrl: string;
}

enum EngineType {
  DVS = 'ДВС',
  GIBRID = 'Гибрид',
  ELECTRO = 'Электро',
  NONE = 'Не установлен',
}

function getEngineType(
  props: IAllCreateFormProps & IAllCreateFormActions & IAllCreateFormPropsExternal
): string {
  let result: EngineType;
  if (props.form === 'edit') {
    if (!!props.requestEnginePower) {
      if (!!props.requestElectricalEnginePower) {
        result = EngineType.GIBRID;
      } else {
        result = EngineType.DVS;
      }
    } else {
      if (!!props.requestElectricalEnginePower) {
        result = EngineType.ELECTRO;
      } else {
        result = EngineType.NONE;
      }
    }
  } else {
    result = EngineType.DVS;
  }
  return result;
}

const AddUsedCarForm = (
  props: IAllCreateFormProps & IAllCreateFormActions & IAllCreateFormPropsExternal
) => {
  console.log(`AddUsedCarForm BEGIN: props = ${JSON.stringify(props)}`);
  const [isContractAttached, setIsContractAttached] = useState(false);
  const [isActAttached, setIsActAttached] = useState(false);
  const [isProofOfPaymentAttached, setIsProofOfPaymentAttached] = useState(false);
  const isEdit = () => props.form === 'edit';
  const [engineType, setEngineType] = useState(getEngineType(props));
  const [notVin, setNotVin] = useState(props.notVin);
  console.log(`AddUsedCarForm: props.notVin = ${JSON.stringify(props.notVin)}`);
  console.log(`AddUsedCarForm: notVin = ${JSON.stringify(notVin)}`);

  useEffect(() => {
    console.log(`AddUsedCarForm/useEffect BEGIN: props = ${JSON.stringify(props)}`);
    if (!props.requestBrandOptions.length) props.actions.getBrandOptions();
    props.actions.getUsedCarSettings();
    if (isEdit()) {
      props.actions.setForm({
        requestContractPdf: props.requestContractFileName
          ? new File([], props.requestContractFileName)
          : null,
        requestActOfAcceptancePdf: props.requestActOfAcceptanceFileName
          ? new File([], props.requestActOfAcceptanceFileName)
          : null,
        requestProofOfPaymentPdf: props.requestProofOfPaymentFileName
          ? new File([], props.requestProofOfPaymentFileName)
          : null,
      });
    }
    console.log(`AddUsedCarForm/useEffect END`);
    return () => {
      props.actions.resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validators = {
    requestCode: configureValidator({
      name: 'requestCode',
      rule: requestCodeRules,
      setError: props.actions.setError,
    }),
    requestBrand: configureValidator({
      name: 'requestBrand',
      rule: brandRules,
      setError: props.actions.setError,
    }),
    requestModel: configureValidator({
      name: 'requestModel',
      rule: requestModelRules,
      setError: props.actions.setError,
    }),
    requestVin: configureValidator({
      name: 'requestVin',
      rule: requestVinRules,
      setError: props.actions.setError,
    }),
    carBodyNumber: configureValidator({
      name: 'requestVin',
      rule: carBodyNumberRules,
      setError: props.actions.setError,
    }),
    requestCarPassportDate: configureValidator({
      name: 'requestCarPassportDate',
      rule: requestCarPassportDateRules(props.usedCarSettings.max_age),
      setError: props.actions.setError,
    }),
    requestEngineDisplacement: configureValidator({
      name: 'requestEngineDisplacement',
      rule: requestEngineDisplacementRules,
      setError: props.actions.setError,
    }),
    requestEnginePower: configureValidator({
      name: 'requestEnginePower',
      rule: requestEnginePowerRules,
      setError: props.actions.setError,
    }),
    requestElectricalEnginePower: configureValidator({
      name: 'requestElectricalEnginePower',
      rule: requestElectricalEnginePowerRules,
      setError: props.actions.setError,
    }),
    requestMileage: configureValidator({
      name: 'requestMileage',
      rule: requestMileageRules(props.usedCarSettings.max_run),
      setError: props.actions.setError,
    }),
    requestAmount: configureValidator({
      name: 'requestAmount',
      rule: requestAmountRules(props.usedCarSettings.max_cost),
      setError: props.actions.setError,
    }),
    requestContractPdf: configureValidator({
      name: 'requestContractPdf',
      rule: requestAttachmentPdfRules,
      setError: props.actions.setError,
    }),
    requestActOfAcceptancePdf: configureValidator({
      name: 'requestActOfAcceptancePdf',
      rule: requestAttachmentPdfRules,
      setError: props.actions.setError,
    }),
    requestProofOfPaymentPdf: configureValidator({
      name: 'requestProofOfPaymentPdf',
      rule: requestAttachmentPdfRules,
      setError: props.actions.setError,
    }),
    requestPdf: configureValidator({
      name: 'requestPdf',
      rule: requestPdfRules,
      setError: props.actions.setError,
    }),
  };

  const saveRequestData = (values: any) => {
    console.log(`AddUsedCarForm/saveRequestData BEGIN: rules = ${JSON.stringify(values)}`);
    if (props.form === 'create') {
      props.actions.createRequestDataUsed(values);
    } else if (isEdit()) {
      props.actions.updateRequestDataUsed(values);
    }
    console.log(`AddUsedCarForm/saveRequestData END`);
  };

  const handleSubmit = () => {
    console.log(`AddUsedCarForm/handleSubmit BEGIN`);
    const sendData: RequestDataUsedEditForm = {
      request_data_id: isEdit() ? props.requestDataId : undefined,
      brand: props.requestBrand,
      brand_id: props.requestBrandId,
      model: props.requestModel,
      model_id: props.requestModelId,
      engine_volume: props.requestEngineDisplacement,
      engine_power: props.requestEnginePower,
      electrical_engine_power: props.requestElectricalEnginePower,
      car_run: props.requestMileage,
      car_passport_date: props.requestCarPassportDate,
      code: props.requestCode,
      vin: props.requestVin,
      not_vin: notVin ? 1 : 0,
      amount: props.requestAmount,
      contractPdf: isContractAttached ? props.requestContractPdf : undefined,
      contractPdfId: isContractAttached ? props.requestContractFileId : undefined,
      actOfAcceptancePdf: isActAttached ? props.requestActOfAcceptancePdf : undefined,
      actOfAcceptancePdfId: isActAttached ? props.requestActOfAcceptanceFileId : undefined,
      proofOfPaymentPdf: isProofOfPaymentAttached ? props.requestProofOfPaymentPdf : undefined,
      proofOfPaymentPdfId: isProofOfPaymentAttached ? props.requestProofOfPaymentFileId : undefined,
    };
    console.log(`AddUsedCarForm/handleSubmit: sendData = ${JSON.stringify(sendData)}`);

    const rules = [
      validators.requestAmount(sendData.amount),
      validators.requestBrand(sendData.brand),
      validators.requestModel(sendData.model),
      validators.requestCarPassportDate(sendData.car_passport_date),
      validators.requestMileage(sendData.car_run),
    ];
    console.log(`AddUsedCarForm/handleSubmit: rules = ${JSON.stringify(rules)}`);

    rules.push(
      notVin ? validators.carBodyNumber(sendData.vin) : validators.requestVin(sendData.vin)
    );

    if (engineType === EngineType.ELECTRO) {
      rules.push(validators.requestElectricalEnginePower(sendData.electrical_engine_power));
    } else if (engineType === EngineType.DVS) {
      rules.push(validators.requestEnginePower(sendData.engine_power));
      rules.push(validators.requestEngineDisplacement(sendData.engine_volume));
    } else if (engineType === EngineType.GIBRID) {
      rules.push(validators.requestEnginePower(sendData.engine_power));
      rules.push(validators.requestEngineDisplacement(sendData.engine_volume));
      rules.push(validators.requestElectricalEnginePower(sendData.electrical_engine_power));
    }
    console.log(`AddUsedCarForm/handleSubmit: rules = ${JSON.stringify(rules)}`);

    if (isEdit()) {
      isContractAttached && rules.push(validators.requestContractPdf(sendData.contractPdf));
      isActAttached &&
        rules.push(validators.requestActOfAcceptancePdf(sendData.actOfAcceptancePdf));
      isProofOfPaymentAttached &&
        rules.push(validators.requestProofOfPaymentPdf(sendData.proofOfPaymentPdf));
    } else {
      rules.push(validators.requestContractPdf(sendData.contractPdf));
      rules.push(validators.requestActOfAcceptancePdf(sendData.actOfAcceptancePdf));
      rules.push(validators.requestProofOfPaymentPdf(sendData.proofOfPaymentPdf));
    }

    runRulesChain(rules)
      .then(() =>
        saveRequestData({
          requestDataId: props.requestDataId, // TODO для создания не нужен ?
          data: sendData,
          brand_type: props.brand_type,
          onSuccess: () => {
            props.history.push(props.backUrl);
          },
        })
      )
      .catch(fp.noop);
    console.log(`AddUsedCarForm/handleSubmit END`);
  };

  const onBrandChange = (brand: string, option: any): void => {
    if (props.requestBrandId != option.value)
      validators.requestBrand(brand).finally((): void => {
        props.actions.setForm({
          brandUsed: brand,
          requestBrand: option.label,
          requestBrandId: option.value,
          requestModel: null,
          requestModelId: null,
          requestVin: null,
          requestCarPassportDate: '',
          requestEngineDisplacement: '',
          requestEnginePower: '',
          requestElectricalEnginePower: '',
          requestMileage: '',
          requestAmount: '',
          requestActOfAcceptancePdf: null,
          requestActOfAcceptanceFileName: '',
          requestContractPdf: null,
          requestContractFileName: '',
          requestProofOfPaymentPdf: null,
          requestProofOfPaymentFileName: '',
        });
        setIsContractAttached(true);
        setIsActAttached(true);
        setIsProofOfPaymentAttached(true);
      });
    props.actions.getBrandModelOptions(brand);
  };

  const onModelChange = (model: string, option: any): void => {
    validators.requestModel(model).finally((): void =>
      props.actions.setForm({
        modelUsed: model,
        requestModel: option.label,
        requestModelId: option.value,
      })
    );
  };
  const onVinChange = (requestVin: string): void => {
    validators.requestVin(requestVin).finally((): void => props.actions.setForm({ requestVin }));
  };
  const onCarBodyNumberChange = (carBodyNumber: string): void => {
    validators
      .carBodyNumber(carBodyNumber)
      .finally((): void => props.actions.setForm({ requestVin: carBodyNumber }));
  };
  const onCarPassportDateChange = (requestCarPassportDate: string): void =>
    validators
      .requestCarPassportDate(requestCarPassportDate)
      .finally((): void => props.actions.setForm({ requestCarPassportDate }));

  const onEngineTypeChange = (engineType: string, option: any): void => setEngineType(engineType);

  function onEngineDisplacementChange(requestEngineDisplacement: string) {
    validators
      .requestEngineDisplacement(requestEngineDisplacement)
      .finally((): void => props.actions.setForm({ requestEngineDisplacement }));
  }

  function onEnginePowerChange(requestEnginePower: string) {
    validators
      .requestEnginePower(requestEnginePower)
      .finally((): void => props.actions.setForm({ requestEnginePower }));
  }

  function onElectricalEnginePowerChange(requestElectricalEnginePower: string) {
    validators
      .requestElectricalEnginePower(requestElectricalEnginePower)
      .finally((): void => props.actions.setForm({ requestElectricalEnginePower }));
  }

  function onMileageChange(requestMileage: string) {
    validators
      .requestMileage(requestMileage)
      .finally((): void => props.actions.setForm({ requestMileage }));
  }

  const onAmountChange = (requestAmount: string): void => {
    validators.requestAmount(requestAmount);
    props.actions.setForm({ requestAmount });
  };

  const hasErrors =
    !fp.isEmpty(props.requestBrandErrorList) ||
    !fp.isEmpty(props.requestModelErrorList) ||
    !fp.isEmpty(props.requestCarPassportDateErrorList) ||
    !fp.isEmpty(props.requestContractPdfErrorList) ||
    !fp.isEmpty(props.requestActOfAcceptancePdfErrorList) ||
    !fp.isEmpty(props.requestProofOfPaymentPdfErrorList) ||
    !fp.isEmpty(props.requestEngineDisplacementErrorList) ||
    !fp.isEmpty(props.requestEnginePowerErrorList) ||
    !fp.isEmpty(props.requestElectricalEnginePowerErrorList) ||
    !fp.isEmpty(props.requestMileageErrorList) ||
    !fp.isEmpty(props.requestVinErrorList) ||
    !fp.isEmpty(props.requestAmountErrorList);

  console.log(`AddUsedCarForm: hasErrors = ${JSON.stringify(hasErrors)}`);

  function FileUpload(props: {
    name: string;
    attachment: File | null;
    addAttachment(file: File): void;
    removeAttachment(): void;
    errors: string[];
  }) {
    const innerRef = useRef<HTMLInputElement>(null);

    const handleClick = () => innerRef.current?.click();

    const onAddAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files: FileList | null = event.target.files;
      if (files && files.length) {
        props.addAttachment(files[0]);
      }
    };

    function Attachment() {
      const Close = (props: SVGProps<SVGSVGElement>) => (
        <svg
          {...props}
          width="12"
          height="12"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.09097 0L12 10.9091L10.9091 12L7.18178e-05 1.09091L1.09097 0Z"
            fill="currentColor"
          />
          <path
            d="M0 10.9091L10.909 1.83914e-07L11.9999 1.09091L1.0909 12L0 10.9091Z"
            fill="currentColor"
          />
        </svg>
      );

      return props.attachment ? (
        <>
          <div
            style={{
              marginTop: 15,
              marginLeft: 15,
              marginRight: 5,
            }}
          >
            {props.attachment.name}
          </div>
          <Close
            className={styles.attachmentDeleteIcon}
            onClick={() => {
              props.removeAttachment();
            }}
          />
        </>
      ) : null;
    }

    return (
      <div aria-label="form field">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={styles.uploadButton}>
            <ButtonWithIcon
              wrapperClassName={styles.buttonAdd}
              type="add"
              text={props.name}
              isActive
              onClick={handleClick}
            />
            <input
              style={{ display: 'none' }}
              type="file"
              accept=".pdf"
              multiple
              ref={innerRef}
              onChange={(e) => onAddAttachment(e)}
            />
          </div>
          <Attachment />
        </div>
        <MessageList type="error" messages={props.errors} />
      </div>
    );
  }

  function setNotVinIsChecked(isNotVin: boolean): void {
    console.log(`AddUsedCarForm/setNotVinIsChecked BEGIN: value = ${JSON.stringify(isNotVin)}`);
    setNotVin(isNotVin);
    props.actions.resetVin();
    console.log(`AddUsedCarForm/setNotVinIsChecked END`);
  }

  return (
    <div>
      <div className={styles.form} role="form">
        <div aria-label="form field">
          <Dropdown
            label="Марка"
            value={props.requestBrand}
            options={props.requestBrandOptions}
            hasError={!fp.isEmpty(props.requestBrandErrorList)}
            onChange={onBrandChange}
          />
          <MessageList type="error" messages={props.requestBrandErrorList} />
        </div>

        <div aria-label="form field">
          <Dropdown
            label="Модель"
            value={props.requestModel}
            options={props.requestModelOptions}
            hasError={!fp.isEmpty(props.requestModelErrorList)}
            onChange={onModelChange}
          />
          <MessageList type="error" messages={props.requestModelErrorList} />
        </div>
        <div aria-label="form field">
          <Switch
            prefix="VIN автомобиля"
            postfix="Номер кузова"
            isChecked={notVin}
            position={SwitchPositionEnum.CENTER}
            onChange={setNotVinIsChecked}
          />
          <Condition value={!notVin}>
            <Input
              keyUnique="requestVin"
              type="vin-number"
              label="VIN автомобиля"
              name="requestVin"
              value={props.requestVin}
              hasError={!fp.isEmpty(props.requestVinErrorList)}
              onChange={onVinChange}
            />
          </Condition>
          <Condition value={notVin}>
            <Input
              keyUnique="carBodyNumber"
              type="text"
              label="Номер кузова"
              name="carBodyNumber"
              value={props.requestVin}
              hasError={!fp.isEmpty(props.requestVinErrorList)}
              onChange={onCarBodyNumberChange}
            />
            <div style={{ marginTop: '5px' }}>
              Номер кузова указывается при отсутствии идентификационного номера (VIN) Автомобиля
            </div>
          </Condition>
          <MessageList type="error" messages={props.requestVinErrorList} />
        </div>

        <div aria-label="form field">
          <div
            style={{
              fontSize: '18px',
              display: 'flex',
              borderBottom: '2px solid var(--brand-color-black)',
            }}
          >
            <div
              style={{
                marginTop: '30px',
                opacity: '0.4',
              }}
            >
              Дата выдачи ПТС/ЭПТС
            </div>
            <DatePickerLargeTextSize
              wrapperClassName={styles.dateField}
              value={props.requestCarPassportDate}
              onChange={onCarPassportDateChange}
            />
          </div>
          <MessageList type="error" messages={props.requestCarPassportDateErrorList} />
        </div>
        <div aria-label="form field">
          <Dropdown
            label="Тип двигателя"
            value={engineType}
            options={[
              { value: EngineType.DVS, label: 'ДВС' },
              { value: EngineType.GIBRID, label: 'Гибрид' },
              { value: EngineType.ELECTRO, label: 'Электро' },
            ]}
            onChange={onEngineTypeChange}
          />
          <MessageList type="error" messages={[]} />
        </div>

        <Condition value={engineType === EngineType.DVS || engineType === EngineType.GIBRID}>
          <div aria-label="form field">
            <Input
              keyUnique="requestEnginePower"
              label="Мощность двигателя (л.с.)"
              name="requestEnginePower"
              value={props.requestEnginePower}
              hasError={!fp.isEmpty(props.requestEnginePowerErrorList)}
              onChange={onEnginePowerChange}
            />
            <MessageList type="error" messages={props.requestEnginePowerErrorList} />
          </div>
        </Condition>
        <Condition value={engineType === EngineType.ELECTRO || engineType === EngineType.GIBRID}>
          <div aria-label="form field">
            <Input
              keyUnique="requestElectricalEnginePower"
              label="Мощность двигателя (кВ.ч)"
              name="requestElectricalEnginePower"
              value={props.requestElectricalEnginePower}
              hasError={!fp.isEmpty(props.requestElectricalEnginePowerErrorList)}
              onChange={onElectricalEnginePowerChange}
            />
            <MessageList type="error" messages={props.requestElectricalEnginePowerErrorList} />
          </div>
        </Condition>
        <Condition value={engineType === EngineType.DVS || engineType === EngineType.GIBRID}>
          <div aria-label="form field">
            <Input
              keyUnique="requestEngineDisplacement"
              label="Рабочий объём двигателя (куб.см)"
              name="requestEngineDisplacement"
              value={props.requestEngineDisplacement}
              hasError={!fp.isEmpty(props.requestEngineDisplacementErrorList)}
              onChange={onEngineDisplacementChange}
            />
            <MessageList type="error" messages={props.requestEngineDisplacementErrorList} />
          </div>
        </Condition>
        <div aria-label="form field">
          <Input
            keyUnique="requestMileage"
            label="Пробег (км)"
            name="requestMileage"
            value={props.requestMileage}
            hasError={!fp.isEmpty(props.requestMileageErrorList)}
            onChange={onMileageChange}
          />
          <MessageList type="error" messages={props.requestMileageErrorList} />
        </div>

        <div aria-label="form field">
          <Input
            type="money"
            keyUnique="requestAmount"
            label="Цена по договору приобретения (руб)"
            name="requestAmount"
            value={props.requestAmount}
            hasError={!fp.isEmpty(props.requestAmountErrorList)}
            onChange={onAmountChange}
          />
          <MessageList type="error" messages={props.requestAmountErrorList} />
        </div>

        <h2 className={styles.fileSizeWarning}>
          Размер каждого загружаемого файла не должен превышать 5 Мегабайт
        </h2>

        {/*TODO Устранить boilerplate*/}
        <FileUpload
          name="Вложить договор приобретения, PDF"
          attachment={props.requestContractPdf}
          addAttachment={(requestContractPdf) => {
            validators.requestContractPdf(requestContractPdf).finally((): void => {
              props.actions.setForm({ requestContractPdf });
              setIsContractAttached(true);
            });
          }}
          removeAttachment={() => {
            validators.requestContractPdf(null).finally((): void => {
              props.actions.setForm({ requestContractPdf: null });
              setIsContractAttached(false);
            });
          }}
          errors={props.requestContractPdfErrorList}
        />

        <FileUpload
          name="Вложить акт приёма-передачи, PDF"
          attachment={props.requestActOfAcceptancePdf}
          addAttachment={(requestActOfAcceptancePdf) => {
            validators.requestActOfAcceptancePdf(requestActOfAcceptancePdf).finally((): void => {
              props.actions.setForm({ requestActOfAcceptancePdf });
              setIsActAttached(true);
            });
          }}
          removeAttachment={() => {
            setIsActAttached(false);
            validators.requestActOfAcceptancePdf(null).finally((): void => {
              props.actions.setForm({ requestActOfAcceptancePdf: null });
              setIsActAttached(false);
            });
          }}
          errors={props.requestActOfAcceptancePdfErrorList}
        />
        <FileUpload
          name="Вложить документ, подтверждающий оплату, PDF"
          attachment={props.requestProofOfPaymentPdf}
          addAttachment={(requestProofOfPaymentPdf) => {
            validators.requestProofOfPaymentPdf(requestProofOfPaymentPdf).finally((): void => {
              props.actions.setForm({ requestProofOfPaymentPdf });
              setIsProofOfPaymentAttached(true);
            });
          }}
          removeAttachment={() => {
            validators.requestProofOfPaymentPdf(null).finally((): void => {
              props.actions.setForm({ requestProofOfPaymentPdf: null });
              setIsProofOfPaymentAttached(false);
            });
          }}
          errors={props.requestProofOfPaymentPdfErrorList}
        />

        <Condition value={!fp.isEmpty(props.apiErrorList)}>
          <MessageList type="error" messages={props.apiErrorList} />
        </Condition>

        <Button
          isDisabled={props.isLoading || hasErrors}
          wrapperClassName={styles.button}
          type="submit"
          text="Сохранить"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default AddUsedCarForm;
