import React, { useEffect, useMemo, useState } from 'react';

import fp from 'lodash/fp';

import { Button, Condition, Form, Input, Loader, MessageList, useTimer } from '@rfb/ui-kit';

import { configureValidator, runRulesChain } from 'helpers/validation';

import { codeRules } from '../../../confirmation/configs/validation';

import cn from 'classnames';
import { DTOCodeConfirmationRequest } from 'dto/confirmcode';
import styles from './assets/styles/index.module.css';

export interface ConfirmCodeProps {
  factorUrl: string;
  phoneNumber: string;
  code: string;
  isSending: boolean;
  isLoading: boolean;
  isBlocked: boolean;
  codeErrorList: string[];
  apiErrorList: string[];
}

export interface ConfirmCodePropsExternal {
  header?: string;
  changePersonalDataFlag?: boolean;
  confirmTypeCode: string;
  timerStartValue?: number;
  onSuccess: Function;
  onFailure?: Function;
  onCancel: Function;
}

export interface ConfirmCodeActions {
  actions: {
    confirm: Function;
    codeRequest: Function;
    reset: Function;
    set: Function;
    setError: Function;
  };
}

const formatPhoneNumber = (value: string): string => {
  if (!value) {
    return value;
  }

  // Check if the input is of correct (takes only 79999999999 numbers now)
  const match = value.match(/^(7|)(\d{3})([*]{3})([*]{2})(\d{2})$/);

  if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    const intlCode = match[1] ? '+7 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
  }
  return '';
};

interface LinkButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  disabled: boolean;
  className?: string;
}

const LinkButton = ({ disabled, className, ...props }: LinkButtonProps) => {
  return (
    <a
      className={cn(
        {
          [styles.link]: !disabled,
          [styles.disabledlink]: disabled,
        },
        className
      )}
      {...props}
    >
      {props.children}
    </a>
  );
};

const ConfirmCode = ({
  timerStartValue = 300,
  ...props
}: ConfirmCodeProps & ConfirmCodePropsExternal & ConfirmCodeActions) => {
  const [isConfirmSent, setConfirmSent] = useState(false); // TODO Сделать через isBlocked ?
  useEffect(() => {
    return () => {
      props.actions.reset();
    };
    // ESLINT Необходимо выполнение только в момент размонтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.actions.codeRequest({
      data: {
        type_confirm_code: props.confirmTypeCode,
      },
      onSuccess: () => timer?.restart(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validators = {
    code: configureValidator({
      name: 'code',
      rule: codeRules,
      setError: props.actions.setError,
    }),
  };

  const timer = useTimer({
    startTime: timerStartValue,
    active: true,
  });

  useEffect(() => {
    if (timer.secondsLeft === 0) {
      props.actions.set({ isBlocked: false });
      setConfirmSent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const onCodeChange = (code: string): void => {
    validators.code(code).finally((): void => props.actions.set({ code }));
  };

  const onFormSubmit = (): void => {
    const data: DTOCodeConfirmationRequest = {
      factor_url: props.factorUrl,
      confirm_code: props.code,
      type_confirm_code: props.confirmTypeCode,
    };
    const rules = [validators.code(props.code)];
    runRulesChain(rules).then(() => {
      // props.actions.confirm({ data, onSuccess: props.onSuccess, onFailure: () => setConfirmSent(false) });
      props.actions.confirm({
        data,
        onSuccess: props.onSuccess,
        onFailure: () => {
          props.onFailure!();
          return setConfirmSent(false);
        },
      });
      setConfirmSent(true);
    });
  };

  const formattedPhoneNumber = useMemo(
    () => formatPhoneNumber(props.phoneNumber),
    [props.phoneNumber]
  );

  if (props.isLoading) {
    return <Loader wrapperClassName={styles.main} />;
  }

  return (
    <div className={styles.main}>
      {props.header && (
        <div className={styles.header}>
          <h1>{props.header}</h1>
        </div>
      )}
      <div className={styles.title}>
        <h2>
          На ваш Номер мобильного телефона{' '}
          <span className={styles.titlephone}>{formattedPhoneNumber}</span>
        </h2>
        <h2>отправлено сообщение с Кодом подтверждения</h2>
      </div>
      <Condition
        value={props.changePersonalDataFlag !== undefined ? props.changePersonalDataFlag : false}
      >
        <span className={styles.smallText}>
          Если Ваш номер телефона изменился, необходимо направить{' '}
          <LinkButton
            disabled={false}
            onClick={() => {
              window.open(
                'https://api.rosbank-auto.ru/doc/shablon-zayavleniya-na-izmenenie-personalnykh-dannykh.docx',
                '_blank'
              );
            }}
          >
            заявление на изменение персональных данных
          </LinkButton>{' '}
          в Банк.
        </span>
      </Condition>

      <div role="form">
        <Form onSubmit={onFormSubmit}>
          <Input
            wrapperClassName={styles.password}
            type="code"
            label="Введите код"
            value={props.code}
            hasError={!fp.isEmpty(props.codeErrorList)}
            onChange={onCodeChange}
            disabled={isConfirmSent || props.isBlocked}
          />
          <MessageList type="error" messages={props.codeErrorList} />

          <Condition value={!fp.isEmpty(props.apiErrorList)}>
            <MessageList type="error" messages={props.apiErrorList} />
          </Condition>

          <Button
            wrapperClassName={styles.button}
            type="submit"
            text="Подтвердить"
            isDisabled={isConfirmSent || props.isBlocked}
          />

          <LinkButton
            className={styles.linkMargin}
            disabled={isConfirmSent}
            onClick={() => {
              !isConfirmSent && props.onCancel();
            }}
          >
            Отмена
          </LinkButton>

          <LinkButton
            className={styles.linkMargin}
            disabled={props.isSending || timer.secondsLeft !== 0 || props.isBlocked}
            onClick={() => {
              if (timer.secondsLeft === 0) {
                props.actions.codeRequest({
                  data: {
                    type_confirm_code: props.confirmTypeCode,
                  },
                  onSuccess: () => timer?.restart(),
                });
              }
            }}
          >
            {`Отправить код повторно ${
              timer.secondsLeft !== 0
                ? `${timer.currentTime.minutes}:${timer.currentTime.seconds}`
                : ''
            }`}
          </LinkButton>
        </Form>
      </div>
    </div>
  );
};

export default ConfirmCode;
