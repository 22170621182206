import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { actions, INotificationState, name, selectors } from '../../store';
import Notifications, {
  INotificaitonSentActions,
  INotificationsProps,
  INotificationsPropsExternal,
} from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import styles from './assets/styles/index.module.css';
import fp from 'lodash/fp';
import { ITable } from '@rfb/ui-kit';
import { getTransformedAttachments } from '../../helpers/transform';
import { store } from 'root-store';
import { convertPathUsingParams } from '@rfb/common';
import routingConfig from '../../../../configs/routing';
import {
  InOutDirection,
  notificationReadStatusFilterCode,
  notificationReadStatusFilterDisplay,
  NotificationReadStatusFilterDisplay,
} from '../../configs/type';
import storage from '../../../../utils/storage';
import { readOnlyRoleKey } from '../../../app/store/sagas';

type TNotificationsTableHeader = ITable['headers'][number];

type TNotificationsTableColumn = {
  header: TNotificationsTableHeader;
  transform?: (value: any, row?: INotificationState['notifications'][number]) => any;
};

const mapStateToProps = (
  state: { [name]: INotificationState },
  props: { notificationDealerSystem: string; history: TODO_ANY }
): INotificationsProps & INotificationsPropsExternal => {
  const notifications: INotificationState['notifications'] = selectors.selectNotifications(state);
  const filter: INotificationState['filter'] = selectors.selectFilter(state);
  const columnsIn: TNotificationsTableColumn[] = [
    {
      header: {
        value: 'attachments',
        title: '',
        isFixed: true,
        columnClassName: styles.attachmentsColumnData,
      },
      transform: getTransformedAttachments,
    },
    {
      header: {
        value: 'notification_date',
        title: 'Дата отправки',
        type: 'sorting',
        sortingType: 'number',
        isFixed: true,
        columnClassName: styles.columnSendingDate,
      },
    },
    {
      header: {
        value: 'sender',
        title: 'Отправитель',
        isFixed: true,
        columnClassName: styles.senderColumnData,
      },
    },
    {
      header: {
        value: 'subject',
        title: 'Тема',
        isFixed: true,
        type: 'input',
        inputTooltipText: 'Введите любые три символа',
        filterValue: filter.subject,
        columnClassName: styles.subjectColumnData,
        columnKey: filter.lastResetTimestamp,
        onFilterChange: (subject) =>
          fp.some(Boolean, [fp.gte(fp.size(subject), 3), fp.isEmpty(subject)])
            ? store.dispatch(actions.setFilter({ subject }))
            : fp.noop,
      },
    },
    {
      header: {
        value: 'read_by',
        title: 'Прочтено',
        isFixed: true,
        type: 'input',
        inputTooltipText: 'Введите любые три символа',
        filterValue: filter.read_by,
        columnClassName: styles.receiverColumnData,
        columnKey: filter.lastResetTimestamp,
        onFilterChange: (read_by) =>
          fp.some(Boolean, [fp.gte(fp.size(read_by), 3), fp.isEmpty(read_by)])
            ? store.dispatch(actions.setFilter({ read_by }))
            : fp.noop,
      },
    },
    {
      header: {
        value: 'read_date',
        title: 'Дата и время прочтения',
        type: 'sorting',
        sortingType: 'number',
        isFixed: false,
        columnClassName: styles.columnDateAndTime,
      },
    },
    {
      header: {
        value: 'read',
        title: 'СТАТУС',
        type: 'select',
        filterList: [
          NotificationReadStatusFilterDisplay.NO,
          NotificationReadStatusFilterDisplay.YES,
          NotificationReadStatusFilterDisplay.ALL,
        ],
        filterValue: notificationReadStatusFilterDisplay[filter.statusCode],
        isFixed: false,
        columnClassName: styles.statusColumnData,
        onFilterChange: (displayValue: TODO_ANY) =>
          store.dispatch(
            actions.setFilter({ statusCode: notificationReadStatusFilterCode[displayValue] })
          ),
      },
      transform: (notUse: TODO_ANY, row) =>
        row?.read_date
          ? NotificationReadStatusFilterDisplay.YES
          : NotificationReadStatusFilterDisplay.NO,
    },
  ];

  const columnsOut: TNotificationsTableColumn[] = [
    {
      header: {
        value: 'notification_date',
        title: 'Дата отправки',
        type: 'sorting',
        sortingType: 'number',
        isFixed: true,
        columnClassName: styles.columnSendingDate,
      },
    },
    {
      header: {
        value: 'sender',
        title: 'Отправитель',
        isFixed: true,
        type: 'input',
        inputTooltipText: 'Введите любые три символа',
        filterValue: filter.sender,
        columnClassName: styles.senderColumnData,
        columnKey: filter.lastResetTimestamp,
        onFilterChange: (sender) =>
          fp.some(Boolean, [fp.gte(fp.size(sender), 3), fp.isEmpty(sender)])
            ? store.dispatch(actions.setFilter({ sender }))
            : fp.noop,
      },
    },
    {
      header: {
        value: 'subject',
        title: 'Тема',
        isFixed: true,
        type: 'input',
        inputTooltipText: 'Введите любые три символа',
        filterValue: filter.subject,
        columnClassName: styles.subjectColumnData,
        columnKey: filter.lastResetTimestamp,
        onFilterChange: (subject) =>
          fp.some(Boolean, [fp.gte(fp.size(subject), 3), fp.isEmpty(subject)])
            ? store.dispatch(actions.setFilter({ subject }))
            : fp.noop,
      },
    },
    {
      header: {
        value: 'statusCode',
        title: 'СТАТУС',
        type: 'select',
        filterList: [
          NotificationReadStatusFilterDisplay.NO,
          NotificationReadStatusFilterDisplay.YES,
          NotificationReadStatusFilterDisplay.ALL,
        ],
        filterValue: notificationReadStatusFilterDisplay[filter.statusCode],
        isFixed: false,
        columnClassName: styles.statusColumnData,
        onFilterChange: (displayValue: TODO_ANY) =>
          store.dispatch(
            actions.setFilter({ statusCode: notificationReadStatusFilterCode[displayValue] })
          ),
      },
      transform: (notUse: TODO_ANY, row) =>
        row?.read_date
          ? NotificationReadStatusFilterDisplay.YES
          : NotificationReadStatusFilterDisplay.NO,
    },
  ];

  const columns = filter.inOutDirection === InOutDirection.IN ? columnsIn : columnsOut;

  const rowDataList = fp.pipe(
    fp.map((row: INotificationState['notifications'][number]) =>
      fp.map(
        (column: TNotificationsTableColumn) =>
          fp.isFunction(column.transform)
            ? column.transform(fp.path(column.header.value, row), row)
            : fp.path(column.header.value, row),
        columns
      )
    ),
    fp.map((data) => ({ data }))
  )(notifications);

  const rowConfigList = fp.pipe(
    fp.map((row: INotificationState['notifications'][number]) => ({
      isBolded: !row.read_date && filter.inOutDirection === InOutDirection.IN,
      link: convertPathUsingParams(routingConfig.notificationView.path, {
        id: row.notification_id,
        dealerSystem: props.notificationDealerSystem,
      }),
    })),
    fp.map((config) => ({ config }))
  )(notifications);

  return {
    notifications: selectors.selectNotifications(state),
    isReadOnly: storage.get(readOnlyRoleKey),
    filter,
    headers: fp.map(fp.path('header'), columns),
    rows: fp.pipe(fp.zip(rowConfigList), fp.map(fp.mergeAll))(rowDataList),
    page: filter.page,
    pageCount: selectors.selectPageCount(state),
    errorList: selectors.selectErrorListByField(state)('api'),
    isLoading: selectors.selectIsLoading(state),
    inOutDirectionOptions: [
      { value: InOutDirection.IN, label: 'Входящие' },
      { value: InOutDirection.OUT, label: 'Исходящие' },
    ],
    notificationDealerSystem: props.notificationDealerSystem,
    history: props.history,
  };
};

const mapDispatchToProps = (dispatch: TODO_ANY): INotificaitonSentActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
