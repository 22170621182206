import React from 'react';

import styles from './assets/styles/index.module.css';
import AccountsFilter from '../AccountsFilter';
import Transactions from './components/Transactions';

const AccountsMain = () => {
  return (
    <div className={styles.main}>
      <AccountsFilter />
      <Transactions />
    </div>
  );
};

export default AccountsMain;
