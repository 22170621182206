import React, { useEffect } from 'react';

import { Button } from '@rfb/ui-kit';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

export interface IAuthPasswordRestoreSentProps {
  email: string;
}

export interface IAuthPasswordRestoreSentPropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void;
  };
}

export interface IAuthPasswordRestoreSentActions {
  actions: {
    set: Function;
    reset: Function;
  };
}

const PasswordRestoreSent = (
  props: IAuthPasswordRestoreSentProps &
    IAuthPasswordRestoreSentPropsExternal &
    IAuthPasswordRestoreSentActions
) => {
  const onButtonClick = (): void => props.history.push(routingConfig.login.path);
  useEffect(() => {
    return () => {
      props.actions.set({ isPasswordRestoreSent: false });
    };
    // ESLINT Необходимо выполнение только в момент размонтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>Восстановление пароля</h1>

      <div className={styles.message}>
        На вашу почту <span className={styles.email}>{props.email}</span> выслано письмо с новым
        паролем. Если в течение 5 минут письмо не поступило, проверьте вводимую почту и попробуйте
        снова.
      </div>

      <Button wrapperClassName={styles.button} text="OK" onClick={onButtonClick} />
    </div>
  );
};

export default PasswordRestoreSent;
