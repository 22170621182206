import { createSelector } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { name } from './index';

export const selectState = fp.path(name);

export default {
  selectCode: createSelector(selectState, fp.path('code')),
  selectFactorUrl: createSelector(selectState, fp.path('factorUrl')),
  selectIsSending: createSelector(selectState, fp.path('isSending')),
  selectIsLoading: createSelector(selectState, fp.path('isLoading')),
  selectIsBlocked: createSelector(selectState, fp.path('isBlocked')),
  selectPhoneNumber: createSelector(selectState, fp.path('phoneNumber')),
  selectErrorListByField: createSelector(selectState, (state) => (field: string) => {
    return fp.pathOr([], ['errorList', field], state);
  }),
};
