import { setupWorker } from 'msw';
import { authenticationCreateTokenHandlers } from './authentication_create-token/authenticationCreateTokenHandlers';
import { profileViewHandlers } from './profile_view/profileViewHandlers';
import { profileChooseDealerHandlers } from './profile_choose-dealer/profileChooseDealerHandlers';
import { rfinfoAccountArrestsHandlers } from './rf-info_account-arrests/rfinfoAccountArrestsHandlers';
import { rfinfoAccountsHandlers } from './rf-info_accounts/rfinfoAccountsHandlers';
import { rfinfoAccountStatementsHandlers } from './rf-info_account-statements/rfinfoAccountStatementsHandlers';
import { rfinfoAccountTransactionsHandlers } from './rf-info_account-transactions/rfinfoAccountTransactionsHandlers';
import { rfinfoClientLinesHandlers } from './rf-info_client-lines/rfinfoClientLinesHandlers';
import { rfinfoClientLineHandlers } from './rf-info_client-line/rfinfoClientLineHandlers';
import { rfinfoInfoHandlers } from './rf-info_info/rfinfoInfoHandlers';
import { rfinfoTranchesHandlers } from './rf-info_tranches/rfinfoTranchesHandlers';
import { securitySendConfirmSmsHandlers } from './security_send-confirm-sms/securitySendConfirmSmsHandlers';
import { securityCheckCodeHandlers } from './security_check-code/securityCheckCodeHandlers';
import { tranchesPaymentDocRequestHandlers } from './tranches-payment_doc-request/tranchesPaymentDocRequestHandlers';
import { tranchesPaymentDocRequestDownloadHandlers } from './tranches-payment_doc-download/tranchesPaymentDocRequestDownloadHandlers';
import { rfinfoTranchesPaymentHandlers } from './rf-info_tranches_payment/rfinfoTranchesPaymentHandlers';
import { rfinfoCodeHandlers } from './rf-info_code/rfinfoCodeHandlers';
import { tranchesRequestDocDownloadHandlers } from './tranches-request_doc-download/tranchesRequestDocDownloadHandlers';
import { rfinfoTranchesRequestHandlers } from './rf-info_tranches_request/rfinfoTranchesRequestHandlers';
import { tranchesRequestDocRequestHandlers } from './tranches-request_doc-request/tranchesRequestDocRequestHandlers';
import { profileDealerSystemHandlers } from './profile_dealer-system/profileDealerSystemHandlers';
import { profileValidateHandlers } from './profile_validate/profileValidateHandlers';
import { feedbackCreateHandlers } from './feedback_create/feedbackCreateHandlers';
import { systemAvailabilityHandlers } from './system_availability/systemAvailabilityHandlers';
import { variableContactsHandlers } from './variable_contacts/variableContactsHandlers';
import { variableContactsRfHandlers } from './variable_contacts-rf/variableContactsRfHandlers';
import { rfinfoUserHandlers } from './rf-info_user/rfinfoUserHandlers';
import { operationDataIndexHandlers } from './operation-data_index/operationDataIndexHandlers';
import { notificationsHandlers } from './notifications/notificationsHandlers';
import { requestDataIndexHandlers } from './request-data_index/requestDataIndexHandlers';
import { operationDataRequestUsedRefreshStatusHandlers } from './api_operation-data_request_used_refresh-status/operationDataRequestUsedRefreshStatusHandlers';

export const worker = setupWorker(
  ...authenticationCreateTokenHandlers,
  ...feedbackCreateHandlers,
  ...notificationsHandlers,
  ...operationDataIndexHandlers,
  ...operationDataRequestUsedRefreshStatusHandlers,
  ...profileChooseDealerHandlers,
  ...requestDataIndexHandlers,
  ...profileDealerSystemHandlers,
  ...profileValidateHandlers,
  ...profileViewHandlers,
  ...rfinfoClientLineHandlers,
  ...rfinfoClientLinesHandlers,
  ...rfinfoCodeHandlers,
  ...rfinfoAccountArrestsHandlers,
  ...rfinfoAccountsHandlers,
  ...rfinfoAccountStatementsHandlers,
  ...rfinfoAccountTransactionsHandlers,
  ...rfinfoInfoHandlers,
  ...rfinfoUserHandlers,
  ...rfinfoTranchesHandlers,
  ...rfinfoTranchesPaymentHandlers,
  ...rfinfoTranchesRequestHandlers,
  ...securityCheckCodeHandlers,
  ...securitySendConfirmSmsHandlers,
  ...systemAvailabilityHandlers,
  ...tranchesPaymentDocRequestDownloadHandlers,
  ...tranchesPaymentDocRequestHandlers,
  ...tranchesRequestDocDownloadHandlers,
  ...tranchesRequestDocRequestHandlers,
  ...variableContactsHandlers,
  ...variableContactsRfHandlers
);
