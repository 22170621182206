import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { FeedbackSubjects } from '@rfb/common';

import { name, actions, selectors, IInformationState } from '../../store';
import { selectors as userSelectors } from 'modules/user/store';

import Component, {
  IInformationFeedbackProps,
  IInformationFeedbackPropsExternal,
  IInformationFeedbackActions,
} from './component';

const mapStateToProps = (
  state: { [name]: IInformationState },
  props: IInformationFeedbackPropsExternal
): IInformationFeedbackProps & IInformationFeedbackPropsExternal => ({
  options: [
    {
      value: FeedbackSubjects.DebtService,
      label: 'Вопросы по обслуживанию задолженности',
    },
    {
      value: FeedbackSubjects.AccountMaintenance,
      label: 'Вопросы по обслуживанию расчётного счёта',
    },
    {
      value: FeedbackSubjects.TechnicalIssue,
      label: 'Технические вопросы',
    },
    {
      value: FeedbackSubjects.Other,
      label: 'Иное',
    },
  ],
  subject: selectors.selectFormValueByField(state)('subject'),
  optionErrorList: selectors.selectErrorListByField(state)('subject'),
  email: userSelectors.selectEmail(state),
  phone: selectors.selectFormValueByField(state)('phone'),
  phoneErrorList: selectors.selectErrorListByField(state)('phone'),
  contractNumber: selectors.selectFormValueByField(state)('contractNumber'),
  message: selectors.selectFormValueByField(state)('message'),
  messageErrorList: selectors.selectErrorListByField(state)('message'),
  apiErrorList: selectors.selectErrorListByField(state)('api'),
  dealerName: userSelectors.selectDealerValueByField(state)('name'),
  isFeedbackSending: selectors.selectIsFeedbackSending(state),
  history: props.history,
  notificationDealerSystem: props.notificationDealerSystem,
});

const mapDispatchToProps = (dispatch: any): IInformationFeedbackActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
